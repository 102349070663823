/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * This definition contains the endpoints of the Polarstern Core API.
 *
 * The version of the OpenAPI document: 2.19.5
 * Contact: devs@polarstern-energie.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from "./base";

/**
 *
 * @export
 * @interface AddContract400Response
 */
export interface AddContract400Response {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof AddContract400Response
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof AddContract400Response
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof AddContract400Response
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof AddContract400Response
   */
  details?: string;
  /**
   * Dictionary containing validation errors.
   * @type {{ [key: string]: string; }}
   * @memberof AddContract400Response
   */
  validationErrors?: { [key: string]: string };
  /**
   *
   * @type {InvalidZipCodeValidationProblemAllOfInvalidPayload}
   * @memberof AddContract400Response
   */
  invalidPayload?: InvalidZipCodeValidationProblemAllOfInvalidPayload;
}
/**
 *
 * @export
 * @interface AddContractRequest
 */
export interface AddContractRequest {
  /**
   * Unique ID of the contract.
   * @type {string}
   * @memberof AddContractRequest
   */
  id: string;
  /**
   *
   * @type {ContractType}
   * @memberof AddContractRequest
   */
  type: ContractType;
  /**
   * The customer\'s estimate on their annual energy consumption.
   * @type {Energy}
   * @memberof AddContractRequest
   */
  annualConsumption: Energy;
  /**
   * The customer\'s estimate on their annual low tariff energy consumption. Can only be set for HT/NT-tariffs.
   * @type {Energy}
   * @memberof AddContractRequest
   */
  annualConsumptionLowTariff?: Energy;
  /**
   * A list of tariffs that are, were or will be used by this contract ordered by its validity starting with the oldest tariff.
   * @type {Array<TariffHistoryInner>}
   * @memberof AddContractRequest
   */
  tariffHistory: Array<TariffHistoryInner>;
  /**
   * This value describes if a comfort tariff expires in the next two months. Thus, it will be set to true, unless the customer already requested a tariff switch.
   * @type {boolean}
   * @memberof AddContractRequest
   */
  isTariffSwitchOutstanding?: boolean;
  /**
   * The monthly gross advance payment amount.
   * @type {Price}
   * @memberof AddContractRequest
   */
  advancePayment: Price;
  /**
   *
   * @type {OrderReason}
   * @memberof AddContractRequest
   */
  orderReason?: OrderReason;
  /**
   *
   * @type {ContractLifecycle}
   * @memberof AddContractRequest
   */
  lifecycle: ContractLifecycle;
  /**
   * This will be set if and only if the customer terminated this contract.
   * @type {Termination}
   * @memberof AddContractRequest
   */
  termination?: Termination;
  /**
   *
   * @type {PreviousContract}
   * @memberof AddContractRequest
   */
  previousContract?: PreviousContract;
  /**
   * The name of the contract. Can be used to differentiate between contracts in a human readable form. Not enforced to be unique.
   * @type {string}
   * @memberof AddContractRequest
   */
  name?: string;
  /**
   *
   * @type {SepaMandate}
   * @memberof AddContractRequest
   */
  sepaMandate: SepaMandate;
  /**
   *
   * @type {DeprecatedMeter}
   * @memberof AddContractRequest
   */
  meter?: DeprecatedMeter;
  /**
   *
   * @type {Array<DeprecatedMeterReading>}
   * @memberof AddContractRequest
   */
  meterReadings?: Array<DeprecatedMeterReading>;
  /**
   *
   * @type {ContractState}
   * @memberof AddContractRequest
   */
  state: ContractState;
  /**
   *
   * @type {BankAccount}
   * @memberof AddContractRequest
   */
  bankAccount: BankAccount;
  /**
   *
   * @type {Address}
   * @memberof AddContractRequest
   */
  billingAddress?: Address;
  /**
   *
   * @type {ContractPartner}
   * @memberof AddContractRequest
   */
  contractPartner?: ContractPartner;
  /**
   *
   * @type {BaseContractTransferredFrom}
   * @memberof AddContractRequest
   */
  transferredFrom?: BaseContractTransferredFrom;
  /**
   * Terms & Conditions may be accepted at a later time if a contract was added by customer support.
   * @type {boolean}
   * @memberof AddContractRequest
   */
  hasAcceptedGtc: boolean;
  /**
   *
   * @type {ContractAllOfSupply}
   * @memberof AddContractRequest
   */
  supply: ContractAllOfSupply;
  /**
   * Whether this contract is self payed by the customer without a sepa mandate.
   * @type {boolean}
   * @memberof AddContractRequest
   */
  selfPayer: boolean;
  /**
   *
   * @type {ForecastBasis}
   * @memberof AddContractRequest
   */
  forecastBasis: ForecastBasis;
}

/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   * This covers all potential additions for addresses like a sub compartment type.
   * @type {string}
   * @memberof Address
   */
  additionalInformation?: string;
  /**
   * Unique identifier of the market location within the regulated energy market.
   * @type {string}
   * @memberof Address
   */
  maLo?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  city: string;
  /**
   *
   * @type {EntityType}
   * @memberof Address
   */
  type: EntityType;
  /**
   * Required if `type=private`.
   * @type {string}
   * @memberof Address
   */
  firstName?: string;
  /**
   * Required if `type=private`.
   * @type {string}
   * @memberof Address
   */
  lastName?: string;
  /**
   * Required if `type=company|ngo`.
   * @type {string}
   * @memberof Address
   */
  companyName?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  careOf?: string;
  /**
   * The two letter country code following ISO 3166-1 alpha-2 standard.
   * @type {string}
   * @memberof Address
   */
  country: string;
}

/**
 *
 * @export
 * @interface AddressWithAdditionalInformation
 */
export interface AddressWithAdditionalInformation {
  /**
   * This covers all potential additions for addresses like a sub compartment type.
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  additionalInformation?: string;
  /**
   * Unique identifier of the market location within the regulated energy market.
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  maLo?: string;
  /**
   *
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  city: string;
}
/**
 *
 * @export
 * @interface Bank
 */
export interface Bank {
  /**
   * The name of the bank.
   * @type {string}
   * @memberof Bank
   */
  name: string;
  /**
   * The Bank Identifier Code (BIC), also known as Swift-Code, of the bank.
   * @type {string}
   * @memberof Bank
   */
  bic: string;
}
/**
 *
 * @export
 * @interface BankAccount
 */
export interface BankAccount {
  /**
   *
   * @type {Bank}
   * @memberof BankAccount
   */
  bank?: Bank;
  /**
   * Display name of the bank account, may be set by the customer.
   * @type {string}
   * @memberof BankAccount
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BankAccount
   */
  iban: string;
  /**
   * The name of the account holder.
   * @type {string}
   * @memberof BankAccount
   */
  accountHolder: string;
}
/**
 *
 * @export
 * @interface BaseAddress
 */
export interface BaseAddress {
  /**
   *
   * @type {string}
   * @memberof BaseAddress
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddress
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddress
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddress
   */
  city: string;
}
/**
 *
 * @export
 * @interface BaseAddressWithMaLo
 */
export interface BaseAddressWithMaLo {
  /**
   * Unique identifier of the market location within the regulated energy market.
   * @type {string}
   * @memberof BaseAddressWithMaLo
   */
  maLo?: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddressWithMaLo
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddressWithMaLo
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddressWithMaLo
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddressWithMaLo
   */
  city: string;
}
/**
 *
 * @export
 * @interface BaseContract
 */
export interface BaseContract {
  /**
   * Unique ID of the contract.
   * @type {string}
   * @memberof BaseContract
   */
  id: string;
  /**
   *
   * @type {ContractType}
   * @memberof BaseContract
   */
  type: ContractType;
  /**
   * The customer\'s estimate on their annual energy consumption.
   * @type {Energy}
   * @memberof BaseContract
   */
  annualConsumption: Energy;
  /**
   * The customer\'s estimate on their annual low tariff energy consumption. Can only be set for HT/NT-tariffs.
   * @type {Energy}
   * @memberof BaseContract
   */
  annualConsumptionLowTariff?: Energy;
  /**
   * A list of tariffs that are, were or will be used by this contract ordered by its validity starting with the oldest tariff.
   * @type {Array<TariffHistoryInner>}
   * @memberof BaseContract
   */
  tariffHistory: Array<TariffHistoryInner>;
  /**
   * This value describes if a comfort tariff expires in the next two months. Thus, it will be set to true, unless the customer already requested a tariff switch.
   * @type {boolean}
   * @memberof BaseContract
   */
  isTariffSwitchOutstanding?: boolean;
  /**
   * The monthly gross advance payment amount.
   * @type {Price}
   * @memberof BaseContract
   */
  advancePayment: Price;
  /**
   *
   * @type {OrderReason}
   * @memberof BaseContract
   */
  orderReason?: OrderReason;
  /**
   *
   * @type {ContractLifecycle}
   * @memberof BaseContract
   */
  lifecycle: ContractLifecycle;
  /**
   * This will be set if and only if the customer terminated this contract.
   * @type {Termination}
   * @memberof BaseContract
   */
  termination?: Termination;
  /**
   *
   * @type {PreviousContract}
   * @memberof BaseContract
   */
  previousContract?: PreviousContract;
  /**
   * The name of the contract. Can be used to differentiate between contracts in a human readable form. Not enforced to be unique.
   * @type {string}
   * @memberof BaseContract
   */
  name?: string;
  /**
   *
   * @type {BaseSepaMandate}
   * @memberof BaseContract
   */
  sepaMandate?: BaseSepaMandate;
  /**
   *
   * @type {DeprecatedMeter}
   * @memberof BaseContract
   */
  meter?: DeprecatedMeter;
  /**
   * Meter readings associated with this contract.
   * @type {Array<DeprecatedBaseMeterReading>}
   * @memberof BaseContract
   */
  meterReadings?: Array<DeprecatedBaseMeterReading>;
  /**
   *
   * @type {ContractState}
   * @memberof BaseContract
   */
  state: ContractState;
  /**
   *
   * @type {BankAccount}
   * @memberof BaseContract
   */
  bankAccount?: BankAccount;
  /**
   *
   * @type {Address}
   * @memberof BaseContract
   */
  billingAddress?: Address;
  /**
   *
   * @type {ContractPartner}
   * @memberof BaseContract
   */
  contractPartner?: ContractPartner;
  /**
   *
   * @type {BaseContractTransferredFrom}
   * @memberof BaseContract
   */
  transferredFrom?: BaseContractTransferredFrom;
  /**
   * Terms & Conditions may be accepted at a later time if a contract was added by customer support.
   * @type {boolean}
   * @memberof BaseContract
   */
  hasAcceptedGtc: boolean;
}

/**
 * This will be set if and only if this contract was transferred from another customer.
 * @export
 * @interface BaseContractTransferredFrom
 */
export interface BaseContractTransferredFrom {
  /**
   * The ID of the transfer that was used to transfer this contract.
   * @type {string}
   * @memberof BaseContractTransferredFrom
   */
  transferId: string;
  /**
   * The ID of the original contract this contract is based on.
   * @type {string}
   * @memberof BaseContractTransferredFrom
   */
  contractId: string;
}
/**
 *
 * @export
 * @interface BaseCustomer
 */
export interface BaseCustomer {
  /**
   * The unique ID of the customer.
   * @type {string}
   * @memberof BaseCustomer
   */
  id?: string;
  /**
   *
   * @type {EntityType}
   * @memberof BaseCustomer
   */
  customerType: EntityType;
  /**
   *
   * @type {PersonalData}
   * @memberof BaseCustomer
   */
  personalData: PersonalData;
  /**
   *
   * @type {EmailAddress}
   * @memberof BaseCustomer
   */
  emailAddress: EmailAddress;
  /**
   *
   * @type {PhoneNumber}
   * @memberof BaseCustomer
   */
  phoneNumber: PhoneNumber;
}

/**
 * If a previous contract needs to be terminated before this contract can become effective, it can be specified here.
 * @export
 * @interface BasePreviousContract
 */
export interface BasePreviousContract {
  /**
   * Date at which the previous contract was or will be terminated. If this is not provided on contract creation, Polarstern needs to terminate the contract on behalf of the customer.
   * @type {string}
   * @memberof BasePreviousContract
   */
  terminatedAt?: string;
}
/**
 *
 * @export
 * @interface BaseSepaMandate
 */
export interface BaseSepaMandate {
  /**
   * The date and time at which the customer accepted the SEPA mandate.
   * @type {string}
   * @memberof BaseSepaMandate
   */
  acceptedAt?: string;
  /**
   * The reference number of this SEPA mandate.
   * @type {string}
   * @memberof BaseSepaMandate
   */
  reference?: string;
}
/**
 *
 * @export
 * @interface BaseSupply
 */
export interface BaseSupply {
  /**
   * Date of requested supply of a customer. The value is required for the creation of a tenant power contract.
   * @type {string}
   * @memberof BaseSupply
   */
  requestedBeginAt?: string;
}
/**
 *
 * @export
 * @interface BaseTermination
 */
export interface BaseTermination {
  /**
   * Unique ID of the terminated contract.
   * @type {string}
   * @memberof BaseTermination
   */
  contractId?: string;
  /**
   * Date at which the contract was or will be terminated.
   * @type {string}
   * @memberof BaseTermination
   */
  terminatedAt: string;
  /**
   * If the customer moves to a new address and wants to stay with Polarstern. This is required to decide which document to send to the customer (Kündigungsbestätigung oder Abmeldebestätigung).
   * @type {boolean}
   * @memberof BaseTermination
   */
  movedToNewAddress?: boolean;
  /**
   *
   * @type {BaseTerminationReason}
   * @memberof BaseTermination
   */
  reason: BaseTerminationReason;
  /**
   *
   * @type {string}
   * @memberof BaseTermination
   */
  note?: string;
  /**
   * Date at which the termination was confirmed by the network provider.
   * @type {string}
   * @memberof BaseTermination
   */
  confirmedAt?: string;
  /**
   * Date and time at which the termination was created.
   * @type {string}
   * @memberof BaseTermination
   */
  createdAt: string;
  /**
   * If the termination was created by an admin.
   * @type {boolean}
   * @memberof BaseTermination
   */
  hasBeenTerminatedByAdmin?: boolean;
}
/**
 * @type BaseTerminationReason
 * The reason for the termination.
 * @export
 */
export type BaseTerminationReason = TerminationReason | string;

/**
 *
 * @export
 * @interface CompanyPersonalData
 */
export interface CompanyPersonalData {
  /**
   *
   * @type {string}
   * @memberof CompanyPersonalData
   */
  companyName: string;
}
/**
 *
 * @export
 * @interface ConfirmedSupply
 */
export interface ConfirmedSupply {
  /**
   * Date of requested supply of a customer. The value is required for the creation of a tenant power contract.
   * @type {string}
   * @memberof ConfirmedSupply
   */
  requestedBeginAt?: string;
  /**
   * Supply address. With exception of MaLo, it is also used as billing address, as long as no separate billing address is provided.
   * @type {Address}
   * @memberof ConfirmedSupply
   */
  address?: Address;
  /**
   * Date at which network operator confirmed to begin to supply this contract.
   * @type {string}
   * @memberof ConfirmedSupply
   */
  confirmedBeginAt?: string;
  /**
   * Date and time at which supply date was confirmed by a network provider.
   * @type {string}
   * @memberof ConfirmedSupply
   */
  confirmedAt?: string;
}
/**
 *
 * @export
 * @interface Contract
 */
export interface Contract {
  /**
   * Unique ID of the contract.
   * @type {string}
   * @memberof Contract
   */
  id: string;
  /**
   *
   * @type {ContractType}
   * @memberof Contract
   */
  type: ContractType;
  /**
   * The customer\'s estimate on their annual energy consumption.
   * @type {Energy}
   * @memberof Contract
   */
  annualConsumption: Energy;
  /**
   * The customer\'s estimate on their annual low tariff energy consumption. Can only be set for HT/NT-tariffs.
   * @type {Energy}
   * @memberof Contract
   */
  annualConsumptionLowTariff?: Energy;
  /**
   * A list of tariffs that are, were or will be used by this contract ordered by its validity starting with the oldest tariff.
   * @type {Array<TariffHistoryInner>}
   * @memberof Contract
   */
  tariffHistory: Array<TariffHistoryInner>;
  /**
   * This value describes if a comfort tariff expires in the next two months. Thus, it will be set to true, unless the customer already requested a tariff switch.
   * @type {boolean}
   * @memberof Contract
   */
  isTariffSwitchOutstanding?: boolean;
  /**
   * The monthly gross advance payment amount.
   * @type {Price}
   * @memberof Contract
   */
  advancePayment: Price;
  /**
   *
   * @type {OrderReason}
   * @memberof Contract
   */
  orderReason?: OrderReason;
  /**
   *
   * @type {ContractLifecycle}
   * @memberof Contract
   */
  lifecycle: ContractLifecycle;
  /**
   * This will be set if and only if the customer terminated this contract.
   * @type {Termination}
   * @memberof Contract
   */
  termination?: Termination;
  /**
   *
   * @type {PreviousContract}
   * @memberof Contract
   */
  previousContract?: PreviousContract;
  /**
   * The name of the contract. Can be used to differentiate between contracts in a human readable form. Not enforced to be unique.
   * @type {string}
   * @memberof Contract
   */
  name?: string;
  /**
   *
   * @type {SepaMandate}
   * @memberof Contract
   */
  sepaMandate?: SepaMandate;
  /**
   *
   * @type {DeprecatedMeter}
   * @memberof Contract
   */
  meter?: DeprecatedMeter;
  /**
   *
   * @type {Array<DeprecatedMeterReading>}
   * @memberof Contract
   */
  meterReadings?: Array<DeprecatedMeterReading>;
  /**
   *
   * @type {ContractState}
   * @memberof Contract
   */
  state: ContractState;
  /**
   *
   * @type {BankAccount}
   * @memberof Contract
   */
  bankAccount?: BankAccount;
  /**
   *
   * @type {Address}
   * @memberof Contract
   */
  billingAddress?: Address;
  /**
   *
   * @type {ContractPartner}
   * @memberof Contract
   */
  contractPartner?: ContractPartner;
  /**
   *
   * @type {BaseContractTransferredFrom}
   * @memberof Contract
   */
  transferredFrom?: BaseContractTransferredFrom;
  /**
   * Terms & Conditions may be accepted at a later time if a contract was added by customer support.
   * @type {boolean}
   * @memberof Contract
   */
  hasAcceptedGtc: boolean;
  /**
   *
   * @type {ContractAllOfSupply}
   * @memberof Contract
   */
  supply: ContractAllOfSupply;
}

/**
 *
 * @export
 * @interface ContractAllOfSupply
 */
export interface ContractAllOfSupply {
  /**
   * Date of requested supply of a customer. The value is required for the creation of a tenant power contract.
   * @type {string}
   * @memberof ContractAllOfSupply
   */
  requestedBeginAt?: string;
  /**
   * Supply address. With exception of MaLo, it is also used as billing address, as long as no separate billing address is provided.
   * @type {Address}
   * @memberof ContractAllOfSupply
   */
  address: Address;
  /**
   *
   * @type {any}
   * @memberof ContractAllOfSupply
   */
  confirmedBeginAt?: any;
  /**
   *
   * @type {any}
   * @memberof ContractAllOfSupply
   */
  confirmedAt?: any;
}
/**
 *
 * @export
 * @interface ContractAsCustomer
 */
export interface ContractAsCustomer {
  /**
   * Unique ID of the contract.
   * @type {string}
   * @memberof ContractAsCustomer
   */
  id: string;
  /**
   *
   * @type {ContractType}
   * @memberof ContractAsCustomer
   */
  type: ContractType;
  /**
   * The customer\'s estimate on their annual energy consumption.
   * @type {Energy}
   * @memberof ContractAsCustomer
   */
  annualConsumption: Energy;
  /**
   * The customer\'s estimate on their annual low tariff energy consumption. Can only be set for HT/NT-tariffs.
   * @type {Energy}
   * @memberof ContractAsCustomer
   */
  annualConsumptionLowTariff?: Energy;
  /**
   * A list of tariffs that are, were or will be used by this contract ordered by its validity starting with the oldest tariff.
   * @type {Array<TariffHistoryInner>}
   * @memberof ContractAsCustomer
   */
  tariffHistory: Array<TariffHistoryInner>;
  /**
   * This value describes if a comfort tariff expires in the next two months. Thus, it will be set to true, unless the customer already requested a tariff switch.
   * @type {boolean}
   * @memberof ContractAsCustomer
   */
  isTariffSwitchOutstanding?: boolean;
  /**
   * The monthly gross advance payment amount.
   * @type {Price}
   * @memberof ContractAsCustomer
   */
  advancePayment: Price;
  /**
   *
   * @type {OrderReason}
   * @memberof ContractAsCustomer
   */
  orderReason?: OrderReason;
  /**
   *
   * @type {ContractLifecycle}
   * @memberof ContractAsCustomer
   */
  lifecycle: ContractLifecycle;
  /**
   * This will be set if and only if the customer terminated this contract.
   * @type {Termination}
   * @memberof ContractAsCustomer
   */
  termination?: Termination;
  /**
   *
   * @type {PreviousContract}
   * @memberof ContractAsCustomer
   */
  previousContract?: PreviousContract;
  /**
   * The name of the contract. Can be used to differentiate between contracts in a human readable form. Not enforced to be unique.
   * @type {string}
   * @memberof ContractAsCustomer
   */
  name?: string;
  /**
   *
   * @type {SepaMandate}
   * @memberof ContractAsCustomer
   */
  sepaMandate?: SepaMandate;
  /**
   *
   * @type {DeprecatedMeter}
   * @memberof ContractAsCustomer
   */
  meter?: DeprecatedMeter;
  /**
   *
   * @type {Array<DeprecatedMeterReading>}
   * @memberof ContractAsCustomer
   */
  meterReadings?: Array<DeprecatedMeterReading>;
  /**
   *
   * @type {ContractState}
   * @memberof ContractAsCustomer
   */
  state: ContractState;
  /**
   *
   * @type {BankAccount}
   * @memberof ContractAsCustomer
   */
  bankAccount?: BankAccount;
  /**
   *
   * @type {Address}
   * @memberof ContractAsCustomer
   */
  billingAddress?: Address;
  /**
   *
   * @type {ContractPartner}
   * @memberof ContractAsCustomer
   */
  contractPartner?: ContractPartner;
  /**
   *
   * @type {BaseContractTransferredFrom}
   * @memberof ContractAsCustomer
   */
  transferredFrom?: BaseContractTransferredFrom;
  /**
   * Terms & Conditions may be accepted at a later time if a contract was added by customer support.
   * @type {boolean}
   * @memberof ContractAsCustomer
   */
  hasAcceptedGtc: boolean;
  /**
   *
   * @type {ContractAllOfSupply}
   * @memberof ContractAsCustomer
   */
  supply: ContractAllOfSupply;
  /**
   * Whether this contract is self payed by the customer without a sepa mandate.
   * @type {boolean}
   * @memberof ContractAsCustomer
   */
  selfPayer: boolean;
  /**
   *
   * @type {ForecastBasis}
   * @memberof ContractAsCustomer
   */
  forecastBasis: ForecastBasis;
}

/**
 *
 * @export
 * @interface ContractLifecycle
 */
export interface ContractLifecycle {
  /**
   * The date and time at which the contract was created initially.
   * @type {string}
   * @memberof ContractLifecycle
   */
  createdAt: string;
  /**
   * The date and time at which the contract was moved (i.e. replaced by another contract). This means that a new address is supplied starting at this time, while this contract can still be active for the previous address.
   * @type {string}
   * @memberof ContractLifecycle
   */
  customerMovedAt?: string;
  /**
   * The date and time at which a transfer for this contract was initiated.
   * @type {string}
   * @memberof ContractLifecycle
   */
  transferInitiatedAt?: string;
  /**
   * The date and time at which the transfer for this contract was accepted by the receiving party.
   * @type {string}
   * @memberof ContractLifecycle
   */
  transferAcceptedAt?: string;
}
/**
 *
 * @export
 * @interface ContractPartner
 */
export interface ContractPartner {
  /**
   *
   * @type {EmailAddress}
   * @memberof ContractPartner
   */
  emailAddress?: EmailAddress;
  /**
   *
   * @type {string}
   * @memberof ContractPartner
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ContractPartner
   */
  lastName: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ContractState = {
  Incomplete: "Incomplete",
  Buffered: "Buffered",
  Pending: "Pending",
  Active: "Active",
  Rejected: "Rejected",
  Terminated: "Terminated",
} as const;

export type ContractState = (typeof ContractState)[keyof typeof ContractState];

/**
 *
 * @export
 * @interface ContractTodo
 */
export interface ContractTodo {
  /**
   *
   * @type {string}
   * @memberof ContractTodo
   */
  resource: ContractTodoResourceEnum;
  /**
   * The type of the information that is missing.
   * @type {string}
   * @memberof ContractTodo
   */
  type: ContractTodoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ContractTodo
   */
  contractId: string;
}

export const ContractTodoResourceEnum = {
  Contract: "contract",
} as const;

export type ContractTodoResourceEnum =
  (typeof ContractTodoResourceEnum)[keyof typeof ContractTodoResourceEnum];
export const ContractTodoTypeEnum = {
  TermsAndConditions: "termsAndConditions",
  OrderReason: "orderReason",
  Meter: "meter",
  BankAccount: "bankAccount",
} as const;

export type ContractTodoTypeEnum =
  (typeof ContractTodoTypeEnum)[keyof typeof ContractTodoTypeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const ContractType = {
  Gas: "gas",
  Power: "power",
  EMobility: "eMobility",
} as const;

export type ContractType = (typeof ContractType)[keyof typeof ContractType];

/**
 *
 * @export
 * @interface ContractUpdate
 */
export interface ContractUpdate {
  /**
   * The name of the contract. Can be used to differentiate between contracts in a human readable form. Not enforced to be unique.
   * @type {string}
   * @memberof ContractUpdate
   */
  name?: string;
  /**
   * Terms & Conditions may be accepted at a later time if a contract was added by customer support.
   * @type {boolean}
   * @memberof ContractUpdate
   */
  hasAcceptedGtc?: boolean;
  /**
   *
   * @type {DeprecatedMeter}
   * @memberof ContractUpdate
   */
  meter?: DeprecatedMeter;
  /**
   *
   * @type {PreviousContract}
   * @memberof ContractUpdate
   */
  previousContract?: PreviousContract;
  /**
   *
   * @type {OrderReason}
   * @memberof ContractUpdate
   */
  orderReason?: OrderReason;
  /**
   * The monthly gross advance payment amount.
   * @type {Price}
   * @memberof ContractUpdate
   */
  advancePayment?: Price;
  /**
   *
   * @type {BankAccount}
   * @memberof ContractUpdate
   */
  bankAccount?: BankAccount;
  /**
   *
   * @type {ContractPartner}
   * @memberof ContractUpdate
   */
  contractPartner?: ContractPartner;
  /**
   *
   * @type {Address}
   * @memberof ContractUpdate
   */
  billingAddress?: Address;
}

/**
 *
 * @export
 * @interface ContractUpdateAsCustomer
 */
export interface ContractUpdateAsCustomer {
  /**
   * The name of the contract. Can be used to differentiate between contracts in a human readable form. Not enforced to be unique.
   * @type {string}
   * @memberof ContractUpdateAsCustomer
   */
  name?: string;
  /**
   * Terms & Conditions may be accepted at a later time if a contract was added by customer support.
   * @type {boolean}
   * @memberof ContractUpdateAsCustomer
   */
  hasAcceptedGtc?: boolean;
  /**
   *
   * @type {DeprecatedMeter}
   * @memberof ContractUpdateAsCustomer
   */
  meter?: DeprecatedMeter;
  /**
   *
   * @type {PreviousContract}
   * @memberof ContractUpdateAsCustomer
   */
  previousContract?: PreviousContract;
  /**
   *
   * @type {OrderReason}
   * @memberof ContractUpdateAsCustomer
   */
  orderReason?: OrderReason;
  /**
   * The monthly gross advance payment amount.
   * @type {Price}
   * @memberof ContractUpdateAsCustomer
   */
  advancePayment?: Price;
  /**
   *
   * @type {BankAccount}
   * @memberof ContractUpdateAsCustomer
   */
  bankAccount?: BankAccount;
  /**
   *
   * @type {ContractPartner}
   * @memberof ContractUpdateAsCustomer
   */
  contractPartner?: ContractPartner;
  /**
   *
   * @type {Address}
   * @memberof ContractUpdateAsCustomer
   */
  billingAddress?: Address;
  /**
   *
   * @type {BaseSupply}
   * @memberof ContractUpdateAsCustomer
   */
  supply?: BaseSupply;
}

/**
 *
 * @export
 * @interface Customer
 */
export interface Customer {
  /**
   *
   * @type {any}
   * @memberof Customer
   */
  id: any;
  /**
   *
   * @type {EntityType}
   * @memberof Customer
   */
  customerType: EntityType;
  /**
   *
   * @type {PersonalData}
   * @memberof Customer
   */
  personalData: PersonalData;
  /**
   *
   * @type {EmailAddress}
   * @memberof Customer
   */
  emailAddress: EmailAddress;
  /**
   *
   * @type {PhoneNumber}
   * @memberof Customer
   */
  phoneNumber: PhoneNumber;
  /**
   *
   * @type {Array<Todo>}
   * @memberof Customer
   */
  todos: Array<Todo>;
  /**
   * The promotion code redeemed by this customer.
   * @type {string}
   * @memberof Customer
   */
  promotionCode?: string;
  /**
   * The universal code to share for recommendations by this customer.
   * @type {string}
   * @memberof Customer
   */
  universalCode: string;
  /**
   * The password to set for the customer\'s user account.
   * @type {string}
   * @memberof Customer
   */
  password: string;
}

/**
 *
 * @export
 * @interface CustomerTodo
 */
export interface CustomerTodo {
  /**
   *
   * @type {string}
   * @memberof CustomerTodo
   */
  resource: CustomerTodoResourceEnum;
  /**
   * The type of the information that is missing.
   * @type {string}
   * @memberof CustomerTodo
   */
  type: CustomerTodoTypeEnum;
}

export const CustomerTodoResourceEnum = {
  Customer: "customer",
} as const;

export type CustomerTodoResourceEnum =
  (typeof CustomerTodoResourceEnum)[keyof typeof CustomerTodoResourceEnum];
export const CustomerTodoTypeEnum = {
  EmailAddressVerification: "emailAddressVerification",
} as const;

export type CustomerTodoTypeEnum =
  (typeof CustomerTodoTypeEnum)[keyof typeof CustomerTodoTypeEnum];

/**
 *
 * @export
 * @interface CustomerWithoutPassword
 */
export interface CustomerWithoutPassword {
  /**
   * The unique ID of the customer.
   * @type {string}
   * @memberof CustomerWithoutPassword
   */
  id?: string;
  /**
   *
   * @type {EntityType}
   * @memberof CustomerWithoutPassword
   */
  customerType: EntityType;
  /**
   *
   * @type {PersonalData}
   * @memberof CustomerWithoutPassword
   */
  personalData: PersonalData;
  /**
   *
   * @type {EmailAddress}
   * @memberof CustomerWithoutPassword
   */
  emailAddress: EmailAddress;
  /**
   *
   * @type {PhoneNumber}
   * @memberof CustomerWithoutPassword
   */
  phoneNumber: PhoneNumber;
  /**
   *
   * @type {Array<Todo>}
   * @memberof CustomerWithoutPassword
   */
  todos: Array<Todo>;
  /**
   * The promotion code redeemed by this customer.
   * @type {string}
   * @memberof CustomerWithoutPassword
   */
  promotionCode?: string;
  /**
   * The universal code to share for recommendations by this customer.
   * @type {string}
   * @memberof CustomerWithoutPassword
   */
  universalCode: string;
}

/**
 *
 * @export
 * @interface DeprecatedBaseMeterReading
 */
export interface DeprecatedBaseMeterReading {
  /**
   *
   * @type {number}
   * @memberof DeprecatedBaseMeterReading
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof DeprecatedBaseMeterReading
   */
  unit: DeprecatedBaseMeterReadingUnitEnum;
  /**
   * The ID of this meter reading.
   * @type {string}
   * @memberof DeprecatedBaseMeterReading
   */
  id: string;
  /**
   * The serial number of the meter. A serial number is NOT unique but duplicates are very rare.
   * @type {string}
   * @memberof DeprecatedBaseMeterReading
   */
  meterSerialNumber?: string;
  /**
   * The date of the reading.
   * @type {string}
   * @memberof DeprecatedBaseMeterReading
   */
  readAt: string;
  /**
   * If the reading is for low tariff (true) or high tariff (false).
   * @type {boolean}
   * @memberof DeprecatedBaseMeterReading
   */
  isLowTariff?: boolean;
  /**
   *
   * @type {MeterReadingType}
   * @memberof DeprecatedBaseMeterReading
   */
  type: MeterReadingType;
}

export const DeprecatedBaseMeterReadingUnitEnum = {
  KWh: "kWh",
  M3: "m3",
} as const;

export type DeprecatedBaseMeterReadingUnitEnum =
  (typeof DeprecatedBaseMeterReadingUnitEnum)[keyof typeof DeprecatedBaseMeterReadingUnitEnum];

/**
 *
 * @export
 * @interface DeprecatedMeter
 */
export interface DeprecatedMeter {
  /**
   * The serial number of the meter. A serial number is NOT unique but duplicates are very rare.
   * @type {string}
   * @memberof DeprecatedMeter
   */
  serialNumber: string;
  /**
   * The date at which the meter was or will be installed.
   * @type {string}
   * @memberof DeprecatedMeter
   */
  installedAt?: string;
  /**
   * A remote controllable meter can be remotely disconnected from the power grid by the network operator.
   * @type {boolean}
   * @memberof DeprecatedMeter
   */
  isRemoteControllable?: boolean;
}
/**
 *
 * @export
 * @interface DeprecatedMeterReading
 */
export interface DeprecatedMeterReading {
  /**
   *
   * @type {number}
   * @memberof DeprecatedMeterReading
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof DeprecatedMeterReading
   */
  unit: DeprecatedMeterReadingUnitEnum;
  /**
   * The ID of this meter reading.
   * @type {string}
   * @memberof DeprecatedMeterReading
   */
  id: string;
  /**
   *
   * @type {any}
   * @memberof DeprecatedMeterReading
   */
  meterSerialNumber?: any;
  /**
   * The date of the reading.
   * @type {string}
   * @memberof DeprecatedMeterReading
   */
  readAt: string;
  /**
   * If the reading is for low tariff (true) or high tariff (false).
   * @type {boolean}
   * @memberof DeprecatedMeterReading
   */
  isLowTariff?: boolean;
  /**
   *
   * @type {any}
   * @memberof DeprecatedMeterReading
   */
  type: any;
}

export const DeprecatedMeterReadingUnitEnum = {
  KWh: "kWh",
  M3: "m3",
} as const;

export type DeprecatedMeterReadingUnitEnum =
  (typeof DeprecatedMeterReadingUnitEnum)[keyof typeof DeprecatedMeterReadingUnitEnum];

/**
 *
 * @export
 * @interface Document
 */
export interface Document {
  /**
   * The unique id or name of this document.
   * @type {string}
   * @memberof Document
   */
  id: string;
  /**
   * The extension specifying the type of this file.
   * @type {string}
   * @memberof Document
   */
  fileExtension: DocumentFileExtensionEnum;
  /**
   * The ID of the contract this document is associated with.
   * @type {string}
   * @memberof Document
   */
  contractId: string;
  /**
   * The document type, for example \"Rechnung\", \"Ablesung\" or \"Abschlagsanpassung\".
   * @type {string}
   * @memberof Document
   */
  documentType: string;
  /**
   * The creation date and time of this document.
   * @type {string}
   * @memberof Document
   */
  createdAt: string;
  /**
   * The base64 encoded content of this document.
   * @type {string}
   * @memberof Document
   */
  content: string;
}

export const DocumentFileExtensionEnum = {
  Pdf: "pdf",
} as const;

export type DocumentFileExtensionEnum =
  (typeof DocumentFileExtensionEnum)[keyof typeof DocumentFileExtensionEnum];

/**
 *
 * @export
 * @interface DocumentWithoutContent
 */
export interface DocumentWithoutContent {
  /**
   * The unique id or name of this document.
   * @type {string}
   * @memberof DocumentWithoutContent
   */
  id: string;
  /**
   * The extension specifying the type of this file.
   * @type {string}
   * @memberof DocumentWithoutContent
   */
  fileExtension: DocumentWithoutContentFileExtensionEnum;
  /**
   * The ID of the contract this document is associated with.
   * @type {string}
   * @memberof DocumentWithoutContent
   */
  contractId: string;
  /**
   * The document type, for example \"Rechnung\", \"Ablesung\" or \"Abschlagsanpassung\".
   * @type {string}
   * @memberof DocumentWithoutContent
   */
  documentType: string;
  /**
   * The creation date and time of this document.
   * @type {string}
   * @memberof DocumentWithoutContent
   */
  createdAt: string;
}

export const DocumentWithoutContentFileExtensionEnum = {
  Pdf: "pdf",
} as const;

export type DocumentWithoutContentFileExtensionEnum =
  (typeof DocumentWithoutContentFileExtensionEnum)[keyof typeof DocumentWithoutContentFileExtensionEnum];

/**
 *
 * @export
 * @interface EmailAddress
 */
export interface EmailAddress {
  /**
   *
   * @type {string}
   * @memberof EmailAddress
   */
  value: string;
  /**
   *
   * @type {Array<string>}
   * @memberof EmailAddress
   */
  subscriptions?: Array<EmailAddressSubscriptionsEnum>;
  /**
   * Date and time at which the email address was verified. Will be set by the server if and only if the email address has been verified.
   * @type {string}
   * @memberof EmailAddress
   */
  verifiedAt?: string;
}

export const EmailAddressSubscriptionsEnum = {
  GeneralNewsletter: "generalNewsletter",
  InterestedNewsletter: "interestedNewsletter",
  TenantPowerNewsletter: "tenantPowerNewsletter",
  HeatPumpNewsletter: "heatPumpNewsletter",
  EmobilityNewsletter: "emobilityNewsletter",
  CommercialSolarNewsletter: "commercialSolarNewsletter",
  JobNewsletter: "jobNewsletter",
} as const;

export type EmailAddressSubscriptionsEnum =
  (typeof EmailAddressSubscriptionsEnum)[keyof typeof EmailAddressSubscriptionsEnum];

/**
 *
 * @export
 * @interface Energy
 */
export interface Energy {
  /**
   *
   * @type {number}
   * @memberof Energy
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof Energy
   */
  unit: EnergyUnitEnum;
}

export const EnergyUnitEnum = {
  KWh: "kWh",
  M3: "m3",
} as const;

export type EnergyUnitEnum = (typeof EnergyUnitEnum)[keyof typeof EnergyUnitEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const EntityType = {
  Private: "private",
  Company: "company",
  Ngo: "ngo",
} as const;

export type EntityType = (typeof EntityType)[keyof typeof EntityType];

/**
 *
 * @export
 * @enum {string}
 */

export const ForecastBasis = {
  Slp: "slp",
  Rlm: "rlm",
} as const;

export type ForecastBasis = (typeof ForecastBasis)[keyof typeof ForecastBasis];

/**
 * Content of an error response due to failed request validation.
 * @export
 * @interface GeneralValidationFailedProblem
 */
export interface GeneralValidationFailedProblem {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof GeneralValidationFailedProblem
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof GeneralValidationFailedProblem
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof GeneralValidationFailedProblem
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof GeneralValidationFailedProblem
   */
  details?: string;
  /**
   * Dictionary containing validation errors.
   * @type {{ [key: string]: string; }}
   * @memberof GeneralValidationFailedProblem
   */
  validationErrors?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface GetCustomerContracts200Response
 */
export interface GetCustomerContracts200Response {
  /**
   *
   * @type {Array<ContractAsCustomer>}
   * @memberof GetCustomerContracts200Response
   */
  items: Array<ContractAsCustomer>;
}
/**
 *
 * @export
 * @interface GetDocuments200Response
 */
export interface GetDocuments200Response {
  /**
   *
   * @type {Array<DocumentWithoutContent>}
   * @memberof GetDocuments200Response
   */
  items: Array<DocumentWithoutContent>;
}
/**
 *
 * @export
 * @interface GetRecommendations200Response
 */
export interface GetRecommendations200Response {
  /**
   *
   * @type {Array<Recommendation>}
   * @memberof GetRecommendations200Response
   */
  items: Array<Recommendation>;
}
/**
 * Content of a failed tariff validation error response due invalidRequestedBeginAt for the tariff.
 * @export
 * @interface InvalidRequestedBeginValidationProblem
 */
export interface InvalidRequestedBeginValidationProblem {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  details?: string;
  /**
   * Dictionary containing validation errors.
   * @type {{ [key: string]: string; }}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  validationErrors?: { [key: string]: string };
  /**
   *
   * @type {InvalidRequestedBeginValidationProblemAllOfInvalidPayload}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  invalidPayload?: InvalidRequestedBeginValidationProblemAllOfInvalidPayload;
}
/**
 * Additional information about the tariff validation error.
 * @export
 * @interface InvalidRequestedBeginValidationProblemAllOfInvalidPayload
 */
export interface InvalidRequestedBeginValidationProblemAllOfInvalidPayload {
  /**
   *
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblemAllOfInvalidPayload
   */
  requestedBeginAt: string;
  /**
   *
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblemAllOfInvalidPayload
   */
  tariffIsValidFrom: string;
  /**
   *
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblemAllOfInvalidPayload
   */
  tariffIsValidTo?: string;
}
/**
 * Content of a failed tariff validation error response due to provided invalid zipcode.
 * @export
 * @interface InvalidZipCodeValidationProblem
 */
export interface InvalidZipCodeValidationProblem {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof InvalidZipCodeValidationProblem
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof InvalidZipCodeValidationProblem
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof InvalidZipCodeValidationProblem
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof InvalidZipCodeValidationProblem
   */
  details?: string;
  /**
   * Dictionary containing validation errors.
   * @type {{ [key: string]: string; }}
   * @memberof InvalidZipCodeValidationProblem
   */
  validationErrors?: { [key: string]: string };
  /**
   *
   * @type {InvalidZipCodeValidationProblemAllOfInvalidPayload}
   * @memberof InvalidZipCodeValidationProblem
   */
  invalidPayload?: InvalidZipCodeValidationProblemAllOfInvalidPayload;
}
/**
 * Additional information about the tariff validation error.
 * @export
 * @interface InvalidZipCodeValidationProblemAllOfInvalidPayload
 */
export interface InvalidZipCodeValidationProblemAllOfInvalidPayload {
  /**
   *
   * @type {string}
   * @memberof InvalidZipCodeValidationProblemAllOfInvalidPayload
   */
  zipCode: string;
}
/**
 *  networkOperator = power grid operator  serviceProvider = Envera  supplier = Polarstern
 * @export
 * @enum {string}
 */

export const MeterReadingType = {
  Customer: "customer",
  NetworkOperator: "networkOperator",
  NetworkOperatorEstimate: "networkOperatorEstimate",
  ServiceProviderEstimate: "serviceProviderEstimate",
  Supplier: "supplier",
} as const;

export type MeterReadingType = (typeof MeterReadingType)[keyof typeof MeterReadingType];

/**
 *
 * @export
 * @enum {string}
 */

export const OrderReason = {
  NewMeter: "newMeter",
  Move: "move",
  SupplierChange: "supplierChange",
  FromDefaultService: "fromDefaultService",
} as const;

export type OrderReason = (typeof OrderReason)[keyof typeof OrderReason];

/**
 * @type PersonalData
 * @export
 */
export type PersonalData = PersonalDataOneOf | PersonalDataOneOf1;

/**
 *
 * @export
 * @interface PersonalDataOneOf
 */
export interface PersonalDataOneOf {
  /**
   *
   * @type {string}
   * @memberof PersonalDataOneOf
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataOneOf
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataOneOf
   */
  dateOfBirth: string;
}
/**
 *
 * @export
 * @interface PersonalDataOneOf1
 */
export interface PersonalDataOneOf1 {
  /**
   *
   * @type {string}
   * @memberof PersonalDataOneOf1
   */
  companyName: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataOneOf1
   */
  careOf?: string;
}
/**
 * @type PersonalDataWithMinimalValues
 * @export
 */
export type PersonalDataWithMinimalValues = CompanyPersonalData | PrivatePersonalData;

/**
 *
 * @export
 * @interface PhoneNumber
 */
export interface PhoneNumber {
  /**
   *
   * @type {string}
   * @memberof PhoneNumber
   */
  value: string;
  /**
   * This flag indicates if the person also wants to be contacted via SMS.
   * @type {boolean}
   * @memberof PhoneNumber
   */
  isSmsAllowed?: boolean;
}
/**
 *
 * @export
 * @interface PlainEmailAddress
 */
export interface PlainEmailAddress {
  /**
   *
   * @type {string}
   * @memberof PlainEmailAddress
   */
  value: string;
}
/**
 *
 * @export
 * @interface PreviousContract
 */
export interface PreviousContract {
  /**
   * Date at which the previous contract was or will be terminated. If this is not provided on contract creation, Polarstern needs to terminate the contract on behalf of the customer.
   * @type {string}
   * @memberof PreviousContract
   */
  terminatedAt?: string;
  /**
   * If the previous contract was a Polarstern contract, its ID can be specified here to replace it with the new contract.
   * @type {string}
   * @memberof PreviousContract
   */
  id?: string;
  /**
   * Name of the supplier with whom the previous contract was concluded.
   * @type {string}
   * @memberof PreviousContract
   */
  previousSupplierName: string;
}
/**
 *
 * @export
 * @interface Price
 */
export interface Price {
  /**
   * The price\'s currency as a ISO 4217 alphabetic code.
   * @type {string}
   * @memberof Price
   */
  currencyCode: string;
  /**
   * The actual price value.
   * @type {string}
   * @memberof Price
   */
  value: string;
}
/**
 *
 * @export
 * @interface PrivatePersonalData
 */
export interface PrivatePersonalData {
  /**
   *
   * @type {string}
   * @memberof PrivatePersonalData
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PrivatePersonalData
   */
  lastName: string;
}
/**
 * Content of an error response, based on https://datatracker.ietf.org/doc/html/rfc7807.
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof ProblemDetails
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof ProblemDetails
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof ProblemDetails
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof ProblemDetails
   */
  details?: string;
}
/**
 *
 * @export
 * @interface ReadonlyConfirmedSupply
 */
export interface ReadonlyConfirmedSupply {
  /**
   * Date of requested supply of a customer. The value is required for the creation of a tenant power contract.
   * @type {string}
   * @memberof ReadonlyConfirmedSupply
   */
  requestedBeginAt?: string;
  /**
   * Supply address. With exception of MaLo, it is also used as billing address, as long as no separate billing address is provided.
   * @type {Address}
   * @memberof ReadonlyConfirmedSupply
   */
  address?: Address;
  /**
   *
   * @type {any}
   * @memberof ReadonlyConfirmedSupply
   */
  confirmedBeginAt?: any;
  /**
   *
   * @type {any}
   * @memberof ReadonlyConfirmedSupply
   */
  confirmedAt?: any;
}
/**
 * A recommendation from one user to another.
 * @export
 * @interface Recommendation
 */
export interface Recommendation {
  /**
   *
   * @type {string}
   * @memberof Recommendation
   */
  channel: RecommendationChannelEnum;
  /**
   * The recommendation code the receiver should use when signing up at Polarstern.
   * @type {string}
   * @memberof Recommendation
   */
  code: string;
  /**
   * The referrer\'s email address. If a customer with this email address exists, the recommendation will be associated with it.
   * @type {string}
   * @memberof Recommendation
   */
  emailAddress: string;
  /**
   * The referrers customerId. May be empty if the referrer uses a non unique mail address.
   * @type {string}
   * @memberof Recommendation
   */
  referredBy?: string;
  /**
   * The redeemers customerId. May be empty if this recommendation has not been redeemed yet.
   * @type {string}
   * @memberof Recommendation
   */
  redeemedBy?: string;
  /**
   * The date the recommendation was redeemed at.
   * @type {string}
   * @memberof Recommendation
   */
  redeemedAt?: string;
  /**
   * The date the recommendation was paid out at.
   * @type {string}
   * @memberof Recommendation
   */
  paidOutAt?: string;
}

export const RecommendationChannelEnum = {
  Email: "email",
  Link: "link",
  WhatsApp: "whatsApp",
  Sms: "sms",
} as const;

export type RecommendationChannelEnum =
  (typeof RecommendationChannelEnum)[keyof typeof RecommendationChannelEnum];

/**
 *
 * @export
 * @interface ReplaceCustomerPasswordRequest
 */
export interface ReplaceCustomerPasswordRequest {
  /**
   *
   * @type {string}
   * @memberof ReplaceCustomerPasswordRequest
   */
  currentPassword: string;
  /**
   *
   * @type {string}
   * @memberof ReplaceCustomerPasswordRequest
   */
  newPassword: string;
}
/**
 *
 * @export
 * @interface SepaMandate
 */
export interface SepaMandate {
  /**
   * The date and time at which the customer accepted the SEPA mandate.
   * @type {string}
   * @memberof SepaMandate
   */
  acceptedAt: string;
  /**
   *
   * @type {any}
   * @memberof SepaMandate
   */
  reference?: any;
}
/**
 *
 * @export
 * @interface SwitchTariffRequest
 */
export interface SwitchTariffRequest {
  /**
   * The tariff\'s ID.
   * @type {string}
   * @memberof SwitchTariffRequest
   */
  tariffId: string;
}
/**
 *
 * @export
 * @interface TariffHistoryInner
 */
export interface TariffHistoryInner {
  /**
   * The ID of the tariff.
   * @type {string}
   * @memberof TariffHistoryInner
   */
  tariffId: string;
  /**
   * The confirmed date from which the contract was, is or will be supplied through this tariff.
   * @type {string}
   * @memberof TariffHistoryInner
   */
  validFrom?: string;
  /**
   * The earliest date at which this contract can be terminated, given this history entry is the current one. If not set, the contract can be terminated any time.
   * @type {string}
   * @memberof TariffHistoryInner
   */
  terminableFrom?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TenantPowerContractType = {
  TenantPower: "tenantPower",
  TenantPowerShadow: "tenantPowerShadow",
} as const;

export type TenantPowerContractType =
  (typeof TenantPowerContractType)[keyof typeof TenantPowerContractType];

/**
 *
 * @export
 * @interface Termination
 */
export interface Termination {
  /**
   * Unique ID of the terminated contract.
   * @type {string}
   * @memberof Termination
   */
  contractId?: string;
  /**
   * Date at which the contract was or will be terminated.
   * @type {string}
   * @memberof Termination
   */
  terminatedAt: string;
  /**
   * If the customer moves to a new address and wants to stay with Polarstern. This is required to decide which document to send to the customer (Kündigungsbestätigung oder Abmeldebestätigung).
   * @type {boolean}
   * @memberof Termination
   */
  movedToNewAddress?: boolean;
  /**
   *
   * @type {BaseTerminationReason}
   * @memberof Termination
   */
  reason: BaseTerminationReason;
  /**
   *
   * @type {string}
   * @memberof Termination
   */
  note?: string;
  /**
   * Date at which the termination was confirmed by the network provider.
   * @type {string}
   * @memberof Termination
   */
  confirmedAt?: string;
  /**
   * Date and time at which the termination was created.
   * @type {string}
   * @memberof Termination
   */
  createdAt: string;
  /**
   * If the termination was created by an admin.
   * @type {boolean}
   * @memberof Termination
   */
  hasBeenTerminatedByAdmin?: boolean;
  /**
   *
   * @type {TerminationType}
   * @memberof Termination
   */
  type: TerminationType;
}
/**
 *
 * @export
 * @interface TerminationAsCustomer
 */
export interface TerminationAsCustomer {
  /**
   * Unique ID of the terminated contract.
   * @type {string}
   * @memberof TerminationAsCustomer
   */
  contractId?: string;
  /**
   * Date at which the contract was or will be terminated.
   * @type {string}
   * @memberof TerminationAsCustomer
   */
  terminatedAt: string;
  /**
   * If the customer moves to a new address and wants to stay with Polarstern. This is required to decide which document to send to the customer (Kündigungsbestätigung oder Abmeldebestätigung).
   * @type {boolean}
   * @memberof TerminationAsCustomer
   */
  movedToNewAddress?: boolean;
  /**
   *
   * @type {BaseTerminationReason}
   * @memberof TerminationAsCustomer
   */
  reason: BaseTerminationReason;
  /**
   *
   * @type {string}
   * @memberof TerminationAsCustomer
   */
  note?: string;
  /**
   *
   * @type {any}
   * @memberof TerminationAsCustomer
   */
  confirmedAt?: any;
  /**
   * Date and time at which the termination was created.
   * @type {string}
   * @memberof TerminationAsCustomer
   */
  createdAt: string;
  /**
   * If the termination was created by an admin.
   * @type {boolean}
   * @memberof TerminationAsCustomer
   */
  hasBeenTerminatedByAdmin?: boolean;
  /**
   *
   * @type {TerminationTypeAsCustomer}
   * @memberof TerminationAsCustomer
   */
  type: TerminationTypeAsCustomer;
}

/**
 *
 * @export
 * @enum {string}
 */

export const TerminationReason = {
  Price: "price",
  ProviderSwitch: "providerSwitch",
  MoveToExistingContract: "moveToExistingContract",
  MoveAbroad: "moveAbroad",
  Move: "move",
  NoNewApartment: "noNewApartment",
  ProviderRestriction: "providerRestriction",
  MeterRemoval: "meterRemoval",
  Death: "death",
  UnsatisfactoryService: "unsatisfactoryService",
  NoGasConnection: "noGasConnection",
} as const;

export type TerminationReason =
  (typeof TerminationReason)[keyof typeof TerminationReason];

/**
 *
 * @export
 * @interface TerminationType
 */
export interface TerminationType {}
/**
 *
 * @export
 * @enum {string}
 */

export const TerminationTypeAsAdmin = {
  Extraordinary: "extraordinary",
  Cancellation: "cancellation",
} as const;

export type TerminationTypeAsAdmin =
  (typeof TerminationTypeAsAdmin)[keyof typeof TerminationTypeAsAdmin];

/**
 *
 * @export
 * @enum {string}
 */

export const TerminationTypeAsCustomer = {
  Regular: "regular",
  Move: "move",
  Transfer: "transfer",
} as const;

export type TerminationTypeAsCustomer =
  (typeof TerminationTypeAsCustomer)[keyof typeof TerminationTypeAsCustomer];

/**
 * @type Todo
 * @export
 */
export type Todo = ContractTodo | CustomerTodo;

/**
 *
 * @export
 * @interface Transfer
 */
export interface Transfer {
  /**
   *
   * @type {string}
   * @memberof Transfer
   */
  id: string;
  /**
   * The list of contracts that should be transferred.
   * @type {Array<TransferContract>}
   * @memberof Transfer
   */
  contracts?: Array<TransferContract>;
  /**
   * The date at which the contracts were or will be transferred. The termination date of the previous contract will be transferredAt - 1 day.
   * @type {string}
   * @memberof Transfer
   */
  transferredAt: string;
  /**
   * Name of the sender that originated the transfer. Might be the person\'s full name or a company name.
   * @type {string}
   * @memberof Transfer
   */
  sender: string;
  /**
   *
   * @type {TransferRecipient}
   * @memberof Transfer
   */
  recipient: TransferRecipient;
}
/**
 *
 * @export
 * @interface TransferContract
 */
export interface TransferContract {
  /**
   * ID of the original contract that is being transferred.
   * @type {string}
   * @memberof TransferContract
   */
  id: string;
  /**
   *
   * @type {ContractType}
   * @memberof TransferContract
   */
  type: ContractType;
  /**
   * The serial number of the meter. A serial number is NOT unique but duplicates are very rare.
   * @type {string}
   * @memberof TransferContract
   */
  meterSerialNumber: string;
  /**
   * Supply address of the original contract.
   * @type {Address}
   * @memberof TransferContract
   */
  supplyAddress: Address;
  /**
   * A list of tariffs that are, were or will be used by this contract ordered by its validity starting with the oldest tariff.
   * @type {Array<TariffHistoryInner>}
   * @memberof TransferContract
   */
  tariffHistory: Array<TariffHistoryInner>;
  /**
   * The (possibly estimated) annual consumption of the original contract.
   * @type {Energy}
   * @memberof TransferContract
   */
  annualConsumption?: Energy;
  /**
   * The (possibly estimated) low tariff annual consumption of the original contract. Can only be set for HT/NT-tariffs.
   * @type {Energy}
   * @memberof TransferContract
   */
  annualConsumptionLowTariff?: Energy;
}

/**
 * The recipient to receive the transferred contracts.
 * @export
 * @interface TransferRecipient
 */
export interface TransferRecipient {
  /**
   *
   * @type {EntityType}
   * @memberof TransferRecipient
   */
  type: EntityType;
  /**
   *
   * @type {string}
   * @memberof TransferRecipient
   */
  emailAddress: string;
  /**
   *
   * @type {PersonalDataWithMinimalValues}
   * @memberof TransferRecipient
   */
  personalData: PersonalDataWithMinimalValues;
}

/**
 * ContractsApi - axios parameter creator
 * @export
 */
export const ContractsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Creates a new contract, adds it to the specified customer and returns it.
     * @summary Add contract
     * @param {AddContractRequest} [addContractRequest] Request to create a contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addContract: async (
      addContractRequest?: AddContractRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/contracts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addContractRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes the contract partner.
     * @summary Delete contract partner
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteContractPartner: async (
      contractId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("deleteContractPartner", "contractId", contractId);
      const localVarPath = `/contracts/{contractId}/contract-partner`.replace(
        `{${"contractId"}}`,
        encodeURIComponent(String(contractId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets all contracts of the specified customer.
     * @summary Get contracts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerContracts: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/contracts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Posts a meter reading
     * @summary Meter reading
     * @param {string} contractId ID of the contract.
     * @param {DeprecatedMeterReading} [deprecatedMeterReading] Request to add a meter reading.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMeterReading: async (
      contractId: string,
      deprecatedMeterReading?: DeprecatedMeterReading,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("postMeterReading", "contractId", contractId);
      const localVarPath = `/contracts/{contractId}/meter-readings`.replace(
        `{${"contractId"}}`,
        encodeURIComponent(String(contractId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deprecatedMeterReading,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Replaces the bank account.
     * @summary Replace bank account
     * @param {string} contractId ID of the contract.
     * @param {BankAccount} [bankAccount] Request to replace a bank account.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    replaceContractBankAccount: async (
      contractId: string,
      bankAccount?: BankAccount,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("replaceContractBankAccount", "contractId", contractId);
      const localVarPath = `/contracts/{contractId}/bank-account`.replace(
        `{${"contractId"}}`,
        encodeURIComponent(String(contractId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bankAccount,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Replaces the billing address.
     * @summary Replace billing address
     * @param {string} contractId ID of the contract.
     * @param {Address} [address] Request to replace a billing address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    replaceContractBillingAddress: async (
      contractId: string,
      address?: Address,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("replaceContractBillingAddress", "contractId", contractId);
      const localVarPath = `/contracts/{contractId}/billing-address`.replace(
        `{${"contractId"}}`,
        encodeURIComponent(String(contractId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        address,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Replaces the contract partner.
     * @summary Replace contract partner
     * @param {string} contractId ID of the contract.
     * @param {ContractPartner} [contractPartner] Request to replace a contract partner.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    replaceContractPartner: async (
      contractId: string,
      contractPartner?: ContractPartner,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("replaceContractPartner", "contractId", contractId);
      const localVarPath = `/contracts/{contractId}/contract-partner`.replace(
        `{${"contractId"}}`,
        encodeURIComponent(String(contractId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        contractPartner,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Switches tariff of the specified contract.
     * @summary Switch tariff
     * @param {string} contractId ID of the contract.
     * @param {SwitchTariffRequest} [switchTariffRequest] Request to switch contract to the provided tariff.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchTariff: async (
      contractId: string,
      switchTariffRequest?: SwitchTariffRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("switchTariff", "contractId", contractId);
      const localVarPath = `/contracts/{contractId}/switch-tariff`.replace(
        `{${"contractId"}}`,
        encodeURIComponent(String(contractId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        switchTariffRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Adds a termination to the specified contract (i.e. terminates the contract) and returns it.
     * @summary Terminate contract
     * @param {string} contractId ID of the contract.
     * @param {TerminationAsCustomer} [terminationAsCustomer] Request to create a contract termination as customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    terminateContract: async (
      contractId: string,
      terminationAsCustomer?: TerminationAsCustomer,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("terminateContract", "contractId", contractId);
      const localVarPath = `/contracts/{contractId}/termination`.replace(
        `{${"contractId"}}`,
        encodeURIComponent(String(contractId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terminationAsCustomer,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates the specified contract.
     * @summary Update contract
     * @param {string} contractId ID of the contract.
     * @param {ContractUpdateAsCustomer} [contractUpdateAsCustomer] Request to update a contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateContract: async (
      contractId: string,
      contractUpdateAsCustomer?: ContractUpdateAsCustomer,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("updateContract", "contractId", contractId);
      const localVarPath = `/contracts/{contractId}`.replace(
        `{${"contractId"}}`,
        encodeURIComponent(String(contractId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/merge-patch+json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        contractUpdateAsCustomer,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ContractsApi - functional programming interface
 * @export
 */
export const ContractsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContractsApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates a new contract, adds it to the specified customer and returns it.
     * @summary Add contract
     * @param {AddContractRequest} [addContractRequest] Request to create a contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addContract(
      addContractRequest?: AddContractRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddContractRequest>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addContract(
        addContractRequest,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.addContract"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Deletes the contract partner.
     * @summary Delete contract partner
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteContractPartner(
      contractId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContractPartner(
        contractId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.deleteContractPartner"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Gets all contracts of the specified customer.
     * @summary Get contracts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomerContracts(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCustomerContracts200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCustomerContracts(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.getCustomerContracts"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Posts a meter reading
     * @summary Meter reading
     * @param {string} contractId ID of the contract.
     * @param {DeprecatedMeterReading} [deprecatedMeterReading] Request to add a meter reading.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postMeterReading(
      contractId: string,
      deprecatedMeterReading?: DeprecatedMeterReading,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeprecatedMeterReading>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postMeterReading(
        contractId,
        deprecatedMeterReading,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.postMeterReading"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Replaces the bank account.
     * @summary Replace bank account
     * @param {string} contractId ID of the contract.
     * @param {BankAccount} [bankAccount] Request to replace a bank account.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async replaceContractBankAccount(
      contractId: string,
      bankAccount?: BankAccount,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAccount>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.replaceContractBankAccount(
          contractId,
          bankAccount,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.replaceContractBankAccount"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Replaces the billing address.
     * @summary Replace billing address
     * @param {string} contractId ID of the contract.
     * @param {Address} [address] Request to replace a billing address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async replaceContractBillingAddress(
      contractId: string,
      address?: Address,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.replaceContractBillingAddress(
          contractId,
          address,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.replaceContractBillingAddress"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Replaces the contract partner.
     * @summary Replace contract partner
     * @param {string} contractId ID of the contract.
     * @param {ContractPartner} [contractPartner] Request to replace a contract partner.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async replaceContractPartner(
      contractId: string,
      contractPartner?: ContractPartner,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractPartner>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.replaceContractPartner(
        contractId,
        contractPartner,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.replaceContractPartner"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Switches tariff of the specified contract.
     * @summary Switch tariff
     * @param {string} contractId ID of the contract.
     * @param {SwitchTariffRequest} [switchTariffRequest] Request to switch contract to the provided tariff.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async switchTariff(
      contractId: string,
      switchTariffRequest?: SwitchTariffRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.switchTariff(
        contractId,
        switchTariffRequest,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.switchTariff"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Adds a termination to the specified contract (i.e. terminates the contract) and returns it.
     * @summary Terminate contract
     * @param {string} contractId ID of the contract.
     * @param {TerminationAsCustomer} [terminationAsCustomer] Request to create a contract termination as customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async terminateContract(
      contractId: string,
      terminationAsCustomer?: TerminationAsCustomer,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Termination>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.terminateContract(
        contractId,
        terminationAsCustomer,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.terminateContract"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Updates the specified contract.
     * @summary Update contract
     * @param {string} contractId ID of the contract.
     * @param {ContractUpdateAsCustomer} [contractUpdateAsCustomer] Request to update a contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateContract(
      contractId: string,
      contractUpdateAsCustomer?: ContractUpdateAsCustomer,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractAsCustomer>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateContract(
        contractId,
        contractUpdateAsCustomer,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.updateContract"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ContractsApi - factory interface
 * @export
 */
export const ContractsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContractsApiFp(configuration);
  return {
    /**
     * Creates a new contract, adds it to the specified customer and returns it.
     * @summary Add contract
     * @param {AddContractRequest} [addContractRequest] Request to create a contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addContract(
      addContractRequest?: AddContractRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AddContractRequest> {
      return localVarFp
        .addContract(addContractRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Deletes the contract partner.
     * @summary Delete contract partner
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteContractPartner(
      contractId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteContractPartner(contractId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets all contracts of the specified customer.
     * @summary Get contracts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerContracts(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetCustomerContracts200Response> {
      return localVarFp
        .getCustomerContracts(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Posts a meter reading
     * @summary Meter reading
     * @param {string} contractId ID of the contract.
     * @param {DeprecatedMeterReading} [deprecatedMeterReading] Request to add a meter reading.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMeterReading(
      contractId: string,
      deprecatedMeterReading?: DeprecatedMeterReading,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DeprecatedMeterReading> {
      return localVarFp
        .postMeterReading(contractId, deprecatedMeterReading, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Replaces the bank account.
     * @summary Replace bank account
     * @param {string} contractId ID of the contract.
     * @param {BankAccount} [bankAccount] Request to replace a bank account.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    replaceContractBankAccount(
      contractId: string,
      bankAccount?: BankAccount,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<BankAccount> {
      return localVarFp
        .replaceContractBankAccount(contractId, bankAccount, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Replaces the billing address.
     * @summary Replace billing address
     * @param {string} contractId ID of the contract.
     * @param {Address} [address] Request to replace a billing address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    replaceContractBillingAddress(
      contractId: string,
      address?: Address,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Address> {
      return localVarFp
        .replaceContractBillingAddress(contractId, address, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Replaces the contract partner.
     * @summary Replace contract partner
     * @param {string} contractId ID of the contract.
     * @param {ContractPartner} [contractPartner] Request to replace a contract partner.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    replaceContractPartner(
      contractId: string,
      contractPartner?: ContractPartner,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ContractPartner> {
      return localVarFp
        .replaceContractPartner(contractId, contractPartner, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Switches tariff of the specified contract.
     * @summary Switch tariff
     * @param {string} contractId ID of the contract.
     * @param {SwitchTariffRequest} [switchTariffRequest] Request to switch contract to the provided tariff.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchTariff(
      contractId: string,
      switchTariffRequest?: SwitchTariffRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .switchTariff(contractId, switchTariffRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Adds a termination to the specified contract (i.e. terminates the contract) and returns it.
     * @summary Terminate contract
     * @param {string} contractId ID of the contract.
     * @param {TerminationAsCustomer} [terminationAsCustomer] Request to create a contract termination as customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    terminateContract(
      contractId: string,
      terminationAsCustomer?: TerminationAsCustomer,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Termination> {
      return localVarFp
        .terminateContract(contractId, terminationAsCustomer, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates the specified contract.
     * @summary Update contract
     * @param {string} contractId ID of the contract.
     * @param {ContractUpdateAsCustomer} [contractUpdateAsCustomer] Request to update a contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateContract(
      contractId: string,
      contractUpdateAsCustomer?: ContractUpdateAsCustomer,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ContractAsCustomer> {
      return localVarFp
        .updateContract(contractId, contractUpdateAsCustomer, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ContractsApi - object-oriented interface
 * @export
 * @class ContractsApi
 * @extends {BaseAPI}
 */
export class ContractsApi extends BaseAPI {
  /**
   * Creates a new contract, adds it to the specified customer and returns it.
   * @summary Add contract
   * @param {AddContractRequest} [addContractRequest] Request to create a contract.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public addContract(
    addContractRequest?: AddContractRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .addContract(addContractRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Deletes the contract partner.
   * @summary Delete contract partner
   * @param {string} contractId ID of the contract.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public deleteContractPartner(contractId: string, options?: RawAxiosRequestConfig) {
    return ContractsApiFp(this.configuration)
      .deleteContractPartner(contractId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets all contracts of the specified customer.
   * @summary Get contracts
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public getCustomerContracts(options?: RawAxiosRequestConfig) {
    return ContractsApiFp(this.configuration)
      .getCustomerContracts(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Posts a meter reading
   * @summary Meter reading
   * @param {string} contractId ID of the contract.
   * @param {DeprecatedMeterReading} [deprecatedMeterReading] Request to add a meter reading.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public postMeterReading(
    contractId: string,
    deprecatedMeterReading?: DeprecatedMeterReading,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .postMeterReading(contractId, deprecatedMeterReading, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Replaces the bank account.
   * @summary Replace bank account
   * @param {string} contractId ID of the contract.
   * @param {BankAccount} [bankAccount] Request to replace a bank account.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public replaceContractBankAccount(
    contractId: string,
    bankAccount?: BankAccount,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .replaceContractBankAccount(contractId, bankAccount, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Replaces the billing address.
   * @summary Replace billing address
   * @param {string} contractId ID of the contract.
   * @param {Address} [address] Request to replace a billing address.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public replaceContractBillingAddress(
    contractId: string,
    address?: Address,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .replaceContractBillingAddress(contractId, address, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Replaces the contract partner.
   * @summary Replace contract partner
   * @param {string} contractId ID of the contract.
   * @param {ContractPartner} [contractPartner] Request to replace a contract partner.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public replaceContractPartner(
    contractId: string,
    contractPartner?: ContractPartner,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .replaceContractPartner(contractId, contractPartner, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Switches tariff of the specified contract.
   * @summary Switch tariff
   * @param {string} contractId ID of the contract.
   * @param {SwitchTariffRequest} [switchTariffRequest] Request to switch contract to the provided tariff.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public switchTariff(
    contractId: string,
    switchTariffRequest?: SwitchTariffRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .switchTariff(contractId, switchTariffRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Adds a termination to the specified contract (i.e. terminates the contract) and returns it.
   * @summary Terminate contract
   * @param {string} contractId ID of the contract.
   * @param {TerminationAsCustomer} [terminationAsCustomer] Request to create a contract termination as customer.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public terminateContract(
    contractId: string,
    terminationAsCustomer?: TerminationAsCustomer,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .terminateContract(contractId, terminationAsCustomer, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates the specified contract.
   * @summary Update contract
   * @param {string} contractId ID of the contract.
   * @param {ContractUpdateAsCustomer} [contractUpdateAsCustomer] Request to update a contract.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public updateContract(
    contractId: string,
    contractUpdateAsCustomer?: ContractUpdateAsCustomer,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .updateContract(contractId, contractUpdateAsCustomer, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Gets the customer that is logged in.
     * @summary Get customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomer: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/customers/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all recommendations
     * @summary Get recommendations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendations: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/recommendations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Replace email address of a customer.
     * @summary Replace email address
     * @param {EmailAddress} [emailAddress] Request to update an email address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceCustomerEmailAddress: async (
      emailAddress?: EmailAddress,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/email-address`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        emailAddress,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Replaces the password.
     * @summary Replace password
     * @param {ReplaceCustomerPasswordRequest} [replaceCustomerPasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceCustomerPassword: async (
      replaceCustomerPasswordRequest?: ReplaceCustomerPasswordRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        replaceCustomerPasswordRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Replace personal data of a customer.
     * @summary Replace personal data
     * @param {PersonalData} [personalData] Request to update personal data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceCustomerPersonalData: async (
      personalData?: PersonalData,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/personal-data`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        personalData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Replace phone number of a customer.
     * @summary Replace phone number
     * @param {PhoneNumber} [phoneNumber] Request to update a phone number.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceCustomerPhoneNumber: async (
      phoneNumber?: PhoneNumber,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/phone-number`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        phoneNumber,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration);
  return {
    /**
     * Gets the customer that is logged in.
     * @summary Get customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomer(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomer(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomersApi.getCustomer"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get all recommendations
     * @summary Get recommendations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRecommendations(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetRecommendations200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRecommendations(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomersApi.getRecommendations"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Replace email address of a customer.
     * @summary Replace email address
     * @param {EmailAddress} [emailAddress] Request to update an email address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async replaceCustomerEmailAddress(
      emailAddress?: EmailAddress,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailAddress>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.replaceCustomerEmailAddress(
          emailAddress,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomersApi.replaceCustomerEmailAddress"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Replaces the password.
     * @summary Replace password
     * @param {ReplaceCustomerPasswordRequest} [replaceCustomerPasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async replaceCustomerPassword(
      replaceCustomerPasswordRequest?: ReplaceCustomerPasswordRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.replaceCustomerPassword(
        replaceCustomerPasswordRequest,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomersApi.replaceCustomerPassword"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Replace personal data of a customer.
     * @summary Replace personal data
     * @param {PersonalData} [personalData] Request to update personal data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async replaceCustomerPersonalData(
      personalData?: PersonalData,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonalData>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.replaceCustomerPersonalData(
          personalData,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomersApi.replaceCustomerPersonalData"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Replace phone number of a customer.
     * @summary Replace phone number
     * @param {PhoneNumber} [phoneNumber] Request to update a phone number.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async replaceCustomerPhoneNumber(
      phoneNumber?: PhoneNumber,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhoneNumber>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.replaceCustomerPhoneNumber(phoneNumber, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomersApi.replaceCustomerPhoneNumber"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CustomersApiFp(configuration);
  return {
    /**
     * Gets the customer that is logged in.
     * @summary Get customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomer(options?: RawAxiosRequestConfig): AxiosPromise<Customer> {
      return localVarFp.getCustomer(options).then((request) => request(axios, basePath));
    },
    /**
     * Get all recommendations
     * @summary Get recommendations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendations(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetRecommendations200Response> {
      return localVarFp
        .getRecommendations(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Replace email address of a customer.
     * @summary Replace email address
     * @param {EmailAddress} [emailAddress] Request to update an email address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceCustomerEmailAddress(
      emailAddress?: EmailAddress,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<EmailAddress> {
      return localVarFp
        .replaceCustomerEmailAddress(emailAddress, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Replaces the password.
     * @summary Replace password
     * @param {ReplaceCustomerPasswordRequest} [replaceCustomerPasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceCustomerPassword(
      replaceCustomerPasswordRequest?: ReplaceCustomerPasswordRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .replaceCustomerPassword(replaceCustomerPasswordRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Replace personal data of a customer.
     * @summary Replace personal data
     * @param {PersonalData} [personalData] Request to update personal data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceCustomerPersonalData(
      personalData?: PersonalData,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PersonalData> {
      return localVarFp
        .replaceCustomerPersonalData(personalData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Replace phone number of a customer.
     * @summary Replace phone number
     * @param {PhoneNumber} [phoneNumber] Request to update a phone number.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceCustomerPhoneNumber(
      phoneNumber?: PhoneNumber,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PhoneNumber> {
      return localVarFp
        .replaceCustomerPhoneNumber(phoneNumber, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
  /**
   * Gets the customer that is logged in.
   * @summary Get customer
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public getCustomer(options?: RawAxiosRequestConfig) {
    return CustomersApiFp(this.configuration)
      .getCustomer(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all recommendations
   * @summary Get recommendations
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public getRecommendations(options?: RawAxiosRequestConfig) {
    return CustomersApiFp(this.configuration)
      .getRecommendations(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Replace email address of a customer.
   * @summary Replace email address
   * @param {EmailAddress} [emailAddress] Request to update an email address.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public replaceCustomerEmailAddress(
    emailAddress?: EmailAddress,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomersApiFp(this.configuration)
      .replaceCustomerEmailAddress(emailAddress, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Replaces the password.
   * @summary Replace password
   * @param {ReplaceCustomerPasswordRequest} [replaceCustomerPasswordRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public replaceCustomerPassword(
    replaceCustomerPasswordRequest?: ReplaceCustomerPasswordRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomersApiFp(this.configuration)
      .replaceCustomerPassword(replaceCustomerPasswordRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Replace personal data of a customer.
   * @summary Replace personal data
   * @param {PersonalData} [personalData] Request to update personal data.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public replaceCustomerPersonalData(
    personalData?: PersonalData,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomersApiFp(this.configuration)
      .replaceCustomerPersonalData(personalData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Replace phone number of a customer.
   * @summary Replace phone number
   * @param {PhoneNumber} [phoneNumber] Request to update a phone number.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public replaceCustomerPhoneNumber(
    phoneNumber?: PhoneNumber,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomersApiFp(this.configuration)
      .replaceCustomerPhoneNumber(phoneNumber, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get document for id.
     * @summary Get document
     * @param {string} contractId ID of the contract.
     * @param {string} documentId ID of the document.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocument: async (
      contractId: string,
      documentId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("getDocument", "contractId", contractId);
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists("getDocument", "documentId", documentId);
      const localVarPath = `/contracts/{contractId}/documents/{documentId}`
        .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
        .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get documents for a contract.
     * @summary Get documents
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocuments: async (
      contractId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("getDocuments", "contractId", contractId);
      const localVarPath = `/contracts/{contractId}/documents`.replace(
        `{${"contractId"}}`,
        encodeURIComponent(String(contractId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get document for id.
     * @summary Get document
     * @param {string} contractId ID of the contract.
     * @param {string} documentId ID of the document.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDocument(
      contractId: string,
      documentId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(
        contractId,
        documentId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DocumentsApi.getDocument"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get documents for a contract.
     * @summary Get documents
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDocuments(
      contractId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocuments200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDocuments(
        contractId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DocumentsApi.getDocuments"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DocumentsApiFp(configuration);
  return {
    /**
     * Get document for id.
     * @summary Get document
     * @param {string} contractId ID of the contract.
     * @param {string} documentId ID of the document.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocument(
      contractId: string,
      documentId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Document> {
      return localVarFp
        .getDocument(contractId, documentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get documents for a contract.
     * @summary Get documents
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocuments(
      contractId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetDocuments200Response> {
      return localVarFp
        .getDocuments(contractId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
  /**
   * Get document for id.
   * @summary Get document
   * @param {string} contractId ID of the contract.
   * @param {string} documentId ID of the document.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public getDocument(
    contractId: string,
    documentId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return DocumentsApiFp(this.configuration)
      .getDocument(contractId, documentId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get documents for a contract.
   * @summary Get documents
   * @param {string} contractId ID of the contract.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public getDocuments(contractId: string, options?: RawAxiosRequestConfig) {
    return DocumentsApiFp(this.configuration)
      .getDocuments(contractId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TransfersApi - axios parameter creator
 * @export
 */
export const TransfersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Adds a transfer of contracts to a customer.
     * @summary Transfer contracts
     * @param {Transfer} [transfer] Request to transfer a contract
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferCustomerContract: async (
      transfer?: Transfer,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/transfers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transfer,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TransfersApi - functional programming interface
 * @export
 */
export const TransfersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TransfersApiAxiosParamCreator(configuration);
  return {
    /**
     * Adds a transfer of contracts to a customer.
     * @summary Transfer contracts
     * @param {Transfer} [transfer] Request to transfer a contract
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transferCustomerContract(
      transfer?: Transfer,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transfer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transferCustomerContract(
        transfer,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TransfersApi.transferCustomerContract"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TransfersApi - factory interface
 * @export
 */
export const TransfersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TransfersApiFp(configuration);
  return {
    /**
     * Adds a transfer of contracts to a customer.
     * @summary Transfer contracts
     * @param {Transfer} [transfer] Request to transfer a contract
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferCustomerContract(
      transfer?: Transfer,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Transfer> {
      return localVarFp
        .transferCustomerContract(transfer, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TransfersApi - object-oriented interface
 * @export
 * @class TransfersApi
 * @extends {BaseAPI}
 */
export class TransfersApi extends BaseAPI {
  /**
   * Adds a transfer of contracts to a customer.
   * @summary Transfer contracts
   * @param {Transfer} [transfer] Request to transfer a contract
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransfersApi
   */
  public transferCustomerContract(transfer?: Transfer, options?: RawAxiosRequestConfig) {
    return TransfersApiFp(this.configuration)
      .transferCustomerContract(transfer, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
