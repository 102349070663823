/* tslint:disable */
/* eslint-disable */
/**
 * Polarstern Admintool API Specification
 * This definition contains the endpoints of the Polarstern Admintool API.
 *
 * The version of the OpenAPI document: 2.37.0
 * Contact: devs@polarstern-energie.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from "./base";

/**
 *
 * @export
 * @interface AddContractsBatchRequest
 */
export interface AddContractsBatchRequest {
  /**
   *
   * @type {Array<ContractAsAdmin>}
   * @memberof AddContractsBatchRequest
   */
  items: Array<ContractAsAdmin>;
}
/**
 *
 * @export
 * @interface AddMeasuringPointsBatchRequest
 */
export interface AddMeasuringPointsBatchRequest {
  /**
   *
   * @type {Array<MeasuringPointWithMeterAsAdmin>}
   * @memberof AddMeasuringPointsBatchRequest
   */
  items: Array<MeasuringPointWithMeterAsAdmin>;
}
/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   * This covers all potential additions for addresses like a sub compartment type.
   * @type {string}
   * @memberof Address
   */
  additionalInformation?: string;
  /**
   * Unique identifier of the market location within the regulated energy market.
   * @type {string}
   * @memberof Address
   */
  maLo?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  city: string;
  /**
   *
   * @type {EntityType}
   * @memberof Address
   */
  type: EntityType;
  /**
   * Required if `type=private`.
   * @type {string}
   * @memberof Address
   */
  firstName?: string;
  /**
   * Required if `type=private`.
   * @type {string}
   * @memberof Address
   */
  lastName?: string;
  /**
   * Required if `type=company|ngo`.
   * @type {string}
   * @memberof Address
   */
  companyName?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  careOf?: string;
  /**
   * The two letter country code following ISO 3166-1 alpha-2 standard.
   * @type {string}
   * @memberof Address
   */
  country: string;
}

/**
 *
 * @export
 * @interface AddressWithAdditionalInformation
 */
export interface AddressWithAdditionalInformation {
  /**
   * This covers all potential additions for addresses like a sub compartment type.
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  additionalInformation?: string;
  /**
   * Unique identifier of the market location within the regulated energy market.
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  maLo?: string;
  /**
   *
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  city: string;
}
/**
 *
 * @export
 * @interface Bank
 */
export interface Bank {
  /**
   * The name of the bank.
   * @type {string}
   * @memberof Bank
   */
  name: string;
  /**
   * The Bank Identifier Code (BIC), also known as Swift-Code, of the bank.
   * @type {string}
   * @memberof Bank
   */
  bic: string;
}
/**
 *
 * @export
 * @interface BankAccount
 */
export interface BankAccount {
  /**
   *
   * @type {Bank}
   * @memberof BankAccount
   */
  bank?: Bank;
  /**
   * Display name of the bank account, may be set by the customer.
   * @type {string}
   * @memberof BankAccount
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BankAccount
   */
  iban: string;
  /**
   * The name of the account holder.
   * @type {string}
   * @memberof BankAccount
   */
  accountHolder: string;
}
/**
 *
 * @export
 * @interface BaseAddress
 */
export interface BaseAddress {
  /**
   *
   * @type {string}
   * @memberof BaseAddress
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddress
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddress
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddress
   */
  city: string;
}
/**
 *
 * @export
 * @interface BaseAddressWithMaLo
 */
export interface BaseAddressWithMaLo {
  /**
   * Unique identifier of the market location within the regulated energy market.
   * @type {string}
   * @memberof BaseAddressWithMaLo
   */
  maLo?: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddressWithMaLo
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddressWithMaLo
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddressWithMaLo
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddressWithMaLo
   */
  city: string;
}
/**
 *
 * @export
 * @interface BaseContract
 */
export interface BaseContract {
  /**
   * Unique ID of the contract.
   * @type {string}
   * @memberof BaseContract
   */
  id: string;
  /**
   *
   * @type {ContractType}
   * @memberof BaseContract
   */
  type: ContractType;
  /**
   * The customer\'s estimate on their annual energy consumption.
   * @type {Energy}
   * @memberof BaseContract
   */
  annualConsumption: Energy;
  /**
   * The customer\'s estimate on their annual low tariff energy consumption. Can only be set for HT/NT-tariffs.
   * @type {Energy}
   * @memberof BaseContract
   */
  annualConsumptionLowTariff?: Energy;
  /**
   * A list of tariffs that are, were or will be used by this contract ordered by its validity starting with the oldest tariff.
   * @type {Array<TariffHistoryInner>}
   * @memberof BaseContract
   */
  tariffHistory: Array<TariffHistoryInner>;
  /**
   * This value describes if a comfort tariff expires in the next two months. Thus, it will be set to true, unless the customer already requested a tariff switch.
   * @type {boolean}
   * @memberof BaseContract
   */
  isTariffSwitchOutstanding?: boolean;
  /**
   * The monthly gross advance payment amount.
   * @type {Price}
   * @memberof BaseContract
   */
  advancePayment: Price;
  /**
   *
   * @type {OrderReason}
   * @memberof BaseContract
   */
  orderReason?: OrderReason;
  /**
   *
   * @type {ContractLifecycle}
   * @memberof BaseContract
   */
  lifecycle: ContractLifecycle;
  /**
   * This will be set if and only if the customer terminated this contract.
   * @type {Termination}
   * @memberof BaseContract
   */
  termination?: Termination;
  /**
   *
   * @type {PreviousContract}
   * @memberof BaseContract
   */
  previousContract?: PreviousContract;
  /**
   * The name of the contract. Can be used to differentiate between contracts in a human readable form. Not enforced to be unique.
   * @type {string}
   * @memberof BaseContract
   */
  name?: string;
  /**
   *
   * @type {BaseSepaMandate}
   * @memberof BaseContract
   */
  sepaMandate?: BaseSepaMandate;
  /**
   *
   * @type {DeprecatedMeter}
   * @memberof BaseContract
   */
  meter?: DeprecatedMeter;
  /**
   * Meter readings associated with this contract.
   * @type {Array<DeprecatedBaseMeterReading>}
   * @memberof BaseContract
   */
  meterReadings?: Array<DeprecatedBaseMeterReading>;
  /**
   *
   * @type {ContractState}
   * @memberof BaseContract
   */
  state: ContractState;
  /**
   *
   * @type {BankAccount}
   * @memberof BaseContract
   */
  bankAccount?: BankAccount;
  /**
   *
   * @type {Address}
   * @memberof BaseContract
   */
  billingAddress?: Address;
  /**
   *
   * @type {ContractPartner}
   * @memberof BaseContract
   */
  contractPartner?: ContractPartner;
  /**
   *
   * @type {BaseContractTransferredFrom}
   * @memberof BaseContract
   */
  transferredFrom?: BaseContractTransferredFrom;
  /**
   * Terms & Conditions may be accepted at a later time if a contract was added by customer support.
   * @type {boolean}
   * @memberof BaseContract
   */
  hasAcceptedGtc: boolean;
}

/**
 * This will be set if and only if this contract was transferred from another customer.
 * @export
 * @interface BaseContractTransferredFrom
 */
export interface BaseContractTransferredFrom {
  /**
   * The ID of the transfer that was used to transfer this contract.
   * @type {string}
   * @memberof BaseContractTransferredFrom
   */
  transferId: string;
  /**
   * The ID of the original contract this contract is based on.
   * @type {string}
   * @memberof BaseContractTransferredFrom
   */
  contractId: string;
}
/**
 *
 * @export
 * @interface BaseCustomer
 */
export interface BaseCustomer {
  /**
   * The unique ID of the customer.
   * @type {string}
   * @memberof BaseCustomer
   */
  id?: string;
  /**
   *
   * @type {EntityType}
   * @memberof BaseCustomer
   */
  customerType: EntityType;
  /**
   *
   * @type {PersonalData}
   * @memberof BaseCustomer
   */
  personalData: PersonalData;
  /**
   *
   * @type {EmailAddress}
   * @memberof BaseCustomer
   */
  emailAddress: EmailAddress;
  /**
   *
   * @type {PhoneNumber}
   * @memberof BaseCustomer
   */
  phoneNumber: PhoneNumber;
}

/**
 * If a previous contract needs to be terminated before this contract can become effective, it can be specified here.
 * @export
 * @interface BasePreviousContract
 */
export interface BasePreviousContract {
  /**
   * Date at which the previous contract was or will be terminated. If this is not provided on contract creation, Polarstern needs to terminate the contract on behalf of the customer.
   * @type {string}
   * @memberof BasePreviousContract
   */
  terminatedAt?: string;
}
/**
 *
 * @export
 * @interface BaseSepaMandate
 */
export interface BaseSepaMandate {
  /**
   * The date and time at which the customer accepted the SEPA mandate.
   * @type {string}
   * @memberof BaseSepaMandate
   */
  acceptedAt?: string;
  /**
   * The reference number of this SEPA mandate.
   * @type {string}
   * @memberof BaseSepaMandate
   */
  reference?: string;
}
/**
 *
 * @export
 * @interface BaseSupply
 */
export interface BaseSupply {
  /**
   * Date of requested supply of a customer. The value is required for the creation of a tenant power contract.
   * @type {string}
   * @memberof BaseSupply
   */
  requestedBeginAt?: string;
}
/**
 *
 * @export
 * @interface BaseTermination
 */
export interface BaseTermination {
  /**
   * Unique ID of the terminated contract.
   * @type {string}
   * @memberof BaseTermination
   */
  contractId?: string;
  /**
   * Date at which the contract was or will be terminated.
   * @type {string}
   * @memberof BaseTermination
   */
  terminatedAt: string;
  /**
   * If the customer moves to a new address and wants to stay with Polarstern. This is required to decide which document to send to the customer (Kündigungsbestätigung oder Abmeldebestätigung).
   * @type {boolean}
   * @memberof BaseTermination
   */
  movedToNewAddress?: boolean;
  /**
   *
   * @type {BaseTerminationReason}
   * @memberof BaseTermination
   */
  reason: BaseTerminationReason;
  /**
   *
   * @type {string}
   * @memberof BaseTermination
   */
  note?: string;
  /**
   * Date at which the termination was confirmed by the network provider.
   * @type {string}
   * @memberof BaseTermination
   */
  confirmedAt?: string;
  /**
   * Date and time at which the termination was created.
   * @type {string}
   * @memberof BaseTermination
   */
  createdAt: string;
  /**
   * If the termination was created by an admin.
   * @type {boolean}
   * @memberof BaseTermination
   */
  hasBeenTerminatedByAdmin?: boolean;
}
/**
 * @type BaseTerminationReason
 * The reason for the termination.
 * @export
 */
export type BaseTerminationReason = TerminationReason | string;

/**
 * Content of an error response due to a conflict with the current state of the target resource.
 * @export
 * @interface BatchConflictProblem
 */
export interface BatchConflictProblem {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof BatchConflictProblem
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof BatchConflictProblem
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof BatchConflictProblem
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof BatchConflictProblem
   */
  details?: string;
  /**
   * Dictionary containing validation errors.
   * @type {{ [key: string]: string; }}
   * @memberof BatchConflictProblem
   */
  validationErrors?: { [key: string]: string };
}
/**
 * Content of an error response due to failed request validation.
 * @export
 * @interface BatchValidationFailedProblem
 */
export interface BatchValidationFailedProblem {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof BatchValidationFailedProblem
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof BatchValidationFailedProblem
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof BatchValidationFailedProblem
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof BatchValidationFailedProblem
   */
  details?: string;
  /**
   * Dictionary containing validation errors.
   * @type {{ [key: string]: string; }}
   * @memberof BatchValidationFailedProblem
   */
  validationErrors?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface CompanyPersonalData
 */
export interface CompanyPersonalData {
  /**
   *
   * @type {string}
   * @memberof CompanyPersonalData
   */
  companyName: string;
}
/**
 *
 * @export
 * @interface ConfirmedSupply
 */
export interface ConfirmedSupply {
  /**
   * Date of requested supply of a customer. The value is required for the creation of a tenant power contract.
   * @type {string}
   * @memberof ConfirmedSupply
   */
  requestedBeginAt?: string;
  /**
   * Supply address. With exception of MaLo, it is also used as billing address, as long as no separate billing address is provided.
   * @type {Address}
   * @memberof ConfirmedSupply
   */
  address?: Address;
  /**
   * Date at which network operator confirmed to begin to supply this contract.
   * @type {string}
   * @memberof ConfirmedSupply
   */
  confirmedBeginAt?: string;
  /**
   * Date and time at which supply date was confirmed by a network provider.
   * @type {string}
   * @memberof ConfirmedSupply
   */
  confirmedAt?: string;
}
/**
 *
 * @export
 * @interface Contact
 */
export interface Contact {
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  id: string;
  /**
   *
   * @type {EmailAddress}
   * @memberof Contact
   */
  emailAddress: EmailAddress;
  /**
   *
   * @type {EntityType}
   * @memberof Contact
   */
  type?: EntityType;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  companyName?: string;
}

/**
 *
 * @export
 * @interface Contract
 */
export interface Contract {
  /**
   * Unique ID of the contract.
   * @type {string}
   * @memberof Contract
   */
  id: string;
  /**
   *
   * @type {ContractType}
   * @memberof Contract
   */
  type: ContractType;
  /**
   * The customer\'s estimate on their annual energy consumption.
   * @type {Energy}
   * @memberof Contract
   */
  annualConsumption: Energy;
  /**
   * The customer\'s estimate on their annual low tariff energy consumption. Can only be set for HT/NT-tariffs.
   * @type {Energy}
   * @memberof Contract
   */
  annualConsumptionLowTariff?: Energy;
  /**
   * A list of tariffs that are, were or will be used by this contract ordered by its validity starting with the oldest tariff.
   * @type {Array<TariffHistoryInner>}
   * @memberof Contract
   */
  tariffHistory: Array<TariffHistoryInner>;
  /**
   * This value describes if a comfort tariff expires in the next two months. Thus, it will be set to true, unless the customer already requested a tariff switch.
   * @type {boolean}
   * @memberof Contract
   */
  isTariffSwitchOutstanding?: boolean;
  /**
   * The monthly gross advance payment amount.
   * @type {Price}
   * @memberof Contract
   */
  advancePayment: Price;
  /**
   *
   * @type {OrderReason}
   * @memberof Contract
   */
  orderReason?: OrderReason;
  /**
   *
   * @type {ContractLifecycle}
   * @memberof Contract
   */
  lifecycle: ContractLifecycle;
  /**
   * This will be set if and only if the customer terminated this contract.
   * @type {Termination}
   * @memberof Contract
   */
  termination?: Termination;
  /**
   *
   * @type {PreviousContract}
   * @memberof Contract
   */
  previousContract?: PreviousContract;
  /**
   * The name of the contract. Can be used to differentiate between contracts in a human readable form. Not enforced to be unique.
   * @type {string}
   * @memberof Contract
   */
  name?: string;
  /**
   *
   * @type {SepaMandate}
   * @memberof Contract
   */
  sepaMandate?: SepaMandate;
  /**
   *
   * @type {DeprecatedMeter}
   * @memberof Contract
   */
  meter?: DeprecatedMeter;
  /**
   *
   * @type {Array<DeprecatedMeterReading>}
   * @memberof Contract
   */
  meterReadings?: Array<DeprecatedMeterReading>;
  /**
   *
   * @type {ContractState}
   * @memberof Contract
   */
  state: ContractState;
  /**
   *
   * @type {BankAccount}
   * @memberof Contract
   */
  bankAccount?: BankAccount;
  /**
   *
   * @type {Address}
   * @memberof Contract
   */
  billingAddress?: Address;
  /**
   *
   * @type {ContractPartner}
   * @memberof Contract
   */
  contractPartner?: ContractPartner;
  /**
   *
   * @type {BaseContractTransferredFrom}
   * @memberof Contract
   */
  transferredFrom?: BaseContractTransferredFrom;
  /**
   * Terms & Conditions may be accepted at a later time if a contract was added by customer support.
   * @type {boolean}
   * @memberof Contract
   */
  hasAcceptedGtc: boolean;
  /**
   *
   * @type {ContractAllOfSupply}
   * @memberof Contract
   */
  supply: ContractAllOfSupply;
}

/**
 *
 * @export
 * @interface ContractAllOfSupply
 */
export interface ContractAllOfSupply {
  /**
   * Date of requested supply of a customer. The value is required for the creation of a tenant power contract.
   * @type {string}
   * @memberof ContractAllOfSupply
   */
  requestedBeginAt?: string;
  /**
   * Supply address. With exception of MaLo, it is also used as billing address, as long as no separate billing address is provided.
   * @type {Address}
   * @memberof ContractAllOfSupply
   */
  address: Address;
  /**
   *
   * @type {any}
   * @memberof ContractAllOfSupply
   */
  confirmedBeginAt?: any;
  /**
   *
   * @type {any}
   * @memberof ContractAllOfSupply
   */
  confirmedAt?: any;
}
/**
 *
 * @export
 * @interface ContractAsAdmin
 */
export interface ContractAsAdmin {
  /**
   * Unique ID of the contract.
   * @type {string}
   * @memberof ContractAsAdmin
   */
  id: string;
  /**
   *
   * @type {ContractType}
   * @memberof ContractAsAdmin
   */
  type: ContractType;
  /**
   * The customer\'s estimate on their annual energy consumption.
   * @type {Energy}
   * @memberof ContractAsAdmin
   */
  annualConsumption: Energy;
  /**
   * The customer\'s estimate on their annual low tariff energy consumption. Can only be set for HT/NT-tariffs.
   * @type {Energy}
   * @memberof ContractAsAdmin
   */
  annualConsumptionLowTariff?: Energy;
  /**
   * A list of tariffs that are, were or will be used by this contract ordered by its validity starting with the oldest tariff.
   * @type {Array<TariffHistoryInner>}
   * @memberof ContractAsAdmin
   */
  tariffHistory: Array<TariffHistoryInner>;
  /**
   * This value describes if a comfort tariff expires in the next two months. Thus, it will be set to true, unless the customer already requested a tariff switch.
   * @type {boolean}
   * @memberof ContractAsAdmin
   */
  isTariffSwitchOutstanding?: boolean;
  /**
   * The monthly gross advance payment amount.
   * @type {Price}
   * @memberof ContractAsAdmin
   */
  advancePayment: Price;
  /**
   *
   * @type {OrderReason}
   * @memberof ContractAsAdmin
   */
  orderReason?: OrderReason;
  /**
   *
   * @type {ContractLifecycle}
   * @memberof ContractAsAdmin
   */
  lifecycle: ContractLifecycle;
  /**
   * This will be set if and only if the customer terminated this contract.
   * @type {Termination}
   * @memberof ContractAsAdmin
   */
  termination?: Termination;
  /**
   *
   * @type {PreviousContract}
   * @memberof ContractAsAdmin
   */
  previousContract?: PreviousContract;
  /**
   * The name of the contract. Can be used to differentiate between contracts in a human readable form. Not enforced to be unique.
   * @type {string}
   * @memberof ContractAsAdmin
   */
  name?: string;
  /**
   *
   * @type {SepaMandate}
   * @memberof ContractAsAdmin
   */
  sepaMandate?: SepaMandate;
  /**
   *
   * @type {DeprecatedMeter}
   * @memberof ContractAsAdmin
   */
  meter?: DeprecatedMeter;
  /**
   *
   * @type {Array<DeprecatedMeterReading>}
   * @memberof ContractAsAdmin
   */
  meterReadings?: Array<DeprecatedMeterReading>;
  /**
   *
   * @type {ContractState}
   * @memberof ContractAsAdmin
   */
  state: ContractState;
  /**
   *
   * @type {BankAccount}
   * @memberof ContractAsAdmin
   */
  bankAccount?: BankAccount;
  /**
   *
   * @type {Address}
   * @memberof ContractAsAdmin
   */
  billingAddress?: Address;
  /**
   *
   * @type {ContractPartner}
   * @memberof ContractAsAdmin
   */
  contractPartner?: ContractPartner;
  /**
   *
   * @type {BaseContractTransferredFrom}
   * @memberof ContractAsAdmin
   */
  transferredFrom?: BaseContractTransferredFrom;
  /**
   * Terms & Conditions may be accepted at a later time if a contract was added by customer support.
   * @type {boolean}
   * @memberof ContractAsAdmin
   */
  hasAcceptedGtc: boolean;
  /**
   *
   * @type {ContractAllOfSupply}
   * @memberof ContractAsAdmin
   */
  supply: ContractAllOfSupply;
  /**
   * Whether this contract is self payed by the customer without a sepa mandate.
   * @type {boolean}
   * @memberof ContractAsAdmin
   */
  selfPayer: boolean;
  /**
   * Whether this contract has been created by an admin.
   * @type {boolean}
   * @memberof ContractAsAdmin
   */
  hasBeenCreatedByAdmin: boolean;
  /**
   *
   * @type {ForecastBasis}
   * @memberof ContractAsAdmin
   */
  forecastBasis: ForecastBasis;
  /**
   * A note exclusively for customer service.
   * @type {string}
   * @memberof ContractAsAdmin
   */
  note?: string;
  /**
   * The date until the contract is buffered.
   * @type {string}
   * @memberof ContractAsAdmin
   */
  bufferedUntil?: string;
}

/**
 *
 * @export
 * @interface ContractError
 */
export interface ContractError {
  /**
   * The unique id of this error.
   * @type {string}
   * @memberof ContractError
   */
  id: string;
  /**
   * Id of the contract this error is for.
   * @type {string}
   * @memberof ContractError
   */
  contractId: string;
  /**
   * The subject of this error.
   * @type {string}
   * @memberof ContractError
   */
  subject: string;
  /**
   * The reason for this error.
   * @type {string}
   * @memberof ContractError
   */
  reason: string;
}
/**
 *
 * @export
 * @interface ContractHistoryEntry
 */
export interface ContractHistoryEntry {
  /**
   * The id of the contract.
   * @type {string}
   * @memberof ContractHistoryEntry
   */
  id: string;
  /**
   *
   * @type {ContractState}
   * @memberof ContractHistoryEntry
   */
  state: ContractState;
  /**
   * Date at which network operator confirmed to begin to supply this contract.
   * @type {string}
   * @memberof ContractHistoryEntry
   */
  confirmedBeginAt: string;
  /**
   * Date at which the contract was terminated.
   * @type {string}
   * @memberof ContractHistoryEntry
   */
  terminatedAt?: string;
}

/**
 *
 * @export
 * @interface ContractLifecycle
 */
export interface ContractLifecycle {
  /**
   * The date and time at which the contract was created initially.
   * @type {string}
   * @memberof ContractLifecycle
   */
  createdAt: string;
  /**
   * The date and time at which the contract was moved (i.e. replaced by another contract). This means that a new address is supplied starting at this time, while this contract can still be active for the previous address.
   * @type {string}
   * @memberof ContractLifecycle
   */
  customerMovedAt?: string;
  /**
   * The date and time at which a transfer for this contract was initiated.
   * @type {string}
   * @memberof ContractLifecycle
   */
  transferInitiatedAt?: string;
  /**
   * The date and time at which the transfer for this contract was accepted by the receiving party.
   * @type {string}
   * @memberof ContractLifecycle
   */
  transferAcceptedAt?: string;
}
/**
 *
 * @export
 * @interface ContractPartner
 */
export interface ContractPartner {
  /**
   *
   * @type {EmailAddress}
   * @memberof ContractPartner
   */
  emailAddress?: EmailAddress;
  /**
   *
   * @type {string}
   * @memberof ContractPartner
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ContractPartner
   */
  lastName: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ContractState = {
  Incomplete: "Incomplete",
  Buffered: "Buffered",
  Pending: "Pending",
  Active: "Active",
  Rejected: "Rejected",
  Terminated: "Terminated",
} as const;

export type ContractState = (typeof ContractState)[keyof typeof ContractState];

/**
 *
 * @export
 * @interface ContractTodo
 */
export interface ContractTodo {
  /**
   *
   * @type {string}
   * @memberof ContractTodo
   */
  resource: ContractTodoResourceEnum;
  /**
   * The type of the information that is missing.
   * @type {string}
   * @memberof ContractTodo
   */
  type: ContractTodoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ContractTodo
   */
  contractId: string;
}

export const ContractTodoResourceEnum = {
  Contract: "contract",
} as const;

export type ContractTodoResourceEnum =
  (typeof ContractTodoResourceEnum)[keyof typeof ContractTodoResourceEnum];
export const ContractTodoTypeEnum = {
  TermsAndConditions: "termsAndConditions",
  OrderReason: "orderReason",
  Meter: "meter",
  BankAccount: "bankAccount",
} as const;

export type ContractTodoTypeEnum =
  (typeof ContractTodoTypeEnum)[keyof typeof ContractTodoTypeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const ContractType = {
  Gas: "gas",
  Power: "power",
  EMobility: "eMobility",
} as const;

export type ContractType = (typeof ContractType)[keyof typeof ContractType];

/**
 *
 * @export
 * @interface ContractUpdate
 */
export interface ContractUpdate {
  /**
   * The name of the contract. Can be used to differentiate between contracts in a human readable form. Not enforced to be unique.
   * @type {string}
   * @memberof ContractUpdate
   */
  name?: string;
  /**
   * Terms & Conditions may be accepted at a later time if a contract was added by customer support.
   * @type {boolean}
   * @memberof ContractUpdate
   */
  hasAcceptedGtc?: boolean;
  /**
   *
   * @type {DeprecatedMeter}
   * @memberof ContractUpdate
   */
  meter?: DeprecatedMeter;
  /**
   *
   * @type {PreviousContract}
   * @memberof ContractUpdate
   */
  previousContract?: PreviousContract;
  /**
   *
   * @type {OrderReason}
   * @memberof ContractUpdate
   */
  orderReason?: OrderReason;
  /**
   * The monthly gross advance payment amount.
   * @type {Price}
   * @memberof ContractUpdate
   */
  advancePayment?: Price;
  /**
   *
   * @type {BankAccount}
   * @memberof ContractUpdate
   */
  bankAccount?: BankAccount;
  /**
   *
   * @type {ContractPartner}
   * @memberof ContractUpdate
   */
  contractPartner?: ContractPartner;
  /**
   *
   * @type {Address}
   * @memberof ContractUpdate
   */
  billingAddress?: Address;
}

/**
 *
 * @export
 * @interface ContractUpdateAsAdmin
 */
export interface ContractUpdateAsAdmin {
  /**
   * The name of the contract. Can be used to differentiate between contracts in a human readable form. Not enforced to be unique.
   * @type {string}
   * @memberof ContractUpdateAsAdmin
   */
  name?: string;
  /**
   * Terms & Conditions may be accepted at a later time if a contract was added by customer support.
   * @type {boolean}
   * @memberof ContractUpdateAsAdmin
   */
  hasAcceptedGtc?: boolean;
  /**
   *
   * @type {DeprecatedMeter}
   * @memberof ContractUpdateAsAdmin
   */
  meter?: DeprecatedMeter;
  /**
   *
   * @type {PreviousContract}
   * @memberof ContractUpdateAsAdmin
   */
  previousContract?: PreviousContract;
  /**
   *
   * @type {OrderReason}
   * @memberof ContractUpdateAsAdmin
   */
  orderReason?: OrderReason;
  /**
   * The monthly gross advance payment amount.
   * @type {Price}
   * @memberof ContractUpdateAsAdmin
   */
  advancePayment?: Price;
  /**
   *
   * @type {BankAccount}
   * @memberof ContractUpdateAsAdmin
   */
  bankAccount?: BankAccount;
  /**
   *
   * @type {ContractPartner}
   * @memberof ContractUpdateAsAdmin
   */
  contractPartner?: ContractPartner;
  /**
   *
   * @type {Address}
   * @memberof ContractUpdateAsAdmin
   */
  billingAddress?: Address;
  /**
   *
   * @type {ForecastBasis}
   * @memberof ContractUpdateAsAdmin
   */
  forecastBasis?: ForecastBasis;
  /**
   * A note exclusively for customer service.
   * @type {string}
   * @memberof ContractUpdateAsAdmin
   */
  note?: string;
  /**
   *
   * @type {ReadonlyConfirmedSupply}
   * @memberof ContractUpdateAsAdmin
   */
  supply?: ReadonlyConfirmedSupply;
  /**
   * The customer\'s annual energy consumption.
   * @type {Energy}
   * @memberof ContractUpdateAsAdmin
   */
  annualConsumption?: Energy;
  /**
   * The customer\'s annual low tariff energy consumption. Can only be set for HT/NT-tariffs.
   * @type {Energy}
   * @memberof ContractUpdateAsAdmin
   */
  annualConsumptionLowTariff?: Energy;
  /**
   * A list of tariffs that are, were or will be used by this contract ordered by its validity starting with the oldest tariff.
   * @type {Array<TariffHistoryInner>}
   * @memberof ContractUpdateAsAdmin
   */
  tariffHistory?: Array<TariffHistoryInner>;
}

/**
 *
 * @export
 * @interface CreateCustomer400Response
 */
export interface CreateCustomer400Response {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof CreateCustomer400Response
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof CreateCustomer400Response
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof CreateCustomer400Response
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof CreateCustomer400Response
   */
  details?: string;
  /**
   * Dictionary containing validation errors.
   * @type {{ [key: string]: string; }}
   * @memberof CreateCustomer400Response
   */
  validationErrors?: { [key: string]: string };
  /**
   *
   * @type {InvalidZipCodeValidationProblemAllOfInvalidPayload}
   * @memberof CreateCustomer400Response
   */
  invalidPayload?: InvalidZipCodeValidationProblemAllOfInvalidPayload;
}
/**
 *
 * @export
 * @interface Customer
 */
export interface Customer {
  /**
   *
   * @type {any}
   * @memberof Customer
   */
  id: any;
  /**
   *
   * @type {EntityType}
   * @memberof Customer
   */
  customerType: EntityType;
  /**
   *
   * @type {PersonalData}
   * @memberof Customer
   */
  personalData: PersonalData;
  /**
   *
   * @type {EmailAddress}
   * @memberof Customer
   */
  emailAddress: EmailAddress;
  /**
   *
   * @type {PhoneNumber}
   * @memberof Customer
   */
  phoneNumber: PhoneNumber;
  /**
   *
   * @type {Array<Todo>}
   * @memberof Customer
   */
  todos: Array<Todo>;
  /**
   * The promotion code redeemed by this customer.
   * @type {string}
   * @memberof Customer
   */
  promotionCode?: string;
  /**
   * The universal code to share for recommendations by this customer.
   * @type {string}
   * @memberof Customer
   */
  universalCode: string;
  /**
   * The password to set for the customer\'s user account.
   * @type {string}
   * @memberof Customer
   */
  password: string;
}

/**
 *
 * @export
 * @interface CustomerAsAdmin
 */
export interface CustomerAsAdmin {
  /**
   *
   * @type {any}
   * @memberof CustomerAsAdmin
   */
  id?: any;
  /**
   *
   * @type {EntityType}
   * @memberof CustomerAsAdmin
   */
  customerType: EntityType;
  /**
   *
   * @type {PersonalData}
   * @memberof CustomerAsAdmin
   */
  personalData: PersonalData;
  /**
   *
   * @type {EmailAddress}
   * @memberof CustomerAsAdmin
   */
  emailAddress: EmailAddress;
  /**
   *
   * @type {PhoneNumber}
   * @memberof CustomerAsAdmin
   */
  phoneNumber: PhoneNumber;
  /**
   *
   * @type {Array<Todo>}
   * @memberof CustomerAsAdmin
   */
  todos: Array<Todo>;
  /**
   * The promotion code redeemed by this customer.
   * @type {string}
   * @memberof CustomerAsAdmin
   */
  promotionCode?: string;
  /**
   * The universal code to share for recommendations by this customer.
   * @type {string}
   * @memberof CustomerAsAdmin
   */
  universalCode: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CustomerAsAdmin
   */
  labelIds?: Array<string>;
}

/**
 *
 * @export
 * @interface CustomerTodo
 */
export interface CustomerTodo {
  /**
   *
   * @type {string}
   * @memberof CustomerTodo
   */
  resource: CustomerTodoResourceEnum;
  /**
   * The type of the information that is missing.
   * @type {string}
   * @memberof CustomerTodo
   */
  type: CustomerTodoTypeEnum;
}

export const CustomerTodoResourceEnum = {
  Customer: "customer",
} as const;

export type CustomerTodoResourceEnum =
  (typeof CustomerTodoResourceEnum)[keyof typeof CustomerTodoResourceEnum];
export const CustomerTodoTypeEnum = {
  EmailAddressVerification: "emailAddressVerification",
} as const;

export type CustomerTodoTypeEnum =
  (typeof CustomerTodoTypeEnum)[keyof typeof CustomerTodoTypeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const CustomerType = {
  Private: "private",
  Company: "company",
} as const;

export type CustomerType = (typeof CustomerType)[keyof typeof CustomerType];

/**
 *
 * @export
 * @interface CustomerWithContracts
 */
export interface CustomerWithContracts {
  /**
   *
   * @type {any}
   * @memberof CustomerWithContracts
   */
  id: any;
  /**
   *
   * @type {EntityType}
   * @memberof CustomerWithContracts
   */
  customerType: EntityType;
  /**
   *
   * @type {PersonalData}
   * @memberof CustomerWithContracts
   */
  personalData: PersonalData;
  /**
   *
   * @type {EmailAddress}
   * @memberof CustomerWithContracts
   */
  emailAddress: EmailAddress;
  /**
   *
   * @type {PhoneNumber}
   * @memberof CustomerWithContracts
   */
  phoneNumber: PhoneNumber;
  /**
   *
   * @type {Array<Todo>}
   * @memberof CustomerWithContracts
   */
  todos: Array<Todo>;
  /**
   * The promotion code redeemed by this customer.
   * @type {string}
   * @memberof CustomerWithContracts
   */
  promotionCode?: string;
  /**
   * The universal code to share for recommendations by this customer.
   * @type {string}
   * @memberof CustomerWithContracts
   */
  universalCode: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CustomerWithContracts
   */
  labelIds?: Array<string>;
  /**
   *
   * @type {Array<ContractAsAdmin>}
   * @memberof CustomerWithContracts
   */
  contracts: Array<ContractAsAdmin>;
}

/**
 *
 * @export
 * @interface CustomerWithContractsWithOptionalId
 */
export interface CustomerWithContractsWithOptionalId {
  /**
   *
   * @type {any}
   * @memberof CustomerWithContractsWithOptionalId
   */
  id?: any;
  /**
   *
   * @type {EntityType}
   * @memberof CustomerWithContractsWithOptionalId
   */
  customerType: EntityType;
  /**
   *
   * @type {PersonalData}
   * @memberof CustomerWithContractsWithOptionalId
   */
  personalData: PersonalData;
  /**
   *
   * @type {EmailAddress}
   * @memberof CustomerWithContractsWithOptionalId
   */
  emailAddress: EmailAddress;
  /**
   *
   * @type {PhoneNumber}
   * @memberof CustomerWithContractsWithOptionalId
   */
  phoneNumber: PhoneNumber;
  /**
   *
   * @type {Array<Todo>}
   * @memberof CustomerWithContractsWithOptionalId
   */
  todos: Array<Todo>;
  /**
   * The promotion code redeemed by this customer.
   * @type {string}
   * @memberof CustomerWithContractsWithOptionalId
   */
  promotionCode?: string;
  /**
   * The universal code to share for recommendations by this customer.
   * @type {string}
   * @memberof CustomerWithContractsWithOptionalId
   */
  universalCode: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CustomerWithContractsWithOptionalId
   */
  labelIds?: Array<string>;
  /**
   *
   * @type {Array<ContractAsAdmin>}
   * @memberof CustomerWithContractsWithOptionalId
   */
  contracts: Array<ContractAsAdmin>;
}

/**
 *
 * @export
 * @interface CustomerWithLabels
 */
export interface CustomerWithLabels {
  /**
   *
   * @type {any}
   * @memberof CustomerWithLabels
   */
  id: any;
  /**
   *
   * @type {EntityType}
   * @memberof CustomerWithLabels
   */
  customerType: EntityType;
  /**
   *
   * @type {PersonalData}
   * @memberof CustomerWithLabels
   */
  personalData: PersonalData;
  /**
   *
   * @type {EmailAddress}
   * @memberof CustomerWithLabels
   */
  emailAddress: EmailAddress;
  /**
   *
   * @type {PhoneNumber}
   * @memberof CustomerWithLabels
   */
  phoneNumber: PhoneNumber;
  /**
   *
   * @type {Array<Todo>}
   * @memberof CustomerWithLabels
   */
  todos: Array<Todo>;
  /**
   * The promotion code redeemed by this customer.
   * @type {string}
   * @memberof CustomerWithLabels
   */
  promotionCode?: string;
  /**
   * The universal code to share for recommendations by this customer.
   * @type {string}
   * @memberof CustomerWithLabels
   */
  universalCode: string;
  /**
   * The password to set for the customer\'s user account.
   * @type {string}
   * @memberof CustomerWithLabels
   */
  password: string;
  /**
   *
   * @type {Array<Label>}
   * @memberof CustomerWithLabels
   */
  labels?: Array<Label>;
}

/**
 *
 * @export
 * @interface CustomerWithoutPassword
 */
export interface CustomerWithoutPassword {
  /**
   * The unique ID of the customer.
   * @type {string}
   * @memberof CustomerWithoutPassword
   */
  id?: string;
  /**
   *
   * @type {EntityType}
   * @memberof CustomerWithoutPassword
   */
  customerType: EntityType;
  /**
   *
   * @type {PersonalData}
   * @memberof CustomerWithoutPassword
   */
  personalData: PersonalData;
  /**
   *
   * @type {EmailAddress}
   * @memberof CustomerWithoutPassword
   */
  emailAddress: EmailAddress;
  /**
   *
   * @type {PhoneNumber}
   * @memberof CustomerWithoutPassword
   */
  phoneNumber: PhoneNumber;
  /**
   *
   * @type {Array<Todo>}
   * @memberof CustomerWithoutPassword
   */
  todos: Array<Todo>;
  /**
   * The promotion code redeemed by this customer.
   * @type {string}
   * @memberof CustomerWithoutPassword
   */
  promotionCode?: string;
  /**
   * The universal code to share for recommendations by this customer.
   * @type {string}
   * @memberof CustomerWithoutPassword
   */
  universalCode: string;
}

/**
 *
 * @export
 * @interface DefaultPassword
 */
export interface DefaultPassword {
  /**
   *
   * @type {string}
   * @memberof DefaultPassword
   */
  password: string;
}
/**
 *
 * @export
 * @interface DeprecatedBaseMeterReading
 */
export interface DeprecatedBaseMeterReading {
  /**
   *
   * @type {number}
   * @memberof DeprecatedBaseMeterReading
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof DeprecatedBaseMeterReading
   */
  unit: DeprecatedBaseMeterReadingUnitEnum;
  /**
   * The ID of this meter reading.
   * @type {string}
   * @memberof DeprecatedBaseMeterReading
   */
  id: string;
  /**
   * The serial number of the meter. A serial number is NOT unique but duplicates are very rare.
   * @type {string}
   * @memberof DeprecatedBaseMeterReading
   */
  meterSerialNumber?: string;
  /**
   * The date of the reading.
   * @type {string}
   * @memberof DeprecatedBaseMeterReading
   */
  readAt: string;
  /**
   * If the reading is for low tariff (true) or high tariff (false).
   * @type {boolean}
   * @memberof DeprecatedBaseMeterReading
   */
  isLowTariff?: boolean;
  /**
   *
   * @type {MeterReadingType}
   * @memberof DeprecatedBaseMeterReading
   */
  type: MeterReadingType;
}

export const DeprecatedBaseMeterReadingUnitEnum = {
  KWh: "kWh",
  M3: "m3",
} as const;

export type DeprecatedBaseMeterReadingUnitEnum =
  (typeof DeprecatedBaseMeterReadingUnitEnum)[keyof typeof DeprecatedBaseMeterReadingUnitEnum];

/**
 *
 * @export
 * @interface DeprecatedMeter
 */
export interface DeprecatedMeter {
  /**
   * The serial number of the meter. A serial number is NOT unique but duplicates are very rare.
   * @type {string}
   * @memberof DeprecatedMeter
   */
  serialNumber: string;
  /**
   * The date at which the meter was or will be installed.
   * @type {string}
   * @memberof DeprecatedMeter
   */
  installedAt?: string;
  /**
   * A remote controllable meter can be remotely disconnected from the power grid by the network operator.
   * @type {boolean}
   * @memberof DeprecatedMeter
   */
  isRemoteControllable?: boolean;
}
/**
 *
 * @export
 * @interface DeprecatedMeterReading
 */
export interface DeprecatedMeterReading {
  /**
   *
   * @type {number}
   * @memberof DeprecatedMeterReading
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof DeprecatedMeterReading
   */
  unit: DeprecatedMeterReadingUnitEnum;
  /**
   * The ID of this meter reading.
   * @type {string}
   * @memberof DeprecatedMeterReading
   */
  id: string;
  /**
   *
   * @type {any}
   * @memberof DeprecatedMeterReading
   */
  meterSerialNumber?: any;
  /**
   * The date of the reading.
   * @type {string}
   * @memberof DeprecatedMeterReading
   */
  readAt: string;
  /**
   * If the reading is for low tariff (true) or high tariff (false).
   * @type {boolean}
   * @memberof DeprecatedMeterReading
   */
  isLowTariff?: boolean;
  /**
   *
   * @type {any}
   * @memberof DeprecatedMeterReading
   */
  type: any;
}

export const DeprecatedMeterReadingUnitEnum = {
  KWh: "kWh",
  M3: "m3",
} as const;

export type DeprecatedMeterReadingUnitEnum =
  (typeof DeprecatedMeterReadingUnitEnum)[keyof typeof DeprecatedMeterReadingUnitEnum];

/**
 *
 * @export
 * @interface DetailedTariffFields
 */
export interface DetailedTariffFields {
  /**
   * The tariff is valid from this date on, including this date.
   * @type {string}
   * @memberof DetailedTariffFields
   */
  validFrom: string;
  /**
   * The tariff is valid until this date, including this date.
   * @type {string}
   * @memberof DetailedTariffFields
   */
  validTo?: string;
  /**
   * This tax directly refers to the [Stromsteuergesetz](https://de.wikipedia.org/wiki/Stromsteuergesetz_(Deutschland))
   * @type {Price}
   * @memberof DetailedTariffFields
   */
  taxPerUnit?: Price;
  /**
   * Whether this is a public tariff.
   * @type {boolean}
   * @memberof DetailedTariffFields
   */
  isPublic?: boolean;
  /**
   *
   * @type {ForecastBasis}
   * @memberof DetailedTariffFields
   */
  forecastBasis: ForecastBasis;
}

/**
 *
 * @export
 * @interface Document
 */
export interface Document {
  /**
   * The unique id or name of this document.
   * @type {string}
   * @memberof Document
   */
  id: string;
  /**
   * The extension specifying the type of this file.
   * @type {string}
   * @memberof Document
   */
  fileExtension: DocumentFileExtensionEnum;
  /**
   * The ID of the contract this document is associated with.
   * @type {string}
   * @memberof Document
   */
  contractId: string;
  /**
   * The document type, for example \"Rechnung\", \"Ablesung\" or \"Abschlagsanpassung\".
   * @type {string}
   * @memberof Document
   */
  documentType: string;
  /**
   * The creation date and time of this document.
   * @type {string}
   * @memberof Document
   */
  createdAt: string;
  /**
   * The base64 encoded content of this document.
   * @type {string}
   * @memberof Document
   */
  content: string;
}

export const DocumentFileExtensionEnum = {
  Pdf: "pdf",
} as const;

export type DocumentFileExtensionEnum =
  (typeof DocumentFileExtensionEnum)[keyof typeof DocumentFileExtensionEnum];

/**
 *
 * @export
 * @interface DocumentWithoutContent
 */
export interface DocumentWithoutContent {
  /**
   * The unique id or name of this document.
   * @type {string}
   * @memberof DocumentWithoutContent
   */
  id: string;
  /**
   * The extension specifying the type of this file.
   * @type {string}
   * @memberof DocumentWithoutContent
   */
  fileExtension: DocumentWithoutContentFileExtensionEnum;
  /**
   * The ID of the contract this document is associated with.
   * @type {string}
   * @memberof DocumentWithoutContent
   */
  contractId: string;
  /**
   * The document type, for example \"Rechnung\", \"Ablesung\" or \"Abschlagsanpassung\".
   * @type {string}
   * @memberof DocumentWithoutContent
   */
  documentType: string;
  /**
   * The creation date and time of this document.
   * @type {string}
   * @memberof DocumentWithoutContent
   */
  createdAt: string;
}

export const DocumentWithoutContentFileExtensionEnum = {
  Pdf: "pdf",
} as const;

export type DocumentWithoutContentFileExtensionEnum =
  (typeof DocumentWithoutContentFileExtensionEnum)[keyof typeof DocumentWithoutContentFileExtensionEnum];

/**
 *
 * @export
 * @interface EmailAddress
 */
export interface EmailAddress {
  /**
   *
   * @type {string}
   * @memberof EmailAddress
   */
  value: string;
  /**
   *
   * @type {Array<string>}
   * @memberof EmailAddress
   */
  subscriptions?: Array<EmailAddressSubscriptionsEnum>;
  /**
   * Date and time at which the email address was verified. Will be set by the server if and only if the email address has been verified.
   * @type {string}
   * @memberof EmailAddress
   */
  verifiedAt?: string;
}

export const EmailAddressSubscriptionsEnum = {
  GeneralNewsletter: "generalNewsletter",
  InterestedNewsletter: "interestedNewsletter",
  TenantPowerNewsletter: "tenantPowerNewsletter",
  HeatPumpNewsletter: "heatPumpNewsletter",
  EmobilityNewsletter: "emobilityNewsletter",
  CommercialSolarNewsletter: "commercialSolarNewsletter",
  JobNewsletter: "jobNewsletter",
} as const;

export type EmailAddressSubscriptionsEnum =
  (typeof EmailAddressSubscriptionsEnum)[keyof typeof EmailAddressSubscriptionsEnum];

/**
 *
 * @export
 * @interface EmailVerificationToken
 */
export interface EmailVerificationToken {
  /**
   * The token which can be used to verify the email address.
   * @type {string}
   * @memberof EmailVerificationToken
   */
  token: string;
  /**
   * The email address to verify.
   * @type {string}
   * @memberof EmailVerificationToken
   */
  emailAddressToVerify: string;
  /**
   * The creation date of this token.
   * @type {string}
   * @memberof EmailVerificationToken
   */
  createdAt: string;
  /**
   * The ID of the customer the email address to verify belongs to.
   * @type {string}
   * @memberof EmailVerificationToken
   */
  customerId: string;
}
/**
 *
 * @export
 * @interface Energy
 */
export interface Energy {
  /**
   *
   * @type {number}
   * @memberof Energy
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof Energy
   */
  unit: EnergyUnitEnum;
}

export const EnergyUnitEnum = {
  KWh: "kWh",
  M3: "m3",
} as const;

export type EnergyUnitEnum = (typeof EnergyUnitEnum)[keyof typeof EnergyUnitEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const EntityType = {
  Private: "private",
  Company: "company",
  Ngo: "ngo",
} as const;

export type EntityType = (typeof EntityType)[keyof typeof EntityType];

/**
 *
 * @export
 * @interface FixPrice
 */
export interface FixPrice {
  /**
   * The net base price to be paid per year within this range.
   * @type {Price}
   * @memberof FixPrice
   */
  basePrice: Price;
  /**
   * The net price to pay per unit on top of the base price.
   * @type {Price}
   * @memberof FixPrice
   */
  pricePerUnit: Price;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ForecastBasis = {
  Slp: "slp",
  Rlm: "rlm",
} as const;

export type ForecastBasis = (typeof ForecastBasis)[keyof typeof ForecastBasis];

/**
 * Content of an error response due to failed request validation.
 * @export
 * @interface GeneralValidationFailedProblem
 */
export interface GeneralValidationFailedProblem {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof GeneralValidationFailedProblem
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof GeneralValidationFailedProblem
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof GeneralValidationFailedProblem
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof GeneralValidationFailedProblem
   */
  details?: string;
  /**
   * Dictionary containing validation errors.
   * @type {{ [key: string]: string; }}
   * @memberof GeneralValidationFailedProblem
   */
  validationErrors?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface GetContractErrorsById200Response
 */
export interface GetContractErrorsById200Response {
  /**
   *
   * @type {Array<GetContractErrorsById200ResponseItemsInner>}
   * @memberof GetContractErrorsById200Response
   */
  items: Array<GetContractErrorsById200ResponseItemsInner>;
}
/**
 *
 * @export
 * @interface GetContractErrorsById200ResponseItemsInner
 */
export interface GetContractErrorsById200ResponseItemsInner {
  /**
   * The unique id of this error.
   * @type {string}
   * @memberof GetContractErrorsById200ResponseItemsInner
   */
  id: string;
  /**
   * Id of the contract this error is for.
   * @type {string}
   * @memberof GetContractErrorsById200ResponseItemsInner
   */
  contractId: string;
  /**
   * The subject of this error.
   * @type {string}
   * @memberof GetContractErrorsById200ResponseItemsInner
   */
  subject: string;
  /**
   * The reason for this error.
   * @type {string}
   * @memberof GetContractErrorsById200ResponseItemsInner
   */
  reason: string;
  /**
   * The creation date of this error.
   * @type {string}
   * @memberof GetContractErrorsById200ResponseItemsInner
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface GetCustomerContracts200Response
 */
export interface GetCustomerContracts200Response {
  /**
   *
   * @type {Array<ContractAsAdmin>}
   * @memberof GetCustomerContracts200Response
   */
  items: Array<ContractAsAdmin>;
}
/**
 *
 * @export
 * @interface GetCustomerRecommendations200Response
 */
export interface GetCustomerRecommendations200Response {
  /**
   *
   * @type {Array<Recommendation>}
   * @memberof GetCustomerRecommendations200Response
   */
  items: Array<Recommendation>;
}
/**
 *
 * @export
 * @interface GetDocuments200Response
 */
export interface GetDocuments200Response {
  /**
   *
   * @type {Array<DocumentWithoutContent>}
   * @memberof GetDocuments200Response
   */
  items: Array<DocumentWithoutContent>;
}
/**
 *
 * @export
 * @interface GetLabels200Response
 */
export interface GetLabels200Response {
  /**
   *
   * @type {Array<Label>}
   * @memberof GetLabels200Response
   */
  items: Array<Label>;
}
/**
 *
 * @export
 * @interface GetMeasuringPoints200Response
 */
export interface GetMeasuringPoints200Response {
  /**
   *
   * @type {Array<MeasuringPointAsAdminListInner>}
   * @memberof GetMeasuringPoints200Response
   */
  items: Array<MeasuringPointAsAdminListInner>;
}
/**
 *
 * @export
 * @interface GetTariffs200Response
 */
export interface GetTariffs200Response {
  /**
   *
   * @type {Array<Tariff>}
   * @memberof GetTariffs200Response
   */
  items: Array<Tariff>;
}
/**
 *
 * @export
 * @interface InitialMeterInstallation
 */
export interface InitialMeterInstallation {
  /**
   * The initial meter reading is the first meter reading that has been taken by the meter for this measuring point. In case no initial meter reading is provided, the meter will be installed with an initial meter reading of **0 kWh.**
   * @type {MeterReading}
   * @memberof InitialMeterInstallation
   */
  initialMeterReading?: MeterReading;
  /**
   * The id of the meter.
   * @type {string}
   * @memberof InitialMeterInstallation
   */
  id: string;
  /**
   * The serial number of the meter. A serial number is NOT unique but duplicates are very rare.
   * @type {string}
   * @memberof InitialMeterInstallation
   */
  serialNumber: string;
  /**
   * The date at which the meter was or will be installed. The date is **required for tenant power measuring points**.
   * @type {string}
   * @memberof InitialMeterInstallation
   */
  installedAt?: string;
  /**
   * A remote controllable meter can be remotely disconnected from the power grid by the network operator.
   * @type {boolean}
   * @memberof InitialMeterInstallation
   */
  isRemoteControllable?: boolean;
  /**
   * The readings that have been taken by the meter.
   * @type {Array<MeterReading>}
   * @memberof InitialMeterInstallation
   */
  readings: Array<MeterReading>;
}
/**
 * Content of a failed tariff validation error response due invalidRequestedBeginAt for the tariff.
 * @export
 * @interface InvalidRequestedBeginValidationProblem
 */
export interface InvalidRequestedBeginValidationProblem {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  details?: string;
  /**
   * Dictionary containing validation errors.
   * @type {{ [key: string]: string; }}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  validationErrors?: { [key: string]: string };
  /**
   *
   * @type {InvalidRequestedBeginValidationProblemAllOfInvalidPayload}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  invalidPayload?: InvalidRequestedBeginValidationProblemAllOfInvalidPayload;
}
/**
 * Additional information about the tariff validation error.
 * @export
 * @interface InvalidRequestedBeginValidationProblemAllOfInvalidPayload
 */
export interface InvalidRequestedBeginValidationProblemAllOfInvalidPayload {
  /**
   *
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblemAllOfInvalidPayload
   */
  requestedBeginAt: string;
  /**
   *
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblemAllOfInvalidPayload
   */
  tariffIsValidFrom: string;
  /**
   *
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblemAllOfInvalidPayload
   */
  tariffIsValidTo?: string;
}
/**
 * Content of a failed tariff validation error response due to provided invalid zipcode.
 * @export
 * @interface InvalidZipCodeValidationProblem
 */
export interface InvalidZipCodeValidationProblem {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof InvalidZipCodeValidationProblem
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof InvalidZipCodeValidationProblem
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof InvalidZipCodeValidationProblem
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof InvalidZipCodeValidationProblem
   */
  details?: string;
  /**
   * Dictionary containing validation errors.
   * @type {{ [key: string]: string; }}
   * @memberof InvalidZipCodeValidationProblem
   */
  validationErrors?: { [key: string]: string };
  /**
   *
   * @type {InvalidZipCodeValidationProblemAllOfInvalidPayload}
   * @memberof InvalidZipCodeValidationProblem
   */
  invalidPayload?: InvalidZipCodeValidationProblemAllOfInvalidPayload;
}
/**
 * Additional information about the tariff validation error.
 * @export
 * @interface InvalidZipCodeValidationProblemAllOfInvalidPayload
 */
export interface InvalidZipCodeValidationProblemAllOfInvalidPayload {
  /**
   *
   * @type {string}
   * @memberof InvalidZipCodeValidationProblemAllOfInvalidPayload
   */
  zipCode: string;
}
/**
 *
 * @export
 * @interface Label
 */
export interface Label {
  /**
   *
   * @type {string}
   * @memberof Label
   */
  id: string;
  /**
   * The human readable unique name of the label.
   * @type {string}
   * @memberof Label
   */
  name: string;
  /**
   * Whether this label is synced to the newsletter client.
   * @type {boolean}
   * @memberof Label
   */
  syncedToNewsletterClient?: boolean;
}
/**
 * A measuring point is a physical point at which the consumption of electricity or gas is measured. A meter change takes place at a measuring point. A measuring point has always one installed meter but never more than one at the same time.
 * @export
 * @interface MeasuringPoint
 */
export interface MeasuringPoint {
  /**
   * The id of the measuring point.
   * @type {string}
   * @memberof MeasuringPoint
   */
  id: string;
  /**
   *
   * @type {MeasuringPointAddress}
   * @memberof MeasuringPoint
   */
  address: MeasuringPointAddress;
  /**
   *
   * @type {MeasuringPointType}
   * @memberof MeasuringPoint
   */
  type: MeasuringPointType;
  /**
   * The meters that have been, are or will be installed at this measuring point.
   * @type {Array<MeasuringPointMeterHistoryInner>}
   * @memberof MeasuringPoint
   */
  meterHistory: Array<MeasuringPointMeterHistoryInner>;
}

/**
 *
 * @export
 * @interface MeasuringPointAddress
 */
export interface MeasuringPointAddress {
  /**
   * Unique identifier of the market location within the regulated energy market.
   * @type {string}
   * @memberof MeasuringPointAddress
   */
  maLo?: string;
  /**
   *
   * @type {string}
   * @memberof MeasuringPointAddress
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof MeasuringPointAddress
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof MeasuringPointAddress
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof MeasuringPointAddress
   */
  city: string;
  /**
   * Additional information about the address, e.g. floor, apartment number, etc.
   * @type {string}
   * @memberof MeasuringPointAddress
   */
  additionalInformation?: string;
  /**
   * Unique identifier of the measurement location within the regulated energy market.
   * @type {string}
   * @memberof MeasuringPointAddress
   */
  meLo?: string;
}
/**
 *
 * @export
 * @interface MeasuringPointAsAdminListInner
 */
export interface MeasuringPointAsAdminListInner {
  /**
   * The id of the measuring point.
   * @type {string}
   * @memberof MeasuringPointAsAdminListInner
   */
  id: string;
  /**
   *
   * @type {MeasuringPointType}
   * @memberof MeasuringPointAsAdminListInner
   */
  type: MeasuringPointType;
  /**
   * A measuring point is dispatched if it gets supplied by an active contract or via a third supplier.
   * @type {boolean}
   * @memberof MeasuringPointAsAdminListInner
   */
  isDispatched: boolean;
  /**
   *
   * @type {MeasuringPointAddress}
   * @memberof MeasuringPointAsAdminListInner
   */
  address: MeasuringPointAddress;
  /**
   * The third supply state, if available (part of the `thirdSupplyHistory`).
   * @type {ThirdSupplyState}
   * @memberof MeasuringPointAsAdminListInner
   */
  thirdSupplyState?: ThirdSupplyState;
  /**
   * The id of the contract, if not in third supply (part of the `contractHistory`).
   * @type {string}
   * @memberof MeasuringPointAsAdminListInner
   */
  contractId?: string;
  /**
   *
   * @type {PreliminaryContract}
   * @memberof MeasuringPointAsAdminListInner
   */
  preliminaryContract?: PreliminaryContract;
  /**
   * The serial number of the meter. A serial number is NOT unique but duplicates are very rare.
   * @type {string}
   * @memberof MeasuringPointAsAdminListInner
   */
  meterSerialNumber?: string;
}

/**
 *
 * @export
 * @interface MeasuringPointMeterHistoryInner
 */
export interface MeasuringPointMeterHistoryInner {
  /**
   * The id of the meter.
   * @type {string}
   * @memberof MeasuringPointMeterHistoryInner
   */
  id: string;
  /**
   * The serial number of the meter. A serial number is NOT unique but duplicates are very rare.
   * @type {string}
   * @memberof MeasuringPointMeterHistoryInner
   */
  serialNumber: string;
  /**
   * The date at which the meter was or will be installed. The date is **required for tenant power measuring points**.
   * @type {string}
   * @memberof MeasuringPointMeterHistoryInner
   */
  installedAt: string;
  /**
   * A remote controllable meter can be remotely disconnected from the power grid by the network operator.
   * @type {boolean}
   * @memberof MeasuringPointMeterHistoryInner
   */
  isRemoteControllable: boolean;
  /**
   * The readings that have been taken by the meter.
   * @type {Array<MeterReading>}
   * @memberof MeasuringPointMeterHistoryInner
   */
  readings: Array<MeterReading>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const MeasuringPointType = {
  Sum: "sum",
  Sub: "sub",
  Cascade: "cascade",
  Pv: "pv",
  Bhkw: "bhkw",
  Battery: "battery",
  FuelCell: "fuelCell",
  HeatingPump: "heatingPump",
} as const;

export type MeasuringPointType =
  (typeof MeasuringPointType)[keyof typeof MeasuringPointType];

/**
 *
 * @export
 * @interface MeasuringPointWithMeterAsAdmin
 */
export interface MeasuringPointWithMeterAsAdmin {
  /**
   * A measuring point is dispatched if it gets supplied by an active contract or via a third supplier.
   * @type {boolean}
   * @memberof MeasuringPointWithMeterAsAdmin
   */
  isDispatched: boolean;
  /**
   * A note that can be added to the measuring point. This note is only visible to the admin.
   * @type {string}
   * @memberof MeasuringPointWithMeterAsAdmin
   */
  note?: string;
  /**
   * The contracts that have been, are or will be signed for this measuring point ordered by the begin date of the contract.
   * @type {Array<ContractHistoryEntry>}
   * @memberof MeasuringPointWithMeterAsAdmin
   */
  contractHistory?: Array<ContractHistoryEntry>;
  /**
   *
   * @type {PreliminaryContract}
   * @memberof MeasuringPointWithMeterAsAdmin
   */
  preliminaryContract?: PreliminaryContract;
  /**
   * This history contains all time spans in which this measuring point was supplied by a third supplier.
   * @type {Array<ThirdSupplyHistory>}
   * @memberof MeasuringPointWithMeterAsAdmin
   */
  thirdSupplyHistory?: Array<ThirdSupplyHistory>;
  /**
   *
   * @type {InitialMeterInstallation}
   * @memberof MeasuringPointWithMeterAsAdmin
   */
  meter?: InitialMeterInstallation;
  /**
   * The id of the measuring point.
   * @type {string}
   * @memberof MeasuringPointWithMeterAsAdmin
   */
  id: string;
  /**
   *
   * @type {MeasuringPointAddress}
   * @memberof MeasuringPointWithMeterAsAdmin
   */
  address: MeasuringPointAddress;
  /**
   *
   * @type {MeasuringPointType}
   * @memberof MeasuringPointWithMeterAsAdmin
   */
  type: MeasuringPointType;
  /**
   * The meters that have been, are or will be installed at this measuring point.
   * @type {Array<MeasuringPointMeterHistoryInner>}
   * @memberof MeasuringPointWithMeterAsAdmin
   */
  meterHistory: Array<MeasuringPointMeterHistoryInner>;
}

/**
 *
 * @export
 * @interface Meter
 */
export interface Meter {
  /**
   * The id of the meter.
   * @type {string}
   * @memberof Meter
   */
  id: string;
  /**
   * The serial number of the meter. A serial number is NOT unique but duplicates are very rare.
   * @type {string}
   * @memberof Meter
   */
  serialNumber: string;
  /**
   * The date at which the meter was or will be installed. The date is **required for tenant power measuring points**.
   * @type {string}
   * @memberof Meter
   */
  installedAt?: string;
  /**
   * A remote controllable meter can be remotely disconnected from the power grid by the network operator.
   * @type {boolean}
   * @memberof Meter
   */
  isRemoteControllable?: boolean;
  /**
   * The readings that have been taken by the meter.
   * @type {Array<MeterReading>}
   * @memberof Meter
   */
  readings: Array<MeterReading>;
}
/**
 *
 * @export
 * @interface MeterReading
 */
export interface MeterReading {
  /**
   *
   * @type {number}
   * @memberof MeterReading
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof MeterReading
   */
  unit: MeterReadingUnitEnum;
  /**
   * The ID of this meter reading.
   * @type {string}
   * @memberof MeterReading
   */
  id: string;
  /**
   * The date of the reading.
   * @type {string}
   * @memberof MeterReading
   */
  readAt: string;
  /**
   * If the reading is for low tariff (true) or high tariff (false).
   * @type {boolean}
   * @memberof MeterReading
   */
  isLowTariff?: boolean;
  /**
   *
   * @type {MeterReadingType}
   * @memberof MeterReading
   */
  type: MeterReadingType;
}

export const MeterReadingUnitEnum = {
  KWh: "kWh",
  M3: "m3",
} as const;

export type MeterReadingUnitEnum =
  (typeof MeterReadingUnitEnum)[keyof typeof MeterReadingUnitEnum];

/**
 *  networkOperator = power grid operator  serviceProvider = Envera  supplier = Polarstern
 * @export
 * @enum {string}
 */

export const MeterReadingType = {
  Customer: "customer",
  NetworkOperator: "networkOperator",
  NetworkOperatorEstimate: "networkOperatorEstimate",
  ServiceProviderEstimate: "serviceProviderEstimate",
  Supplier: "supplier",
} as const;

export type MeterReadingType = (typeof MeterReadingType)[keyof typeof MeterReadingType];

/**
 *
 * @export
 * @enum {string}
 */

export const MeteringConcept = {
  MeKo1KundenanlageMitPhysSzPv: "MeKo1: Kundenanlage mit phys. SZ & PV",
  MeKo1aKundenanlageMitPhysSzMehrerenPv:
    "MeKo1a: Kundenanlage mit phys. SZ & mehreren PV",
  MeKo2KundenanlageMitPhysSzPvSpeicher:
    "MeKo2: Kundenanlage mit phys. SZ , PV & Speicher",
  MeKo2aKundenanlageMitPhysSzPvSpeicher:
    "MeKo2a: Kundenanlage mit phys. SZ , PV & Speicher",
  MeKo3KundenanlageMitPhysSzPvEmob: "MeKo3: Kundenanlage mit phys. SZ , PV & Emob",
  MeKo3aKundenanlageMitPhysSzPvEmob: "MeKo3a: Kundenanlage mit phys. SZ , PV & Emob",
  MeKo3bKundenanlageMitPhysSzPvEmob: "MeKo3b: Kundenanlage mit phys. SZ , PV & Emob",
  MeKo4KundenanlageMitPhysSzPvWp: "MeKo4: Kundenanlage mit phys. SZ , PV & WP",
  MeKo4aKundenanlageMitPhysSzPvWp: "MeKo4a: Kundenanlage mit phys. SZ , PV & WP",
  MeKo4bKundenanlageMitPhysSzPvWp: "MeKo4b: Kundenanlage mit phys. SZ , PV & WP",
  MeKo5KundenanlageMitPhysSzPvEmobWp: "MeKo5: Kundenanlage mit phys. SZ , PV, Emob & WP",
  MeKo5aKundenanlageMitPhysSzPvEmobWp:
    "MeKo5a: Kundenanlage mit phys. SZ , PV, Emob & WP",
  MeKo5bKundenanlageMitPhysSzPvEmobWp:
    "MeKo5b: Kundenanlage mit phys. SZ , PV, Emob & WP",
  MeKo6KundenanlageMitPhysSzPvEmobSpeicherWp:
    "MeKo6: Kundenanlage mit phys. SZ, PV, Emob, Speicher & WP",
  MeKo6aKundenanlageMitPhysSzPvEmobSpeicherWp:
    "MeKo6a: Kundenanlage mit phys. SZ, PV, Emob, Speicher & WP",
  MeKo7KundenanlageMitPhysSzBhkw: "MeKo7: Kundenanlage mit phys. SZ & BHKW",
  MeKo8KundenanlageMitPhysSzPvBhkw: "MeKo8: Kundenanlage mit phys. SZ, PV & BHKW",
  MeKo8aKundenanlageMitPhysSzPvBhkw: "MeKo8a: Kundenanlage mit phys. SZ, PV & BHKW",
  MeKo8bKundenanlageMitPhysSzPvBhkw: "MeKo8b: Kundenanlage mit phys. SZ, PV & BHKW",
  MeKo8cKundenanlageMitPhysSzPvBhkw: "MeKo8c: Kundenanlage mit phys. SZ, PV & BHKW",
  MeKo9KundenanlageMitVirtSzPv: "MeKo9: Kundenanlage mit virt. SZ & PV",
  MeKo10KundenanlageMitVirtSzPvEmob: "MeKo10: Kundenanlage mit virt. SZ, PV & Emob",
  MeKo11KundenanlageMitVirtSzPvWp: "MeKo11: Kundenanlage mit virt. SZ, PV & WP",
  MeKo12KundenanlageMitVirtSzPvEmobWp: "MeKo12: Kundenanlage mit virt. SZ, PV, Emob & WP",
  MeKo13GgvMitDynVerteilung: "MeKo13: GGV mit dyn. Verteilung",
  MeKo13aPvTeilbelieferungMitDynVerteilung:
    "MeKo13a: PV-Teilbelieferung mit dyn. Verteilung",
  MeKo13bGgvMitStatVerteilung: "MeKo13b: GGV mit stat. Verteilung",
  MeKo13cPvTeilbelieferungMitStatVerteilung:
    "MeKo13c: PV-Teilbelieferung mit stat. Verteilung",
  MeKo14KundenanlageMitPhysSzPvMsAnschluss:
    "MeKo14: Kundenanlage mit phys. SZ, PV & MS-Anschluss.",
  MeKo14aKundenanlageMitPhysSzPvMsAnschluss:
    "MeKo14a: Kundenanlage mit phys. SZ, PV & MS-Anschluss.",
  MeKo15GewerbesolarPvDirektlieferungMitEinemAbnehmerUndSpeicher:
    "MeKo15: Gewerbesolar – PV-Direktlieferung mit einem Abnehmer und Speicher",
} as const;

export type MeteringConcept = (typeof MeteringConcept)[keyof typeof MeteringConcept];

/**
 *
 * @export
 * @enum {string}
 */

export const OrderReason = {
  NewMeter: "newMeter",
  Move: "move",
  SupplierChange: "supplierChange",
  FromDefaultService: "fromDefaultService",
} as const;

export type OrderReason = (typeof OrderReason)[keyof typeof OrderReason];

/**
 *
 * @export
 * @interface PartialTenantPowerProject
 */
export interface PartialTenantPowerProject {
  /**
   * The id of the tenant power project.
   * @type {string}
   * @memberof PartialTenantPowerProject
   */
  id?: string;
  /**
   * The human readable, unique code of the project.
   * @type {string}
   * @memberof PartialTenantPowerProject
   */
  code?: string;
  /**
   * The id of the tenant power micro grid.
   * @type {string}
   * @memberof PartialTenantPowerProject
   */
  microGridId?: string | null;
  /**
   * The measuring point operator of the tenant power project.
   * @type {string}
   * @memberof PartialTenantPowerProject
   */
  measuringPointOperator?: string | null;
  /**
   * The name of the tenant power project.
   * @type {string}
   * @memberof PartialTenantPowerProject
   */
  name?: string;
  /**
   * The responsible manager of the tenant power project.
   * @type {string}
   * @memberof PartialTenantPowerProject
   */
  manager?: string;
  /**
   * The responsible supervisor handling the operative business of the project.
   * @type {string}
   * @memberof PartialTenantPowerProject
   */
  supervisor?: string;
  /**
   * The property developers of the building of the tenant power contract.
   * @type {string}
   * @memberof PartialTenantPowerProject
   */
  contractPartner?: string;
  /**
   * The partial, immutable tariff id of the tenant power project.
   * @type {string}
   * @memberof PartialTenantPowerProject
   */
  partialTariffId?: string;
  /**
   * The ID of the tariff.
   * @type {string}
   * @memberof PartialTenantPowerProject
   */
  tariffId?: string;
  /**
   * Whether the tenant power project is funded by KfW40+.
   * @type {boolean}
   * @memberof PartialTenantPowerProject
   */
  isKfw40PlusFunding?: boolean;
  /**
   * Whether the building is newly build.
   * @type {boolean}
   * @memberof PartialTenantPowerProject
   */
  isNewlyBuilt?: boolean;
  /**
   * A note for the tenant power project.
   * @type {string}
   * @memberof PartialTenantPowerProject
   */
  note?: string | null;
  /**
   *
   * @type {MeteringConcept}
   * @memberof PartialTenantPowerProject
   */
  meteringConcept?: MeteringConcept;
  /**
   *
   * @type {PropertyManagement}
   * @memberof PartialTenantPowerProject
   */
  propertyManagement?: PropertyManagement;
}

/**
 * @type PersonalData
 * @export
 */
export type PersonalData = PersonalDataOneOf | PersonalDataOneOf1;

/**
 *
 * @export
 * @interface PersonalDataOneOf
 */
export interface PersonalDataOneOf {
  /**
   *
   * @type {string}
   * @memberof PersonalDataOneOf
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataOneOf
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataOneOf
   */
  dateOfBirth: string;
}
/**
 *
 * @export
 * @interface PersonalDataOneOf1
 */
export interface PersonalDataOneOf1 {
  /**
   *
   * @type {string}
   * @memberof PersonalDataOneOf1
   */
  companyName: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataOneOf1
   */
  careOf?: string;
}
/**
 * @type PersonalDataWithMinimalValues
 * @export
 */
export type PersonalDataWithMinimalValues = CompanyPersonalData | PrivatePersonalData;

/**
 *
 * @export
 * @interface PhoneNumber
 */
export interface PhoneNumber {
  /**
   *
   * @type {string}
   * @memberof PhoneNumber
   */
  value: string;
  /**
   * This flag indicates if the person also wants to be contacted via SMS.
   * @type {boolean}
   * @memberof PhoneNumber
   */
  isSmsAllowed?: boolean;
}
/**
 *
 * @export
 * @interface PlainEmailAddress
 */
export interface PlainEmailAddress {
  /**
   *
   * @type {string}
   * @memberof PlainEmailAddress
   */
  value: string;
}
/**
 *
 * @export
 * @interface PreliminaryContract
 */
export interface PreliminaryContract {
  /**
   *
   * @type {string}
   * @memberof PreliminaryContract
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof PreliminaryContract
   */
  lastName: string;
  /**
   * Date of requested supply of a customer.
   * @type {string}
   * @memberof PreliminaryContract
   */
  requestedBeginAt?: string;
}
/**
 *
 * @export
 * @interface PreviousContract
 */
export interface PreviousContract {
  /**
   * Date at which the previous contract was or will be terminated. If this is not provided on contract creation, Polarstern needs to terminate the contract on behalf of the customer.
   * @type {string}
   * @memberof PreviousContract
   */
  terminatedAt?: string;
  /**
   * If the previous contract was a Polarstern contract, its ID can be specified here to replace it with the new contract.
   * @type {string}
   * @memberof PreviousContract
   */
  id?: string;
  /**
   * Name of the supplier with whom the previous contract was concluded.
   * @type {string}
   * @memberof PreviousContract
   */
  previousSupplierName: string;
}
/**
 *
 * @export
 * @interface Price
 */
export interface Price {
  /**
   * The price\'s currency as a ISO 4217 alphabetic code.
   * @type {string}
   * @memberof Price
   */
  currencyCode: string;
  /**
   * The actual price value.
   * @type {string}
   * @memberof Price
   */
  value: string;
}
/**
 *
 * @export
 * @interface PrivatePersonalData
 */
export interface PrivatePersonalData {
  /**
   *
   * @type {string}
   * @memberof PrivatePersonalData
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PrivatePersonalData
   */
  lastName: string;
}
/**
 * Content of an error response, based on https://datatracker.ietf.org/doc/html/rfc7807.
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof ProblemDetails
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof ProblemDetails
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof ProblemDetails
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof ProblemDetails
   */
  details?: string;
}
/**
 *
 * @export
 * @interface ProblemDetailsWithValidationErrors
 */
export interface ProblemDetailsWithValidationErrors {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof ProblemDetailsWithValidationErrors
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof ProblemDetailsWithValidationErrors
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof ProblemDetailsWithValidationErrors
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof ProblemDetailsWithValidationErrors
   */
  details?: string;
  /**
   * Dictionary containing validation errors.
   * @type {{ [key: string]: string; }}
   * @memberof ProblemDetailsWithValidationErrors
   */
  validationErrors?: { [key: string]: string };
}
/**
 * The property management of the building of the tenant power project.
 * @export
 * @interface PropertyManagement
 */
export interface PropertyManagement {
  /**
   *
   * @type {string}
   * @memberof PropertyManagement
   */
  customerId: string;
}
/**
 *
 * @export
 * @interface PublicTariff
 */
export interface PublicTariff {
  /**
   * The tariff\'s ID.
   * @type {string}
   * @memberof PublicTariff
   */
  id: string;
  /**
   *
   * @type {TariffType}
   * @memberof PublicTariff
   */
  type: TariffType;
  /**
   * The tariff\'s sub type, used to narrowing down tariffs.
   * @type {string}
   * @memberof PublicTariff
   */
  subType: PublicTariffSubTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PublicTariff
   */
  runtimeType: PublicTariffRuntimeTypeEnum;
  /**
   * Whether this is a key account tariff.
   * @type {boolean}
   * @memberof PublicTariff
   */
  isKeyAccountTariff: boolean;
  /**
   * The human-readable name of the tariff.
   * @type {string}
   * @memberof PublicTariff
   */
  name: string;
  /**
   * The ZIP codes of the areas the tariff is valid in.
   * @type {Array<string>}
   * @memberof PublicTariff
   */
  zipCodes: Array<string>;
  /**
   * If provided specifies the date until which the price guarantee is valid.
   * @type {string}
   * @memberof PublicTariff
   */
  priceGuaranteedUntil?: string;
  /**
   * If provided specifies the duration for which the price guarantee is valid, currently only applicable for tariffs with the comfort term.
   * @type {number}
   * @memberof PublicTariff
   */
  priceGuaranteeDurationInMonths?: number;
  /**
   * The [value-added tax](https://en.wikipedia.org/wiki/Value-added_tax) (VAT) to apply for this tariff.
   * @type {number}
   * @memberof PublicTariff
   */
  valueAddedTax?: number;
  /**
   * The id of the partner which is only set if this is a partner tariff.
   * @type {string}
   * @memberof PublicTariff
   */
  partnerId?: string;
}

export const PublicTariffSubTypeEnum = {
  Classic: "classic",
  ClassicFlex: "classicFlex",
  Own: "own",
  Double: "double",
  HeatPump: "heatPump",
  NightStorageHeating: "nightStorageHeating",
  TenantPower: "tenantPower",
  TenantPowerShadow: "tenantPowerShadow",
  CarPower: "carPower",
  CarPowerPlus: "carPowerPlus",
  IndustrySolar: "industrySolar",
  Geg: "geg",
  Vegan: "vegan",
} as const;

export type PublicTariffSubTypeEnum =
  (typeof PublicTariffSubTypeEnum)[keyof typeof PublicTariffSubTypeEnum];
export const PublicTariffRuntimeTypeEnum = {
  Standard: "standard",
  Comfort: "comfort",
} as const;

export type PublicTariffRuntimeTypeEnum =
  (typeof PublicTariffRuntimeTypeEnum)[keyof typeof PublicTariffRuntimeTypeEnum];

/**
 *
 * @export
 * @interface PublicTariffWithSummarizedPriceRanges
 */
export interface PublicTariffWithSummarizedPriceRanges {
  /**
   * The prices to apply based on the customer\'s annual consumption.
   * @type {Array<TariffPriceRange>}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  priceRanges: Array<TariffPriceRange>;
  /**
   * The prices to apply for low tariffs based on the customer\'s annual consumption.
   * @type {Array<TariffPriceRange>}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  lowTariffPriceRanges?: Array<TariffPriceRange>;
  /**
   * The tariff\'s ID.
   * @type {string}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  id: string;
  /**
   *
   * @type {TariffType}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  type: TariffType;
  /**
   * The tariff\'s sub type, used to narrowing down tariffs.
   * @type {string}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  subType: PublicTariffWithSummarizedPriceRangesSubTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  runtimeType: PublicTariffWithSummarizedPriceRangesRuntimeTypeEnum;
  /**
   * Whether this is a key account tariff.
   * @type {boolean}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  isKeyAccountTariff: boolean;
  /**
   * The human-readable name of the tariff.
   * @type {string}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  name: string;
  /**
   * The ZIP codes of the areas the tariff is valid in.
   * @type {Array<string>}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  zipCodes: Array<string>;
  /**
   * If provided specifies the date until which the price guarantee is valid.
   * @type {string}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  priceGuaranteedUntil?: string;
  /**
   * If provided specifies the duration for which the price guarantee is valid, currently only applicable for tariffs with the comfort term.
   * @type {number}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  priceGuaranteeDurationInMonths?: number;
  /**
   * The [value-added tax](https://en.wikipedia.org/wiki/Value-added_tax) (VAT) to apply for this tariff.
   * @type {number}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  valueAddedTax?: number;
  /**
   * The id of the partner which is only set if this is a partner tariff.
   * @type {string}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  partnerId?: string;
}

export const PublicTariffWithSummarizedPriceRangesSubTypeEnum = {
  Classic: "classic",
  ClassicFlex: "classicFlex",
  Own: "own",
  Double: "double",
  HeatPump: "heatPump",
  NightStorageHeating: "nightStorageHeating",
  TenantPower: "tenantPower",
  TenantPowerShadow: "tenantPowerShadow",
  CarPower: "carPower",
  CarPowerPlus: "carPowerPlus",
  IndustrySolar: "industrySolar",
  Geg: "geg",
  Vegan: "vegan",
} as const;

export type PublicTariffWithSummarizedPriceRangesSubTypeEnum =
  (typeof PublicTariffWithSummarizedPriceRangesSubTypeEnum)[keyof typeof PublicTariffWithSummarizedPriceRangesSubTypeEnum];
export const PublicTariffWithSummarizedPriceRangesRuntimeTypeEnum = {
  Standard: "standard",
  Comfort: "comfort",
} as const;

export type PublicTariffWithSummarizedPriceRangesRuntimeTypeEnum =
  (typeof PublicTariffWithSummarizedPriceRangesRuntimeTypeEnum)[keyof typeof PublicTariffWithSummarizedPriceRangesRuntimeTypeEnum];

/**
 *
 * @export
 * @interface ReadonlyConfirmedSupply
 */
export interface ReadonlyConfirmedSupply {
  /**
   * Date of requested supply of a customer. The value is required for the creation of a tenant power contract.
   * @type {string}
   * @memberof ReadonlyConfirmedSupply
   */
  requestedBeginAt?: string;
  /**
   * Supply address. With exception of MaLo, it is also used as billing address, as long as no separate billing address is provided.
   * @type {Address}
   * @memberof ReadonlyConfirmedSupply
   */
  address?: Address;
  /**
   *
   * @type {any}
   * @memberof ReadonlyConfirmedSupply
   */
  confirmedBeginAt?: any;
  /**
   *
   * @type {any}
   * @memberof ReadonlyConfirmedSupply
   */
  confirmedAt?: any;
}
/**
 * A recommendation from one user to another.
 * @export
 * @interface Recommendation
 */
export interface Recommendation {
  /**
   *
   * @type {string}
   * @memberof Recommendation
   */
  channel: RecommendationChannelEnum;
  /**
   * The recommendation code the receiver should use when signing up at Polarstern.
   * @type {string}
   * @memberof Recommendation
   */
  code: string;
  /**
   * The referrer\'s email address. If a customer with this email address exists, the recommendation will be associated with it.
   * @type {string}
   * @memberof Recommendation
   */
  emailAddress: string;
  /**
   * The referrers customerId. May be empty if the referrer uses a non unique mail address.
   * @type {string}
   * @memberof Recommendation
   */
  referredBy?: string;
  /**
   * The redeemers customerId. May be empty if this recommendation has not been redeemed yet.
   * @type {string}
   * @memberof Recommendation
   */
  redeemedBy?: string;
  /**
   * The date the recommendation was redeemed at.
   * @type {string}
   * @memberof Recommendation
   */
  redeemedAt?: string;
  /**
   * The date the recommendation was paid out at.
   * @type {string}
   * @memberof Recommendation
   */
  paidOutAt?: string;
}

export const RecommendationChannelEnum = {
  Email: "email",
  Link: "link",
  WhatsApp: "whatsApp",
  Sms: "sms",
} as const;

export type RecommendationChannelEnum =
  (typeof RecommendationChannelEnum)[keyof typeof RecommendationChannelEnum];

/**
 *
 * @export
 * @interface ReplaceCustomerLabelsRequest
 */
export interface ReplaceCustomerLabelsRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof ReplaceCustomerLabelsRequest
   */
  items: Array<string>;
}
/**
 *
 * @export
 * @interface RunContractUpdate200Response
 */
export interface RunContractUpdate200Response {
  /**
   *
   * @type {number}
   * @memberof RunContractUpdate200Response
   */
  updatedContracts: number;
}
/**
 *
 * @export
 * @interface RunNewsletterServiceSync200Response
 */
export interface RunNewsletterServiceSync200Response {
  /**
   * The inclusive start of the customer number range.
   * @type {string}
   * @memberof RunNewsletterServiceSync200Response
   */
  from: string;
  /**
   * The exclusive end of the customer number range.
   * @type {string}
   * @memberof RunNewsletterServiceSync200Response
   */
  to: string;
}
/**
 *
 * @export
 * @interface RunNewsletterServiceSyncRequest
 */
export interface RunNewsletterServiceSyncRequest {
  /**
   * The inclusive start of the customer number range.
   * @type {string}
   * @memberof RunNewsletterServiceSyncRequest
   */
  from: string;
  /**
   * The number of customer numbers in the range.
   * @type {number}
   * @memberof RunNewsletterServiceSyncRequest
   */
  count: number;
}
/**
 *
 * @export
 * @interface SearchContacts200Response
 */
export interface SearchContacts200Response {
  /**
   *
   * @type {Array<Contact>}
   * @memberof SearchContacts200Response
   */
  items?: Array<Contact>;
}
/**
 *
 * @export
 * @interface SearchCustomers200Response
 */
export interface SearchCustomers200Response {
  /**
   *
   * @type {Array<CustomerWithContracts>}
   * @memberof SearchCustomers200Response
   */
  items: Array<CustomerWithContracts>;
}
/**
 *
 * @export
 * @interface SearchProjects200Response
 */
export interface SearchProjects200Response {
  /**
   *
   * @type {Array<TenantPowerProjectWithSomeMeasuringPoints>}
   * @memberof SearchProjects200Response
   */
  items: Array<TenantPowerProjectWithSomeMeasuringPoints>;
}
/**
 *
 * @export
 * @interface SepaMandate
 */
export interface SepaMandate {
  /**
   * The date and time at which the customer accepted the SEPA mandate.
   * @type {string}
   * @memberof SepaMandate
   */
  acceptedAt: string;
  /**
   *
   * @type {any}
   * @memberof SepaMandate
   */
  reference?: any;
}
/**
 *
 * @export
 * @interface SwitchTariffRequest
 */
export interface SwitchTariffRequest {
  /**
   * The tariff\'s ID.
   * @type {string}
   * @memberof SwitchTariffRequest
   */
  tariffId: string;
}
/**
 *
 * @export
 * @interface Tariff
 */
export interface Tariff {
  /**
   * The prices to apply based on the customer\'s annual consumption.
   * @type {Array<TariffPriceRange>}
   * @memberof Tariff
   */
  priceRanges: Array<TariffPriceRange>;
  /**
   * The prices to apply for low tariffs based on the customer\'s annual consumption.
   * @type {Array<TariffPriceRange>}
   * @memberof Tariff
   */
  lowTariffPriceRanges?: Array<TariffPriceRange>;
  /**
   * The tariff\'s ID.
   * @type {string}
   * @memberof Tariff
   */
  id: string;
  /**
   *
   * @type {TariffType}
   * @memberof Tariff
   */
  type: TariffType;
  /**
   * The tariff\'s sub type, used to narrowing down tariffs.
   * @type {string}
   * @memberof Tariff
   */
  subType: TariffSubTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Tariff
   */
  runtimeType: TariffRuntimeTypeEnum;
  /**
   * Whether this is a key account tariff.
   * @type {boolean}
   * @memberof Tariff
   */
  isKeyAccountTariff: boolean;
  /**
   * The human-readable name of the tariff.
   * @type {string}
   * @memberof Tariff
   */
  name: string;
  /**
   * The ZIP codes of the areas the tariff is valid in.
   * @type {Array<string>}
   * @memberof Tariff
   */
  zipCodes: Array<string>;
  /**
   * If provided specifies the date until which the price guarantee is valid.
   * @type {string}
   * @memberof Tariff
   */
  priceGuaranteedUntil?: string;
  /**
   * If provided specifies the duration for which the price guarantee is valid, currently only applicable for tariffs with the comfort term.
   * @type {number}
   * @memberof Tariff
   */
  priceGuaranteeDurationInMonths?: number;
  /**
   * The [value-added tax](https://en.wikipedia.org/wiki/Value-added_tax) (VAT) to apply for this tariff.
   * @type {number}
   * @memberof Tariff
   */
  valueAddedTax?: number;
  /**
   * The id of the partner which is only set if this is a partner tariff.
   * @type {string}
   * @memberof Tariff
   */
  partnerId?: string;
  /**
   * The tariff is valid from this date on, including this date.
   * @type {string}
   * @memberof Tariff
   */
  validFrom: string;
  /**
   * The tariff is valid until this date, including this date.
   * @type {string}
   * @memberof Tariff
   */
  validTo?: string;
  /**
   * This tax directly refers to the [Stromsteuergesetz](https://de.wikipedia.org/wiki/Stromsteuergesetz_(Deutschland))
   * @type {Price}
   * @memberof Tariff
   */
  taxPerUnit?: Price;
  /**
   * Whether this is a public tariff.
   * @type {boolean}
   * @memberof Tariff
   */
  isPublic?: boolean;
  /**
   *
   * @type {ForecastBasis}
   * @memberof Tariff
   */
  forecastBasis: ForecastBasis;
}

export const TariffSubTypeEnum = {
  Classic: "classic",
  ClassicFlex: "classicFlex",
  Own: "own",
  Double: "double",
  HeatPump: "heatPump",
  NightStorageHeating: "nightStorageHeating",
  TenantPower: "tenantPower",
  TenantPowerShadow: "tenantPowerShadow",
  CarPower: "carPower",
  CarPowerPlus: "carPowerPlus",
  IndustrySolar: "industrySolar",
  Geg: "geg",
  Vegan: "vegan",
} as const;

export type TariffSubTypeEnum =
  (typeof TariffSubTypeEnum)[keyof typeof TariffSubTypeEnum];
export const TariffRuntimeTypeEnum = {
  Standard: "standard",
  Comfort: "comfort",
} as const;

export type TariffRuntimeTypeEnum =
  (typeof TariffRuntimeTypeEnum)[keyof typeof TariffRuntimeTypeEnum];

/**
 *
 * @export
 * @interface TariffHistoryInner
 */
export interface TariffHistoryInner {
  /**
   * The ID of the tariff.
   * @type {string}
   * @memberof TariffHistoryInner
   */
  tariffId: string;
  /**
   * The confirmed date from which the contract was, is or will be supplied through this tariff.
   * @type {string}
   * @memberof TariffHistoryInner
   */
  validFrom?: string;
  /**
   * The earliest date at which this contract can be terminated, given this history entry is the current one. If not set, the contract can be terminated any time.
   * @type {string}
   * @memberof TariffHistoryInner
   */
  terminableFrom?: string;
}
/**
 *
 * @export
 * @interface TariffPriceRange
 */
export interface TariffPriceRange {
  /**
   * Lower (inclusive) bound of the range.
   * @type {Energy}
   * @memberof TariffPriceRange
   */
  from: Energy;
  /**
   * Upper (inclusive) bound of the range.
   * @type {Energy}
   * @memberof TariffPriceRange
   */
  to: Energy;
  /**
   * The net base price to be paid per year within this range.
   * @type {Price}
   * @memberof TariffPriceRange
   */
  basePrice: Price;
  /**
   * The net price to pay per unit on top of the base price.
   * @type {Price}
   * @memberof TariffPriceRange
   */
  pricePerUnit: Price;
}
/**
 * Tariff base type, further classified by its sub type.
 * @export
 * @enum {string}
 */

export const TariffType = {
  Power: "power",
  Gas: "gas",
} as const;

export type TariffType = (typeof TariffType)[keyof typeof TariffType];

/**
 *
 * @export
 * @enum {string}
 */

export const TenantPowerContractType = {
  TenantPower: "tenantPower",
  TenantPowerShadow: "tenantPowerShadow",
} as const;

export type TenantPowerContractType =
  (typeof TenantPowerContractType)[keyof typeof TenantPowerContractType];

/**
 *
 * @export
 * @interface TenantPowerProject
 */
export interface TenantPowerProject {
  /**
   * The id of the tenant power project.
   * @type {string}
   * @memberof TenantPowerProject
   */
  id: string;
  /**
   * The human readable, unique code of the project.
   * @type {string}
   * @memberof TenantPowerProject
   */
  code: string;
  /**
   * The id of the tenant power micro grid.
   * @type {string}
   * @memberof TenantPowerProject
   */
  microGridId?: string | null;
  /**
   * The measuring point operator of the tenant power project.
   * @type {string}
   * @memberof TenantPowerProject
   */
  measuringPointOperator?: string | null;
  /**
   * The name of the tenant power project.
   * @type {string}
   * @memberof TenantPowerProject
   */
  name: string;
  /**
   * The responsible manager of the tenant power project.
   * @type {string}
   * @memberof TenantPowerProject
   */
  manager: string;
  /**
   * The responsible supervisor handling the operative business of the project.
   * @type {string}
   * @memberof TenantPowerProject
   */
  supervisor?: string;
  /**
   * The property developers of the building of the tenant power contract.
   * @type {string}
   * @memberof TenantPowerProject
   */
  contractPartner: string;
  /**
   * The partial, immutable tariff id of the tenant power project.
   * @type {string}
   * @memberof TenantPowerProject
   */
  partialTariffId?: string;
  /**
   * The ID of the tariff.
   * @type {string}
   * @memberof TenantPowerProject
   */
  tariffId: string;
  /**
   * Whether the tenant power project is funded by KfW40+.
   * @type {boolean}
   * @memberof TenantPowerProject
   */
  isKfw40PlusFunding: boolean;
  /**
   * Whether the building is newly build.
   * @type {boolean}
   * @memberof TenantPowerProject
   */
  isNewlyBuilt: boolean;
  /**
   * A note for the tenant power project.
   * @type {string}
   * @memberof TenantPowerProject
   */
  note?: string | null;
  /**
   *
   * @type {MeteringConcept}
   * @memberof TenantPowerProject
   */
  meteringConcept: MeteringConcept;
  /**
   *
   * @type {PropertyManagement}
   * @memberof TenantPowerProject
   */
  propertyManagement?: PropertyManagement;
}

/**
 *
 * @export
 * @interface TenantPowerProjectAsAdmin
 */
export interface TenantPowerProjectAsAdmin {
  /**
   *
   * @type {DeprecatedMeter & object}
   * @memberof TenantPowerProjectAsAdmin
   */
  sumMeter?: DeprecatedMeter & object;
  /**
   *
   * @type {Array<BaseAddress>}
   * @memberof TenantPowerProjectAsAdmin
   */
  addresses?: Array<BaseAddress>;
  /**
   * The id of the tenant power project.
   * @type {string}
   * @memberof TenantPowerProjectAsAdmin
   */
  id: string;
  /**
   * The human readable, unique code of the project.
   * @type {string}
   * @memberof TenantPowerProjectAsAdmin
   */
  code: string;
  /**
   * The id of the tenant power micro grid.
   * @type {string}
   * @memberof TenantPowerProjectAsAdmin
   */
  microGridId?: string | null;
  /**
   * The measuring point operator of the tenant power project.
   * @type {string}
   * @memberof TenantPowerProjectAsAdmin
   */
  measuringPointOperator?: string | null;
  /**
   * The name of the tenant power project.
   * @type {string}
   * @memberof TenantPowerProjectAsAdmin
   */
  name: string;
  /**
   * The responsible manager of the tenant power project.
   * @type {string}
   * @memberof TenantPowerProjectAsAdmin
   */
  manager: string;
  /**
   * The responsible supervisor handling the operative business of the project.
   * @type {string}
   * @memberof TenantPowerProjectAsAdmin
   */
  supervisor?: string;
  /**
   * The property developers of the building of the tenant power contract.
   * @type {string}
   * @memberof TenantPowerProjectAsAdmin
   */
  contractPartner: string;
  /**
   * The partial, immutable tariff id of the tenant power project.
   * @type {string}
   * @memberof TenantPowerProjectAsAdmin
   */
  partialTariffId?: string;
  /**
   * The ID of the tariff.
   * @type {string}
   * @memberof TenantPowerProjectAsAdmin
   */
  tariffId: string;
  /**
   * Whether the tenant power project is funded by KfW40+.
   * @type {boolean}
   * @memberof TenantPowerProjectAsAdmin
   */
  isKfw40PlusFunding: boolean;
  /**
   * Whether the building is newly build.
   * @type {boolean}
   * @memberof TenantPowerProjectAsAdmin
   */
  isNewlyBuilt: boolean;
  /**
   * A note for the tenant power project.
   * @type {string}
   * @memberof TenantPowerProjectAsAdmin
   */
  note?: string | null;
  /**
   *
   * @type {MeteringConcept}
   * @memberof TenantPowerProjectAsAdmin
   */
  meteringConcept: MeteringConcept;
  /**
   *
   * @type {PropertyManagement}
   * @memberof TenantPowerProjectAsAdmin
   */
  propertyManagement?: PropertyManagement;
}

/**
 *
 * @export
 * @interface TenantPowerProjectWithSomeMeasuringPoints
 */
export interface TenantPowerProjectWithSomeMeasuringPoints {
  /**
   * The id of the tenant power project.
   * @type {string}
   * @memberof TenantPowerProjectWithSomeMeasuringPoints
   */
  id: string;
  /**
   * The human readable, unique code of the project.
   * @type {string}
   * @memberof TenantPowerProjectWithSomeMeasuringPoints
   */
  code: string;
  /**
   * The id of the tenant power micro grid.
   * @type {string}
   * @memberof TenantPowerProjectWithSomeMeasuringPoints
   */
  microGridId?: string | null;
  /**
   * The measuring point operator of the tenant power project.
   * @type {string}
   * @memberof TenantPowerProjectWithSomeMeasuringPoints
   */
  measuringPointOperator?: string | null;
  /**
   * The name of the tenant power project.
   * @type {string}
   * @memberof TenantPowerProjectWithSomeMeasuringPoints
   */
  name: string;
  /**
   * The responsible manager of the tenant power project.
   * @type {string}
   * @memberof TenantPowerProjectWithSomeMeasuringPoints
   */
  manager: string;
  /**
   * The responsible supervisor handling the operative business of the project.
   * @type {string}
   * @memberof TenantPowerProjectWithSomeMeasuringPoints
   */
  supervisor?: string;
  /**
   * The property developers of the building of the tenant power contract.
   * @type {string}
   * @memberof TenantPowerProjectWithSomeMeasuringPoints
   */
  contractPartner: string;
  /**
   * The partial, immutable tariff id of the tenant power project.
   * @type {string}
   * @memberof TenantPowerProjectWithSomeMeasuringPoints
   */
  partialTariffId?: string;
  /**
   * The ID of the tariff.
   * @type {string}
   * @memberof TenantPowerProjectWithSomeMeasuringPoints
   */
  tariffId: string;
  /**
   * Whether the tenant power project is funded by KfW40+.
   * @type {boolean}
   * @memberof TenantPowerProjectWithSomeMeasuringPoints
   */
  isKfw40PlusFunding: boolean;
  /**
   * Whether the building is newly build.
   * @type {boolean}
   * @memberof TenantPowerProjectWithSomeMeasuringPoints
   */
  isNewlyBuilt: boolean;
  /**
   * A note for the tenant power project.
   * @type {string}
   * @memberof TenantPowerProjectWithSomeMeasuringPoints
   */
  note?: string | null;
  /**
   *
   * @type {MeteringConcept}
   * @memberof TenantPowerProjectWithSomeMeasuringPoints
   */
  meteringConcept: MeteringConcept;
  /**
   *
   * @type {PropertyManagement}
   * @memberof TenantPowerProjectWithSomeMeasuringPoints
   */
  propertyManagement?: PropertyManagement;
  /**
   * The measuring points matching the query. This list is not necessarily complete.
   * @type {Array<MeasuringPointAsAdminListInner>}
   * @memberof TenantPowerProjectWithSomeMeasuringPoints
   */
  measuringPoints: Array<MeasuringPointAsAdminListInner>;
}

/**
 *
 * @export
 * @interface Termination
 */
export interface Termination {
  /**
   * Unique ID of the terminated contract.
   * @type {string}
   * @memberof Termination
   */
  contractId?: string;
  /**
   * Date at which the contract was or will be terminated.
   * @type {string}
   * @memberof Termination
   */
  terminatedAt: string;
  /**
   * If the customer moves to a new address and wants to stay with Polarstern. This is required to decide which document to send to the customer (Kündigungsbestätigung oder Abmeldebestätigung).
   * @type {boolean}
   * @memberof Termination
   */
  movedToNewAddress?: boolean;
  /**
   *
   * @type {BaseTerminationReason}
   * @memberof Termination
   */
  reason: BaseTerminationReason;
  /**
   *
   * @type {string}
   * @memberof Termination
   */
  note?: string;
  /**
   * Date at which the termination was confirmed by the network provider.
   * @type {string}
   * @memberof Termination
   */
  confirmedAt?: string;
  /**
   * Date and time at which the termination was created.
   * @type {string}
   * @memberof Termination
   */
  createdAt: string;
  /**
   * If the termination was created by an admin.
   * @type {boolean}
   * @memberof Termination
   */
  hasBeenTerminatedByAdmin?: boolean;
  /**
   *
   * @type {TerminationType}
   * @memberof Termination
   */
  type: TerminationType;
}
/**
 *
 * @export
 * @interface TerminationAsAdmin
 */
export interface TerminationAsAdmin {
  /**
   * Unique ID of the terminated contract.
   * @type {string}
   * @memberof TerminationAsAdmin
   */
  contractId?: string;
  /**
   * Date at which the contract was or will be terminated.
   * @type {string}
   * @memberof TerminationAsAdmin
   */
  terminatedAt: string;
  /**
   * If the customer moves to a new address and wants to stay with Polarstern. This is required to decide which document to send to the customer (Kündigungsbestätigung oder Abmeldebestätigung).
   * @type {boolean}
   * @memberof TerminationAsAdmin
   */
  movedToNewAddress?: boolean;
  /**
   *
   * @type {BaseTerminationReason}
   * @memberof TerminationAsAdmin
   */
  reason: BaseTerminationReason;
  /**
   *
   * @type {string}
   * @memberof TerminationAsAdmin
   */
  note?: string;
  /**
   *
   * @type {any}
   * @memberof TerminationAsAdmin
   */
  confirmedAt?: any;
  /**
   * Date and time at which the termination was created.
   * @type {string}
   * @memberof TerminationAsAdmin
   */
  createdAt: string;
  /**
   * If the termination was created by an admin.
   * @type {boolean}
   * @memberof TerminationAsAdmin
   */
  hasBeenTerminatedByAdmin?: boolean;
  /**
   *
   * @type {TerminationType}
   * @memberof TerminationAsAdmin
   */
  type: TerminationType;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TerminationReason = {
  Price: "price",
  ProviderSwitch: "providerSwitch",
  MoveToExistingContract: "moveToExistingContract",
  MoveAbroad: "moveAbroad",
  Move: "move",
  NoNewApartment: "noNewApartment",
  ProviderRestriction: "providerRestriction",
  MeterRemoval: "meterRemoval",
  Death: "death",
  UnsatisfactoryService: "unsatisfactoryService",
  NoGasConnection: "noGasConnection",
} as const;

export type TerminationReason =
  (typeof TerminationReason)[keyof typeof TerminationReason];

/**
 *
 * @export
 * @interface TerminationType
 */
export interface TerminationType {}
/**
 *
 * @export
 * @enum {string}
 */

export const TerminationTypeAsAdmin = {
  Extraordinary: "extraordinary",
  Cancellation: "cancellation",
} as const;

export type TerminationTypeAsAdmin =
  (typeof TerminationTypeAsAdmin)[keyof typeof TerminationTypeAsAdmin];

/**
 *
 * @export
 * @enum {string}
 */

export const TerminationTypeAsCustomer = {
  Regular: "regular",
  Move: "move",
  Transfer: "transfer",
} as const;

export type TerminationTypeAsCustomer =
  (typeof TerminationTypeAsCustomer)[keyof typeof TerminationTypeAsCustomer];

/**
 *
 * @export
 * @interface ThirdSupplyHistory
 */
export interface ThirdSupplyHistory {
  /**
   * The id of the third supply history entry.
   * @type {string}
   * @memberof ThirdSupplyHistory
   */
  id: string;
  /**
   * The date at which the supply entry started.
   * @type {string}
   * @memberof ThirdSupplyHistory
   */
  beginAt: string;
  /**
   * The date at which the supply entry ended.
   * @type {string}
   * @memberof ThirdSupplyHistory
   */
  endAt?: string;
  /**
   *
   * @type {ThirdSupplyState}
   * @memberof ThirdSupplyHistory
   */
  state: ThirdSupplyState;
  /**
   * The name of the supplier.
   * @type {string}
   * @memberof ThirdSupplyHistory
   */
  supplier?: string;
}

/**
 * The state of the supply entry.
 * @export
 * @enum {string}
 */

export const ThirdSupplyState = {
  ThirdSupplyRequested: "thirdSupplyRequested",
  ThirdSupply: "thirdSupply",
  DirectSupplyRequested: "directSupplyRequested",
} as const;

export type ThirdSupplyState = (typeof ThirdSupplyState)[keyof typeof ThirdSupplyState];

/**
 * @type Todo
 * @export
 */
export type Todo = ContractTodo | CustomerTodo;

/**
 *
 * @export
 * @interface Transfer
 */
export interface Transfer {
  /**
   *
   * @type {string}
   * @memberof Transfer
   */
  id: string;
  /**
   * The list of contracts that should be transferred.
   * @type {Array<TransferContract>}
   * @memberof Transfer
   */
  contracts?: Array<TransferContract>;
  /**
   * The date at which the contracts were or will be transferred. The termination date of the previous contract will be transferredAt - 1 day.
   * @type {string}
   * @memberof Transfer
   */
  transferredAt: string;
  /**
   * Name of the sender that originated the transfer. Might be the person\'s full name or a company name.
   * @type {string}
   * @memberof Transfer
   */
  sender: string;
  /**
   *
   * @type {TransferRecipient}
   * @memberof Transfer
   */
  recipient: TransferRecipient;
}
/**
 *
 * @export
 * @interface TransferContract
 */
export interface TransferContract {
  /**
   * ID of the original contract that is being transferred.
   * @type {string}
   * @memberof TransferContract
   */
  id: string;
  /**
   *
   * @type {ContractType}
   * @memberof TransferContract
   */
  type: ContractType;
  /**
   * The serial number of the meter. A serial number is NOT unique but duplicates are very rare.
   * @type {string}
   * @memberof TransferContract
   */
  meterSerialNumber: string;
  /**
   * Supply address of the original contract.
   * @type {Address}
   * @memberof TransferContract
   */
  supplyAddress: Address;
  /**
   * A list of tariffs that are, were or will be used by this contract ordered by its validity starting with the oldest tariff.
   * @type {Array<TariffHistoryInner>}
   * @memberof TransferContract
   */
  tariffHistory: Array<TariffHistoryInner>;
  /**
   * The (possibly estimated) annual consumption of the original contract.
   * @type {Energy}
   * @memberof TransferContract
   */
  annualConsumption?: Energy;
  /**
   * The (possibly estimated) low tariff annual consumption of the original contract. Can only be set for HT/NT-tariffs.
   * @type {Energy}
   * @memberof TransferContract
   */
  annualConsumptionLowTariff?: Energy;
}

/**
 * The recipient to receive the transferred contracts.
 * @export
 * @interface TransferRecipient
 */
export interface TransferRecipient {
  /**
   *
   * @type {EntityType}
   * @memberof TransferRecipient
   */
  type: EntityType;
  /**
   *
   * @type {string}
   * @memberof TransferRecipient
   */
  emailAddress: string;
  /**
   *
   * @type {PersonalDataWithMinimalValues}
   * @memberof TransferRecipient
   */
  personalData: PersonalDataWithMinimalValues;
}

/**
 *
 * @export
 * @interface TriggerAnotherEmailVerificationRequest
 */
export interface TriggerAnotherEmailVerificationRequest {
  /**
   *
   * @type {string}
   * @memberof TriggerAnotherEmailVerificationRequest
   */
  customerId: string;
}
/**
 *
 * @export
 * @interface UpdateProjectRequest
 */
export interface UpdateProjectRequest {
  /**
   * The id of the tenant power project.
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  id?: string;
  /**
   * The human readable, unique code of the project.
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  code?: string;
  /**
   * The id of the tenant power micro grid.
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  microGridId?: string | null;
  /**
   * The measuring point operator of the tenant power project.
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  measuringPointOperator?: string | null;
  /**
   * The name of the tenant power project.
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  name?: string;
  /**
   * The responsible manager of the tenant power project.
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  manager?: string;
  /**
   * The responsible supervisor handling the operative business of the project.
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  supervisor?: string;
  /**
   * The property developers of the building of the tenant power contract.
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  contractPartner?: string;
  /**
   * The partial, immutable tariff id of the tenant power project.
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  partialTariffId?: string;
  /**
   * The ID of the tariff.
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  tariffId?: string;
  /**
   * Whether the tenant power project is funded by KfW40+.
   * @type {boolean}
   * @memberof UpdateProjectRequest
   */
  isKfw40PlusFunding?: boolean;
  /**
   * Whether the building is newly build.
   * @type {boolean}
   * @memberof UpdateProjectRequest
   */
  isNewlyBuilt?: boolean;
  /**
   * A note for the tenant power project.
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  note?: string | null;
  /**
   *
   * @type {MeteringConcept}
   * @memberof UpdateProjectRequest
   */
  meteringConcept?: MeteringConcept;
  /**
   *
   * @type {PropertyManagement}
   * @memberof UpdateProjectRequest
   */
  propertyManagement?: PropertyManagement;
}

/**
 *
 * @export
 * @interface UpsertPreliminaryContractRequest
 */
export interface UpsertPreliminaryContractRequest {
  /**
   *
   * @type {PreliminaryContract}
   * @memberof UpsertPreliminaryContractRequest
   */
  preliminaryContract: PreliminaryContract | null;
}

/**
 * ContactsApi - axios parameter creator
 * @export
 */
export const ContactsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Creates a new contact and returns it.
     * @summary Create contact
     * @param {Contact} [contact] Request containing a contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createContact: async (
      contact?: Contact,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/contacts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        contact,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes the contact.
     * @summary Delete contact
     * @param {string} contactId ID of the contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteContact: async (
      contactId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'contactId' is not null or undefined
      assertParamExists("deleteContact", "contactId", contactId);
      const localVarPath = `/contacts/{contactId}`.replace(
        `{${"contactId"}}`,
        encodeURIComponent(String(contactId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets a contact by its ID.
     * @summary Get contact
     * @param {string} contactId ID of the contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContact: async (
      contactId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'contactId' is not null or undefined
      assertParamExists("getContact", "contactId", contactId);
      const localVarPath = `/contacts/{contactId}`.replace(
        `{${"contactId"}}`,
        encodeURIComponent(String(contactId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Replaces the specified contact
     * @summary Replace contact
     * @param {string} contactId ID of the contact.
     * @param {Contact} [contact] Request containing a contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceContact: async (
      contactId: string,
      contact?: Contact,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'contactId' is not null or undefined
      assertParamExists("replaceContact", "contactId", contactId);
      const localVarPath = `/contacts/{contactId}`.replace(
        `{${"contactId"}}`,
        encodeURIComponent(String(contactId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        contact,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Search for contacts
     * @summary Search contacts
     * @param {string} query The search query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchContacts: async (
      query: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists("searchContacts", "query", query);
      const localVarPath = `/contacts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      if (query !== undefined) {
        localVarQueryParameter["query"] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ContactsApi - functional programming interface
 * @export
 */
export const ContactsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContactsApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates a new contact and returns it.
     * @summary Create contact
     * @param {Contact} [contact] Request containing a contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createContact(
      contact?: Contact,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createContact(
        contact,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContactsApi.createContact"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Deletes the contact.
     * @summary Delete contact
     * @param {string} contactId ID of the contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteContact(
      contactId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContact(
        contactId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContactsApi.deleteContact"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Gets a contact by its ID.
     * @summary Get contact
     * @param {string} contactId ID of the contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContact(
      contactId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContact(
        contactId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContactsApi.getContact"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Replaces the specified contact
     * @summary Replace contact
     * @param {string} contactId ID of the contact.
     * @param {Contact} [contact] Request containing a contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async replaceContact(
      contactId: string,
      contact?: Contact,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.replaceContact(
        contactId,
        contact,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContactsApi.replaceContact"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Search for contacts
     * @summary Search contacts
     * @param {string} query The search query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchContacts(
      query: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SearchContacts200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchContacts(
        query,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContactsApi.searchContacts"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ContactsApi - factory interface
 * @export
 */
export const ContactsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContactsApiFp(configuration);
  return {
    /**
     * Creates a new contact and returns it.
     * @summary Create contact
     * @param {Contact} [contact] Request containing a contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createContact(
      contact?: Contact,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Contact> {
      return localVarFp
        .createContact(contact, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Deletes the contact.
     * @summary Delete contact
     * @param {string} contactId ID of the contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteContact(
      contactId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteContact(contactId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets a contact by its ID.
     * @summary Get contact
     * @param {string} contactId ID of the contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContact(
      contactId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Contact> {
      return localVarFp
        .getContact(contactId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Replaces the specified contact
     * @summary Replace contact
     * @param {string} contactId ID of the contact.
     * @param {Contact} [contact] Request containing a contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceContact(
      contactId: string,
      contact?: Contact,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Contact> {
      return localVarFp
        .replaceContact(contactId, contact, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Search for contacts
     * @summary Search contacts
     * @param {string} query The search query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchContacts(
      query: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SearchContacts200Response> {
      return localVarFp
        .searchContacts(query, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ContactsApi - object-oriented interface
 * @export
 * @class ContactsApi
 * @extends {BaseAPI}
 */
export class ContactsApi extends BaseAPI {
  /**
   * Creates a new contact and returns it.
   * @summary Create contact
   * @param {Contact} [contact] Request containing a contact.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public createContact(contact?: Contact, options?: RawAxiosRequestConfig) {
    return ContactsApiFp(this.configuration)
      .createContact(contact, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Deletes the contact.
   * @summary Delete contact
   * @param {string} contactId ID of the contact.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public deleteContact(contactId: string, options?: RawAxiosRequestConfig) {
    return ContactsApiFp(this.configuration)
      .deleteContact(contactId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets a contact by its ID.
   * @summary Get contact
   * @param {string} contactId ID of the contact.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public getContact(contactId: string, options?: RawAxiosRequestConfig) {
    return ContactsApiFp(this.configuration)
      .getContact(contactId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Replaces the specified contact
   * @summary Replace contact
   * @param {string} contactId ID of the contact.
   * @param {Contact} [contact] Request containing a contact.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public replaceContact(
    contactId: string,
    contact?: Contact,
    options?: RawAxiosRequestConfig,
  ) {
    return ContactsApiFp(this.configuration)
      .replaceContact(contactId, contact, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Search for contacts
   * @summary Search contacts
   * @param {string} query The search query
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public searchContacts(query: string, options?: RawAxiosRequestConfig) {
    return ContactsApiFp(this.configuration)
      .searchContacts(query, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ContractErrorsApi - axios parameter creator
 * @export
 */
export const ContractErrorsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Gets contract errors (NOKs) for given contract id
     * @summary Get contract errors
     * @param {string} contractId The id of the contract to get the contract errors for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContractErrorsById: async (
      contractId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("getContractErrorsById", "contractId", contractId);
      const localVarPath = `/contract-errors`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      if (contractId !== undefined) {
        localVarQueryParameter["contractId"] = contractId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ContractErrorsApi - functional programming interface
 * @export
 */
export const ContractErrorsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContractErrorsApiAxiosParamCreator(configuration);
  return {
    /**
     * Gets contract errors (NOKs) for given contract id
     * @summary Get contract errors
     * @param {string} contractId The id of the contract to get the contract errors for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContractErrorsById(
      contractId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetContractErrorsById200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContractErrorsById(
        contractId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractErrorsApi.getContractErrorsById"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ContractErrorsApi - factory interface
 * @export
 */
export const ContractErrorsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContractErrorsApiFp(configuration);
  return {
    /**
     * Gets contract errors (NOKs) for given contract id
     * @summary Get contract errors
     * @param {string} contractId The id of the contract to get the contract errors for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContractErrorsById(
      contractId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetContractErrorsById200Response> {
      return localVarFp
        .getContractErrorsById(contractId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ContractErrorsApi - object-oriented interface
 * @export
 * @class ContractErrorsApi
 * @extends {BaseAPI}
 */
export class ContractErrorsApi extends BaseAPI {
  /**
   * Gets contract errors (NOKs) for given contract id
   * @summary Get contract errors
   * @param {string} contractId The id of the contract to get the contract errors for
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractErrorsApi
   */
  public getContractErrorsById(contractId: string, options?: RawAxiosRequestConfig) {
    return ContractErrorsApiFp(this.configuration)
      .getContractErrorsById(contractId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ContractsApi - axios parameter creator
 * @export
 */
export const ContractsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Creates a new contract, adds it to the specified customer and returns it.
     * @summary Add contract
     * @param {string} customerId ID of the customer.
     * @param {ContractAsAdmin} [contractAsAdmin] Request to create a contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addContract: async (
      customerId: string,
      contractAsAdmin?: ContractAsAdmin,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("addContract", "customerId", customerId);
      const localVarPath = `/customers/{customerId}/contracts`.replace(
        `{${"customerId"}}`,
        encodeURIComponent(String(customerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        contractAsAdmin,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates a batch of new contracts and adds them to the specified customer.
     * @summary Add contracts
     * @param {string} customerId ID of the customer.
     * @param {AddContractsBatchRequest} [addContractsBatchRequest] Request to create a list of contracts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addContractsBatch: async (
      customerId: string,
      addContractsBatchRequest?: AddContractsBatchRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("addContractsBatch", "customerId", customerId);
      const localVarPath = `/customers/{customerId}/contracts/batch`.replace(
        `{${"customerId"}}`,
        encodeURIComponent(String(customerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addContractsBatchRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes the specified contract. Also deletes the customer if no other contracts exist.
     * @summary Delete contract
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteContract: async (
      customerId: string,
      contractId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("deleteContract", "customerId", customerId);
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("deleteContract", "contractId", contractId);
      const localVarPath = `/customers/{customerId}/contracts/{contractId}`
        .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
        .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes the bank account.
     * @summary Delete bank account
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteContractBankAccount: async (
      customerId: string,
      contractId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("deleteContractBankAccount", "customerId", customerId);
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("deleteContractBankAccount", "contractId", contractId);
      const localVarPath = `/customers/{customerId}/contracts/{contractId}/bank-account`
        .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
        .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes the contract partner.
     * @summary Delete contract partner
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteContractPartner: async (
      customerId: string,
      contractId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("deleteContractPartner", "customerId", customerId);
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("deleteContractPartner", "contractId", contractId);
      const localVarPath =
        `/customers/{customerId}/contracts/{contractId}/contract-partner`
          .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
          .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes a meter reading.
     * @summary Delete meter reading
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {string} meterReadingId ID of the meter reading.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMeterReading: async (
      customerId: string,
      contractId: string,
      meterReadingId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("deleteMeterReading", "customerId", customerId);
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("deleteMeterReading", "contractId", contractId);
      // verify required parameter 'meterReadingId' is not null or undefined
      assertParamExists("deleteMeterReading", "meterReadingId", meterReadingId);
      const localVarPath =
        `/customers/{customerId}/contracts/{contractId}/meter-readings/{meterReadingId}`
          .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
          .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
          .replace(`{${"meterReadingId"}}`, encodeURIComponent(String(meterReadingId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets a contract by its ID.
     * @summary Get contract
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContract: async (
      customerId: string,
      contractId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("getContract", "customerId", customerId);
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("getContract", "contractId", contractId);
      const localVarPath = `/customers/{customerId}/contracts/{contractId}`
        .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
        .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets all contracts of the specified customer.
     * @summary Get contracts
     * @param {string} customerId ID of the customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerContracts: async (
      customerId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("getCustomerContracts", "customerId", customerId);
      const localVarPath = `/customers/{customerId}/contracts`.replace(
        `{${"customerId"}}`,
        encodeURIComponent(String(customerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates a meter reading and returns it.
     * @summary Create meter reading
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {DeprecatedMeterReading} [deprecatedMeterReading] Request to add a meter reading.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMeterReading: async (
      customerId: string,
      contractId: string,
      deprecatedMeterReading?: DeprecatedMeterReading,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("postMeterReading", "customerId", customerId);
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("postMeterReading", "contractId", contractId);
      const localVarPath = `/customers/{customerId}/contracts/{contractId}/meter-readings`
        .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
        .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deprecatedMeterReading,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Replaces the bank account.
     * @summary Replace bank account
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {BankAccount} [bankAccount] Request to replace a bank account.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    replaceContractBankAccount: async (
      customerId: string,
      contractId: string,
      bankAccount?: BankAccount,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("replaceContractBankAccount", "customerId", customerId);
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("replaceContractBankAccount", "contractId", contractId);
      const localVarPath = `/customers/{customerId}/contracts/{contractId}/bank-account`
        .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
        .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bankAccount,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Replaces the billing address.
     * @summary Replace billing address
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {Address} [address] Request to replace a billing address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    replaceContractBillingAddress: async (
      customerId: string,
      contractId: string,
      address?: Address,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("replaceContractBillingAddress", "customerId", customerId);
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("replaceContractBillingAddress", "contractId", contractId);
      const localVarPath =
        `/customers/{customerId}/contracts/{contractId}/billing-address`
          .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
          .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        address,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Replaces the contract partner.
     * @summary Replace contract partner
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {ContractPartner} [contractPartner] Request to replace a contract partner.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    replaceContractPartner: async (
      customerId: string,
      contractId: string,
      contractPartner?: ContractPartner,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("replaceContractPartner", "customerId", customerId);
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("replaceContractPartner", "contractId", contractId);
      const localVarPath =
        `/customers/{customerId}/contracts/{contractId}/contract-partner`
          .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
          .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        contractPartner,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Switches tariff of the specified contract.
     * @summary Switch tariff
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {SwitchTariffRequest} [switchTariffRequest] Request to switch contract to the provided tariff.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchTariff: async (
      customerId: string,
      contractId: string,
      switchTariffRequest?: SwitchTariffRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("switchTariff", "customerId", customerId);
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("switchTariff", "contractId", contractId);
      const localVarPath = `/customers/{customerId}/contracts/{contractId}/switch-tariff`
        .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
        .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        switchTariffRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Adds a termination to the specified contract (i.e. terminates the contract) and returns it.
     * @summary Terminate contract
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {TerminationAsAdmin} [terminationAsAdmin] Request containing a contract termination.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    terminateContract: async (
      customerId: string,
      contractId: string,
      terminationAsAdmin?: TerminationAsAdmin,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("terminateContract", "customerId", customerId);
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("terminateContract", "contractId", contractId);
      const localVarPath = `/customers/{customerId}/contracts/{contractId}/termination`
        .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
        .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terminationAsAdmin,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates the specified contract.
     * @summary Update contract
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {ContractUpdateAsAdmin} [contractUpdateAsAdmin] Request to update a contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateContract: async (
      customerId: string,
      contractId: string,
      contractUpdateAsAdmin?: ContractUpdateAsAdmin,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("updateContract", "customerId", customerId);
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("updateContract", "contractId", contractId);
      const localVarPath = `/customers/{customerId}/contracts/{contractId}`
        .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
        .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/merge-patch+json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        contractUpdateAsAdmin,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ContractsApi - functional programming interface
 * @export
 */
export const ContractsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContractsApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates a new contract, adds it to the specified customer and returns it.
     * @summary Add contract
     * @param {string} customerId ID of the customer.
     * @param {ContractAsAdmin} [contractAsAdmin] Request to create a contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addContract(
      customerId: string,
      contractAsAdmin?: ContractAsAdmin,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractAsAdmin>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addContract(
        customerId,
        contractAsAdmin,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.addContract"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Creates a batch of new contracts and adds them to the specified customer.
     * @summary Add contracts
     * @param {string} customerId ID of the customer.
     * @param {AddContractsBatchRequest} [addContractsBatchRequest] Request to create a list of contracts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addContractsBatch(
      customerId: string,
      addContractsBatchRequest?: AddContractsBatchRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addContractsBatch(
        customerId,
        addContractsBatchRequest,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.addContractsBatch"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Deletes the specified contract. Also deletes the customer if no other contracts exist.
     * @summary Delete contract
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteContract(
      customerId: string,
      contractId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContract(
        customerId,
        contractId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.deleteContract"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Deletes the bank account.
     * @summary Delete bank account
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteContractBankAccount(
      customerId: string,
      contractId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContractBankAccount(
        customerId,
        contractId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.deleteContractBankAccount"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Deletes the contract partner.
     * @summary Delete contract partner
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteContractPartner(
      customerId: string,
      contractId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContractPartner(
        customerId,
        contractId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.deleteContractPartner"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Deletes a meter reading.
     * @summary Delete meter reading
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {string} meterReadingId ID of the meter reading.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteMeterReading(
      customerId: string,
      contractId: string,
      meterReadingId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMeterReading(
        customerId,
        contractId,
        meterReadingId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.deleteMeterReading"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Gets a contract by its ID.
     * @summary Get contract
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContract(
      customerId: string,
      contractId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractAsAdmin>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContract(
        customerId,
        contractId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.getContract"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Gets all contracts of the specified customer.
     * @summary Get contracts
     * @param {string} customerId ID of the customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomerContracts(
      customerId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCustomerContracts200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerContracts(
        customerId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.getCustomerContracts"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Creates a meter reading and returns it.
     * @summary Create meter reading
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {DeprecatedMeterReading} [deprecatedMeterReading] Request to add a meter reading.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postMeterReading(
      customerId: string,
      contractId: string,
      deprecatedMeterReading?: DeprecatedMeterReading,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeprecatedMeterReading>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postMeterReading(
        customerId,
        contractId,
        deprecatedMeterReading,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.postMeterReading"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Replaces the bank account.
     * @summary Replace bank account
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {BankAccount} [bankAccount] Request to replace a bank account.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async replaceContractBankAccount(
      customerId: string,
      contractId: string,
      bankAccount?: BankAccount,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAccount>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.replaceContractBankAccount(
          customerId,
          contractId,
          bankAccount,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.replaceContractBankAccount"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Replaces the billing address.
     * @summary Replace billing address
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {Address} [address] Request to replace a billing address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async replaceContractBillingAddress(
      customerId: string,
      contractId: string,
      address?: Address,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.replaceContractBillingAddress(
          customerId,
          contractId,
          address,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.replaceContractBillingAddress"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Replaces the contract partner.
     * @summary Replace contract partner
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {ContractPartner} [contractPartner] Request to replace a contract partner.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async replaceContractPartner(
      customerId: string,
      contractId: string,
      contractPartner?: ContractPartner,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractPartner>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.replaceContractPartner(
        customerId,
        contractId,
        contractPartner,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.replaceContractPartner"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Switches tariff of the specified contract.
     * @summary Switch tariff
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {SwitchTariffRequest} [switchTariffRequest] Request to switch contract to the provided tariff.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async switchTariff(
      customerId: string,
      contractId: string,
      switchTariffRequest?: SwitchTariffRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.switchTariff(
        customerId,
        contractId,
        switchTariffRequest,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.switchTariff"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Adds a termination to the specified contract (i.e. terminates the contract) and returns it.
     * @summary Terminate contract
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {TerminationAsAdmin} [terminationAsAdmin] Request containing a contract termination.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async terminateContract(
      customerId: string,
      contractId: string,
      terminationAsAdmin?: TerminationAsAdmin,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Termination>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.terminateContract(
        customerId,
        contractId,
        terminationAsAdmin,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.terminateContract"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Updates the specified contract.
     * @summary Update contract
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {ContractUpdateAsAdmin} [contractUpdateAsAdmin] Request to update a contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateContract(
      customerId: string,
      contractId: string,
      contractUpdateAsAdmin?: ContractUpdateAsAdmin,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractAsAdmin>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateContract(
        customerId,
        contractId,
        contractUpdateAsAdmin,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContractsApi.updateContract"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ContractsApi - factory interface
 * @export
 */
export const ContractsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContractsApiFp(configuration);
  return {
    /**
     * Creates a new contract, adds it to the specified customer and returns it.
     * @summary Add contract
     * @param {string} customerId ID of the customer.
     * @param {ContractAsAdmin} [contractAsAdmin] Request to create a contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addContract(
      customerId: string,
      contractAsAdmin?: ContractAsAdmin,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ContractAsAdmin> {
      return localVarFp
        .addContract(customerId, contractAsAdmin, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Creates a batch of new contracts and adds them to the specified customer.
     * @summary Add contracts
     * @param {string} customerId ID of the customer.
     * @param {AddContractsBatchRequest} [addContractsBatchRequest] Request to create a list of contracts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addContractsBatch(
      customerId: string,
      addContractsBatchRequest?: AddContractsBatchRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .addContractsBatch(customerId, addContractsBatchRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Deletes the specified contract. Also deletes the customer if no other contracts exist.
     * @summary Delete contract
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteContract(
      customerId: string,
      contractId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteContract(customerId, contractId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Deletes the bank account.
     * @summary Delete bank account
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteContractBankAccount(
      customerId: string,
      contractId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteContractBankAccount(customerId, contractId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Deletes the contract partner.
     * @summary Delete contract partner
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteContractPartner(
      customerId: string,
      contractId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteContractPartner(customerId, contractId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Deletes a meter reading.
     * @summary Delete meter reading
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {string} meterReadingId ID of the meter reading.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMeterReading(
      customerId: string,
      contractId: string,
      meterReadingId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteMeterReading(customerId, contractId, meterReadingId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets a contract by its ID.
     * @summary Get contract
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContract(
      customerId: string,
      contractId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ContractAsAdmin> {
      return localVarFp
        .getContract(customerId, contractId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets all contracts of the specified customer.
     * @summary Get contracts
     * @param {string} customerId ID of the customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerContracts(
      customerId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetCustomerContracts200Response> {
      return localVarFp
        .getCustomerContracts(customerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Creates a meter reading and returns it.
     * @summary Create meter reading
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {DeprecatedMeterReading} [deprecatedMeterReading] Request to add a meter reading.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMeterReading(
      customerId: string,
      contractId: string,
      deprecatedMeterReading?: DeprecatedMeterReading,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DeprecatedMeterReading> {
      return localVarFp
        .postMeterReading(customerId, contractId, deprecatedMeterReading, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Replaces the bank account.
     * @summary Replace bank account
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {BankAccount} [bankAccount] Request to replace a bank account.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    replaceContractBankAccount(
      customerId: string,
      contractId: string,
      bankAccount?: BankAccount,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<BankAccount> {
      return localVarFp
        .replaceContractBankAccount(customerId, contractId, bankAccount, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Replaces the billing address.
     * @summary Replace billing address
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {Address} [address] Request to replace a billing address.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    replaceContractBillingAddress(
      customerId: string,
      contractId: string,
      address?: Address,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Address> {
      return localVarFp
        .replaceContractBillingAddress(customerId, contractId, address, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Replaces the contract partner.
     * @summary Replace contract partner
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {ContractPartner} [contractPartner] Request to replace a contract partner.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    replaceContractPartner(
      customerId: string,
      contractId: string,
      contractPartner?: ContractPartner,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ContractPartner> {
      return localVarFp
        .replaceContractPartner(customerId, contractId, contractPartner, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Switches tariff of the specified contract.
     * @summary Switch tariff
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {SwitchTariffRequest} [switchTariffRequest] Request to switch contract to the provided tariff.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchTariff(
      customerId: string,
      contractId: string,
      switchTariffRequest?: SwitchTariffRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .switchTariff(customerId, contractId, switchTariffRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Adds a termination to the specified contract (i.e. terminates the contract) and returns it.
     * @summary Terminate contract
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {TerminationAsAdmin} [terminationAsAdmin] Request containing a contract termination.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    terminateContract(
      customerId: string,
      contractId: string,
      terminationAsAdmin?: TerminationAsAdmin,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Termination> {
      return localVarFp
        .terminateContract(customerId, contractId, terminationAsAdmin, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates the specified contract.
     * @summary Update contract
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {ContractUpdateAsAdmin} [contractUpdateAsAdmin] Request to update a contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateContract(
      customerId: string,
      contractId: string,
      contractUpdateAsAdmin?: ContractUpdateAsAdmin,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ContractAsAdmin> {
      return localVarFp
        .updateContract(customerId, contractId, contractUpdateAsAdmin, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ContractsApi - object-oriented interface
 * @export
 * @class ContractsApi
 * @extends {BaseAPI}
 */
export class ContractsApi extends BaseAPI {
  /**
   * Creates a new contract, adds it to the specified customer and returns it.
   * @summary Add contract
   * @param {string} customerId ID of the customer.
   * @param {ContractAsAdmin} [contractAsAdmin] Request to create a contract.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public addContract(
    customerId: string,
    contractAsAdmin?: ContractAsAdmin,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .addContract(customerId, contractAsAdmin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creates a batch of new contracts and adds them to the specified customer.
   * @summary Add contracts
   * @param {string} customerId ID of the customer.
   * @param {AddContractsBatchRequest} [addContractsBatchRequest] Request to create a list of contracts.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public addContractsBatch(
    customerId: string,
    addContractsBatchRequest?: AddContractsBatchRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .addContractsBatch(customerId, addContractsBatchRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Deletes the specified contract. Also deletes the customer if no other contracts exist.
   * @summary Delete contract
   * @param {string} customerId ID of the customer.
   * @param {string} contractId ID of the contract.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public deleteContract(
    customerId: string,
    contractId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .deleteContract(customerId, contractId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Deletes the bank account.
   * @summary Delete bank account
   * @param {string} customerId ID of the customer.
   * @param {string} contractId ID of the contract.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public deleteContractBankAccount(
    customerId: string,
    contractId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .deleteContractBankAccount(customerId, contractId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Deletes the contract partner.
   * @summary Delete contract partner
   * @param {string} customerId ID of the customer.
   * @param {string} contractId ID of the contract.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public deleteContractPartner(
    customerId: string,
    contractId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .deleteContractPartner(customerId, contractId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Deletes a meter reading.
   * @summary Delete meter reading
   * @param {string} customerId ID of the customer.
   * @param {string} contractId ID of the contract.
   * @param {string} meterReadingId ID of the meter reading.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public deleteMeterReading(
    customerId: string,
    contractId: string,
    meterReadingId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .deleteMeterReading(customerId, contractId, meterReadingId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets a contract by its ID.
   * @summary Get contract
   * @param {string} customerId ID of the customer.
   * @param {string} contractId ID of the contract.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public getContract(
    customerId: string,
    contractId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .getContract(customerId, contractId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets all contracts of the specified customer.
   * @summary Get contracts
   * @param {string} customerId ID of the customer.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public getCustomerContracts(customerId: string, options?: RawAxiosRequestConfig) {
    return ContractsApiFp(this.configuration)
      .getCustomerContracts(customerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creates a meter reading and returns it.
   * @summary Create meter reading
   * @param {string} customerId ID of the customer.
   * @param {string} contractId ID of the contract.
   * @param {DeprecatedMeterReading} [deprecatedMeterReading] Request to add a meter reading.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public postMeterReading(
    customerId: string,
    contractId: string,
    deprecatedMeterReading?: DeprecatedMeterReading,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .postMeterReading(customerId, contractId, deprecatedMeterReading, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Replaces the bank account.
   * @summary Replace bank account
   * @param {string} customerId ID of the customer.
   * @param {string} contractId ID of the contract.
   * @param {BankAccount} [bankAccount] Request to replace a bank account.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public replaceContractBankAccount(
    customerId: string,
    contractId: string,
    bankAccount?: BankAccount,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .replaceContractBankAccount(customerId, contractId, bankAccount, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Replaces the billing address.
   * @summary Replace billing address
   * @param {string} customerId ID of the customer.
   * @param {string} contractId ID of the contract.
   * @param {Address} [address] Request to replace a billing address.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public replaceContractBillingAddress(
    customerId: string,
    contractId: string,
    address?: Address,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .replaceContractBillingAddress(customerId, contractId, address, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Replaces the contract partner.
   * @summary Replace contract partner
   * @param {string} customerId ID of the customer.
   * @param {string} contractId ID of the contract.
   * @param {ContractPartner} [contractPartner] Request to replace a contract partner.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public replaceContractPartner(
    customerId: string,
    contractId: string,
    contractPartner?: ContractPartner,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .replaceContractPartner(customerId, contractId, contractPartner, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Switches tariff of the specified contract.
   * @summary Switch tariff
   * @param {string} customerId ID of the customer.
   * @param {string} contractId ID of the contract.
   * @param {SwitchTariffRequest} [switchTariffRequest] Request to switch contract to the provided tariff.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public switchTariff(
    customerId: string,
    contractId: string,
    switchTariffRequest?: SwitchTariffRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .switchTariff(customerId, contractId, switchTariffRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Adds a termination to the specified contract (i.e. terminates the contract) and returns it.
   * @summary Terminate contract
   * @param {string} customerId ID of the customer.
   * @param {string} contractId ID of the contract.
   * @param {TerminationAsAdmin} [terminationAsAdmin] Request containing a contract termination.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public terminateContract(
    customerId: string,
    contractId: string,
    terminationAsAdmin?: TerminationAsAdmin,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .terminateContract(customerId, contractId, terminationAsAdmin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates the specified contract.
   * @summary Update contract
   * @param {string} customerId ID of the customer.
   * @param {string} contractId ID of the contract.
   * @param {ContractUpdateAsAdmin} [contractUpdateAsAdmin] Request to update a contract.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContractsApi
   */
  public updateContract(
    customerId: string,
    contractId: string,
    contractUpdateAsAdmin?: ContractUpdateAsAdmin,
    options?: RawAxiosRequestConfig,
  ) {
    return ContractsApiFp(this.configuration)
      .updateContract(customerId, contractId, contractUpdateAsAdmin, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CustomerNumbersApi - axios parameter creator
 * @export
 */
export const CustomerNumbersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Generates new customer numbers.
     * @summary Customer number generation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerNumberGeneration: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/customer-numbers/generation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CustomerNumbersApi - functional programming interface
 * @export
 */
export const CustomerNumbersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CustomerNumbersApiAxiosParamCreator(configuration);
  return {
    /**
     * Generates new customer numbers.
     * @summary Customer number generation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customerNumberGeneration(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.customerNumberGeneration(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomerNumbersApi.customerNumberGeneration"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * CustomerNumbersApi - factory interface
 * @export
 */
export const CustomerNumbersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CustomerNumbersApiFp(configuration);
  return {
    /**
     * Generates new customer numbers.
     * @summary Customer number generation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customerNumberGeneration(options?: RawAxiosRequestConfig): AxiosPromise<void> {
      return localVarFp
        .customerNumberGeneration(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CustomerNumbersApi - object-oriented interface
 * @export
 * @class CustomerNumbersApi
 * @extends {BaseAPI}
 */
export class CustomerNumbersApi extends BaseAPI {
  /**
   * Generates new customer numbers.
   * @summary Customer number generation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerNumbersApi
   */
  public customerNumberGeneration(options?: RawAxiosRequestConfig) {
    return CustomerNumbersApiFp(this.configuration)
      .customerNumberGeneration(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Creates a new customer and returns it.
     * @summary Create customer
     * @param {CustomerWithContractsWithOptionalId} [customerWithContractsWithOptionalId] Request to create a customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCustomer: async (
      customerWithContractsWithOptionalId?: CustomerWithContractsWithOptionalId,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/customers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        customerWithContractsWithOptionalId,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a customer by id
     * @summary Get customer by id
     * @param {string} customerId ID of the customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerById: async (
      customerId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("getCustomerById", "customerId", customerId);
      const localVarPath = `/customers/{customerId}`.replace(
        `{${"customerId"}}`,
        encodeURIComponent(String(customerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all recommendations of a customer
     * @summary Get recommendations of customer
     * @param {string} customerId The id of the customer to retrieve recommendations for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerRecommendations: async (
      customerId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("getCustomerRecommendations", "customerId", customerId);
      const localVarPath = `/customers/{customerId}/recommendations`.replace(
        `{${"customerId"}}`,
        encodeURIComponent(String(customerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Replace email address of a customer.
     * @summary Replace email address
     * @param {string} customerId ID of the customer.
     * @param {EmailAddress} [emailAddress] Request to update an email address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceCustomerEmailAddress: async (
      customerId: string,
      emailAddress?: EmailAddress,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("replaceCustomerEmailAddress", "customerId", customerId);
      const localVarPath = `/customers/{customerId}/email-address`.replace(
        `{${"customerId"}}`,
        encodeURIComponent(String(customerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        emailAddress,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Replaces the labels of a customer.
     * @summary Replace customer labels
     * @param {string} customerId ID of the customer.
     * @param {ReplaceCustomerLabelsRequest} [replaceCustomerLabelsRequest] Request containing a list of label ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceCustomerLabels: async (
      customerId: string,
      replaceCustomerLabelsRequest?: ReplaceCustomerLabelsRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("replaceCustomerLabels", "customerId", customerId);
      const localVarPath = `/customers/{customerId}/labels`.replace(
        `{${"customerId"}}`,
        encodeURIComponent(String(customerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        replaceCustomerLabelsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Replace personal data of a customer.
     * @summary Replace personal data
     * @param {string} customerId ID of the customer.
     * @param {PersonalData} [personalData] Request to update personal data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceCustomerPersonalData: async (
      customerId: string,
      personalData?: PersonalData,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("replaceCustomerPersonalData", "customerId", customerId);
      const localVarPath = `/customers/{customerId}/personal-data`.replace(
        `{${"customerId"}}`,
        encodeURIComponent(String(customerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        personalData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Replace phone number of a customer.
     * @summary Replace phone number
     * @param {string} customerId ID of the customer.
     * @param {PhoneNumber} [phoneNumber] Request to update a phone number.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceCustomerPhoneNumber: async (
      customerId: string,
      phoneNumber?: PhoneNumber,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("replaceCustomerPhoneNumber", "customerId", customerId);
      const localVarPath = `/customers/{customerId}/phone-number`.replace(
        `{${"customerId"}}`,
        encodeURIComponent(String(customerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        phoneNumber,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Reset the customer password and return the new password.
     * @summary Reset password
     * @param {string} customerId ID of the customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetCustomerPassword: async (
      customerId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("resetCustomerPassword", "customerId", customerId);
      const localVarPath = `/customers/{customerId}/reset-password`.replace(
        `{${"customerId"}}`,
        encodeURIComponent(String(customerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Search for customers
     * @summary Search customers
     * @param {string} query The search query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCustomers: async (
      query: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists("searchCustomers", "query", query);
      const localVarPath = `/customers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      if (query !== undefined) {
        localVarQueryParameter["query"] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Adds a transfer of contracts to a customer.
     * @summary Transfer contracts
     * @param {string} customerId ID of the customer.
     * @param {Transfer} [transfer] Request to transfer a contract
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferCustomerContract: async (
      customerId: string,
      transfer?: Transfer,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("transferCustomerContract", "customerId", customerId);
      const localVarPath = `/customers/{customerId}/transfers`.replace(
        `{${"customerId"}}`,
        encodeURIComponent(String(customerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transfer,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates a new customer and returns it.
     * @summary Create customer
     * @param {CustomerWithContractsWithOptionalId} [customerWithContractsWithOptionalId] Request to create a customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCustomer(
      customerWithContractsWithOptionalId?: CustomerWithContractsWithOptionalId,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerWithContracts>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomer(
        customerWithContractsWithOptionalId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomersApi.createCustomer"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get a customer by id
     * @summary Get customer by id
     * @param {string} customerId ID of the customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomerById(
      customerId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerWithLabels>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerById(
        customerId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomersApi.getCustomerById"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get all recommendations of a customer
     * @summary Get recommendations of customer
     * @param {string} customerId The id of the customer to retrieve recommendations for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomerRecommendations(
      customerId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCustomerRecommendations200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCustomerRecommendations(customerId, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomersApi.getCustomerRecommendations"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Replace email address of a customer.
     * @summary Replace email address
     * @param {string} customerId ID of the customer.
     * @param {EmailAddress} [emailAddress] Request to update an email address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async replaceCustomerEmailAddress(
      customerId: string,
      emailAddress?: EmailAddress,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailAddress>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.replaceCustomerEmailAddress(
          customerId,
          emailAddress,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomersApi.replaceCustomerEmailAddress"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Replaces the labels of a customer.
     * @summary Replace customer labels
     * @param {string} customerId ID of the customer.
     * @param {ReplaceCustomerLabelsRequest} [replaceCustomerLabelsRequest] Request containing a list of label ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async replaceCustomerLabels(
      customerId: string,
      replaceCustomerLabelsRequest?: ReplaceCustomerLabelsRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ReplaceCustomerLabelsRequest>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.replaceCustomerLabels(
        customerId,
        replaceCustomerLabelsRequest,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomersApi.replaceCustomerLabels"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Replace personal data of a customer.
     * @summary Replace personal data
     * @param {string} customerId ID of the customer.
     * @param {PersonalData} [personalData] Request to update personal data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async replaceCustomerPersonalData(
      customerId: string,
      personalData?: PersonalData,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonalData>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.replaceCustomerPersonalData(
          customerId,
          personalData,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomersApi.replaceCustomerPersonalData"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Replace phone number of a customer.
     * @summary Replace phone number
     * @param {string} customerId ID of the customer.
     * @param {PhoneNumber} [phoneNumber] Request to update a phone number.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async replaceCustomerPhoneNumber(
      customerId: string,
      phoneNumber?: PhoneNumber,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhoneNumber>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.replaceCustomerPhoneNumber(
          customerId,
          phoneNumber,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomersApi.replaceCustomerPhoneNumber"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Reset the customer password and return the new password.
     * @summary Reset password
     * @param {string} customerId ID of the customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetCustomerPassword(
      customerId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultPassword>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetCustomerPassword(
        customerId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomersApi.resetCustomerPassword"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Search for customers
     * @summary Search customers
     * @param {string} query The search query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchCustomers(
      query: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SearchCustomers200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchCustomers(
        query,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomersApi.searchCustomers"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Adds a transfer of contracts to a customer.
     * @summary Transfer contracts
     * @param {string} customerId ID of the customer.
     * @param {Transfer} [transfer] Request to transfer a contract
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transferCustomerContract(
      customerId: string,
      transfer?: Transfer,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transfer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transferCustomerContract(
        customerId,
        transfer,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomersApi.transferCustomerContract"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CustomersApiFp(configuration);
  return {
    /**
     * Creates a new customer and returns it.
     * @summary Create customer
     * @param {CustomerWithContractsWithOptionalId} [customerWithContractsWithOptionalId] Request to create a customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCustomer(
      customerWithContractsWithOptionalId?: CustomerWithContractsWithOptionalId,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CustomerWithContracts> {
      return localVarFp
        .createCustomer(customerWithContractsWithOptionalId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a customer by id
     * @summary Get customer by id
     * @param {string} customerId ID of the customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerById(
      customerId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CustomerWithLabels> {
      return localVarFp
        .getCustomerById(customerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all recommendations of a customer
     * @summary Get recommendations of customer
     * @param {string} customerId The id of the customer to retrieve recommendations for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerRecommendations(
      customerId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetCustomerRecommendations200Response> {
      return localVarFp
        .getCustomerRecommendations(customerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Replace email address of a customer.
     * @summary Replace email address
     * @param {string} customerId ID of the customer.
     * @param {EmailAddress} [emailAddress] Request to update an email address.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceCustomerEmailAddress(
      customerId: string,
      emailAddress?: EmailAddress,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<EmailAddress> {
      return localVarFp
        .replaceCustomerEmailAddress(customerId, emailAddress, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Replaces the labels of a customer.
     * @summary Replace customer labels
     * @param {string} customerId ID of the customer.
     * @param {ReplaceCustomerLabelsRequest} [replaceCustomerLabelsRequest] Request containing a list of label ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceCustomerLabels(
      customerId: string,
      replaceCustomerLabelsRequest?: ReplaceCustomerLabelsRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ReplaceCustomerLabelsRequest> {
      return localVarFp
        .replaceCustomerLabels(customerId, replaceCustomerLabelsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Replace personal data of a customer.
     * @summary Replace personal data
     * @param {string} customerId ID of the customer.
     * @param {PersonalData} [personalData] Request to update personal data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceCustomerPersonalData(
      customerId: string,
      personalData?: PersonalData,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PersonalData> {
      return localVarFp
        .replaceCustomerPersonalData(customerId, personalData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Replace phone number of a customer.
     * @summary Replace phone number
     * @param {string} customerId ID of the customer.
     * @param {PhoneNumber} [phoneNumber] Request to update a phone number.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceCustomerPhoneNumber(
      customerId: string,
      phoneNumber?: PhoneNumber,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PhoneNumber> {
      return localVarFp
        .replaceCustomerPhoneNumber(customerId, phoneNumber, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Reset the customer password and return the new password.
     * @summary Reset password
     * @param {string} customerId ID of the customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetCustomerPassword(
      customerId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DefaultPassword> {
      return localVarFp
        .resetCustomerPassword(customerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Search for customers
     * @summary Search customers
     * @param {string} query The search query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCustomers(
      query: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SearchCustomers200Response> {
      return localVarFp
        .searchCustomers(query, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Adds a transfer of contracts to a customer.
     * @summary Transfer contracts
     * @param {string} customerId ID of the customer.
     * @param {Transfer} [transfer] Request to transfer a contract
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferCustomerContract(
      customerId: string,
      transfer?: Transfer,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Transfer> {
      return localVarFp
        .transferCustomerContract(customerId, transfer, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
  /**
   * Creates a new customer and returns it.
   * @summary Create customer
   * @param {CustomerWithContractsWithOptionalId} [customerWithContractsWithOptionalId] Request to create a customer.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public createCustomer(
    customerWithContractsWithOptionalId?: CustomerWithContractsWithOptionalId,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomersApiFp(this.configuration)
      .createCustomer(customerWithContractsWithOptionalId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a customer by id
   * @summary Get customer by id
   * @param {string} customerId ID of the customer.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public getCustomerById(customerId: string, options?: RawAxiosRequestConfig) {
    return CustomersApiFp(this.configuration)
      .getCustomerById(customerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all recommendations of a customer
   * @summary Get recommendations of customer
   * @param {string} customerId The id of the customer to retrieve recommendations for.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public getCustomerRecommendations(customerId: string, options?: RawAxiosRequestConfig) {
    return CustomersApiFp(this.configuration)
      .getCustomerRecommendations(customerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Replace email address of a customer.
   * @summary Replace email address
   * @param {string} customerId ID of the customer.
   * @param {EmailAddress} [emailAddress] Request to update an email address.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public replaceCustomerEmailAddress(
    customerId: string,
    emailAddress?: EmailAddress,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomersApiFp(this.configuration)
      .replaceCustomerEmailAddress(customerId, emailAddress, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Replaces the labels of a customer.
   * @summary Replace customer labels
   * @param {string} customerId ID of the customer.
   * @param {ReplaceCustomerLabelsRequest} [replaceCustomerLabelsRequest] Request containing a list of label ids.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public replaceCustomerLabels(
    customerId: string,
    replaceCustomerLabelsRequest?: ReplaceCustomerLabelsRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomersApiFp(this.configuration)
      .replaceCustomerLabels(customerId, replaceCustomerLabelsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Replace personal data of a customer.
   * @summary Replace personal data
   * @param {string} customerId ID of the customer.
   * @param {PersonalData} [personalData] Request to update personal data.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public replaceCustomerPersonalData(
    customerId: string,
    personalData?: PersonalData,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomersApiFp(this.configuration)
      .replaceCustomerPersonalData(customerId, personalData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Replace phone number of a customer.
   * @summary Replace phone number
   * @param {string} customerId ID of the customer.
   * @param {PhoneNumber} [phoneNumber] Request to update a phone number.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public replaceCustomerPhoneNumber(
    customerId: string,
    phoneNumber?: PhoneNumber,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomersApiFp(this.configuration)
      .replaceCustomerPhoneNumber(customerId, phoneNumber, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Reset the customer password and return the new password.
   * @summary Reset password
   * @param {string} customerId ID of the customer.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public resetCustomerPassword(customerId: string, options?: RawAxiosRequestConfig) {
    return CustomersApiFp(this.configuration)
      .resetCustomerPassword(customerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Search for customers
   * @summary Search customers
   * @param {string} query The search query
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public searchCustomers(query: string, options?: RawAxiosRequestConfig) {
    return CustomersApiFp(this.configuration)
      .searchCustomers(query, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Adds a transfer of contracts to a customer.
   * @summary Transfer contracts
   * @param {string} customerId ID of the customer.
   * @param {Transfer} [transfer] Request to transfer a contract
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public transferCustomerContract(
    customerId: string,
    transfer?: Transfer,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomersApiFp(this.configuration)
      .transferCustomerContract(customerId, transfer, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get document for id.
     * @summary Get document
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {string} documentId ID of the document.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocument: async (
      customerId: string,
      contractId: string,
      documentId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("getDocument", "customerId", customerId);
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("getDocument", "contractId", contractId);
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists("getDocument", "documentId", documentId);
      const localVarPath =
        `/customers/{customerId}/contracts/{contractId}/documents/{documentId}`
          .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
          .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
          .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get documents for a contract.
     * @summary Get documents
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocuments: async (
      customerId: string,
      contractId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists("getDocuments", "customerId", customerId);
      // verify required parameter 'contractId' is not null or undefined
      assertParamExists("getDocuments", "contractId", contractId);
      const localVarPath = `/customers/{customerId}/contracts/{contractId}/documents`
        .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
        .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get document for id.
     * @summary Get document
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {string} documentId ID of the document.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDocument(
      customerId: string,
      contractId: string,
      documentId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(
        customerId,
        contractId,
        documentId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DocumentsApi.getDocument"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get documents for a contract.
     * @summary Get documents
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDocuments(
      customerId: string,
      contractId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocuments200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDocuments(
        customerId,
        contractId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DocumentsApi.getDocuments"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DocumentsApiFp(configuration);
  return {
    /**
     * Get document for id.
     * @summary Get document
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {string} documentId ID of the document.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocument(
      customerId: string,
      contractId: string,
      documentId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Document> {
      return localVarFp
        .getDocument(customerId, contractId, documentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get documents for a contract.
     * @summary Get documents
     * @param {string} customerId ID of the customer.
     * @param {string} contractId ID of the contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocuments(
      customerId: string,
      contractId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetDocuments200Response> {
      return localVarFp
        .getDocuments(customerId, contractId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
  /**
   * Get document for id.
   * @summary Get document
   * @param {string} customerId ID of the customer.
   * @param {string} contractId ID of the contract.
   * @param {string} documentId ID of the document.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public getDocument(
    customerId: string,
    contractId: string,
    documentId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return DocumentsApiFp(this.configuration)
      .getDocument(customerId, contractId, documentId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get documents for a contract.
   * @summary Get documents
   * @param {string} customerId ID of the customer.
   * @param {string} contractId ID of the contract.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public getDocuments(
    customerId: string,
    contractId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return DocumentsApiFp(this.configuration)
      .getDocuments(customerId, contractId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Runs the job to update contracts
     * @summary Run Contract Update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runContractUpdate: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/jobs/update-contracts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Runs the job to sync customers to the newsletter service
     * @summary Run Newsletter Service Sync
     * @param {RunNewsletterServiceSyncRequest} [runNewsletterServiceSyncRequest] Request containing a customer number range.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runNewsletterServiceSync: async (
      runNewsletterServiceSyncRequest?: RunNewsletterServiceSyncRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/jobs/newsletter-service-sync`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        runNewsletterServiceSyncRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Switch tenant power customers to postal mailing, in case they didn\'t verify their email address within three weeks since registration.
     * @summary Switch Tenant Power Customers To Postal Mailing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchTenantPowerCustomersToPostalMailing: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/jobs/switch-tenant-power-customers-to-postal-mailing`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = JobsApiAxiosParamCreator(configuration);
  return {
    /**
     * Runs the job to update contracts
     * @summary Run Contract Update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runContractUpdate(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RunContractUpdate200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.runContractUpdate(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["JobsApi.runContractUpdate"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Runs the job to sync customers to the newsletter service
     * @summary Run Newsletter Service Sync
     * @param {RunNewsletterServiceSyncRequest} [runNewsletterServiceSyncRequest] Request containing a customer number range.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runNewsletterServiceSync(
      runNewsletterServiceSyncRequest?: RunNewsletterServiceSyncRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RunNewsletterServiceSync200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.runNewsletterServiceSync(
        runNewsletterServiceSyncRequest,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["JobsApi.runNewsletterServiceSync"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Switch tenant power customers to postal mailing, in case they didn\'t verify their email address within three weeks since registration.
     * @summary Switch Tenant Power Customers To Postal Mailing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async switchTenantPowerCustomersToPostalMailing(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.switchTenantPowerCustomersToPostalMailing(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["JobsApi.switchTenantPowerCustomersToPostalMailing"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = JobsApiFp(configuration);
  return {
    /**
     * Runs the job to update contracts
     * @summary Run Contract Update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runContractUpdate(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<RunContractUpdate200Response> {
      return localVarFp
        .runContractUpdate(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Runs the job to sync customers to the newsletter service
     * @summary Run Newsletter Service Sync
     * @param {RunNewsletterServiceSyncRequest} [runNewsletterServiceSyncRequest] Request containing a customer number range.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runNewsletterServiceSync(
      runNewsletterServiceSyncRequest?: RunNewsletterServiceSyncRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<RunNewsletterServiceSync200Response> {
      return localVarFp
        .runNewsletterServiceSync(runNewsletterServiceSyncRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Switch tenant power customers to postal mailing, in case they didn\'t verify their email address within three weeks since registration.
     * @summary Switch Tenant Power Customers To Postal Mailing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchTenantPowerCustomersToPostalMailing(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .switchTenantPowerCustomersToPostalMailing(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
  /**
   * Runs the job to update contracts
   * @summary Run Contract Update
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public runContractUpdate(options?: RawAxiosRequestConfig) {
    return JobsApiFp(this.configuration)
      .runContractUpdate(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Runs the job to sync customers to the newsletter service
   * @summary Run Newsletter Service Sync
   * @param {RunNewsletterServiceSyncRequest} [runNewsletterServiceSyncRequest] Request containing a customer number range.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public runNewsletterServiceSync(
    runNewsletterServiceSyncRequest?: RunNewsletterServiceSyncRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .runNewsletterServiceSync(runNewsletterServiceSyncRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Switch tenant power customers to postal mailing, in case they didn\'t verify their email address within three weeks since registration.
   * @summary Switch Tenant Power Customers To Postal Mailing
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public switchTenantPowerCustomersToPostalMailing(options?: RawAxiosRequestConfig) {
    return JobsApiFp(this.configuration)
      .switchTenantPowerCustomersToPostalMailing(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * LabelsApi - axios parameter creator
 * @export
 */
export const LabelsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Deletes the label.
     * @summary Delete label
     * @param {string} labelId ID of a label.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLabel: async (
      labelId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'labelId' is not null or undefined
      assertParamExists("deleteLabel", "labelId", labelId);
      const localVarPath = `/labels/{labelId}`.replace(
        `{${"labelId"}}`,
        encodeURIComponent(String(labelId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a list of all labels.
     * @summary Get all labels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabels: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/labels`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add a new label or update an existing one.
     * @summary Add or update label
     * @param {Label} [label] Request containing a label.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putLabel: async (
      label?: Label,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/labels`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        label,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LabelsApi - functional programming interface
 * @export
 */
export const LabelsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LabelsApiAxiosParamCreator(configuration);
  return {
    /**
     * Deletes the label.
     * @summary Delete label
     * @param {string} labelId ID of a label.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteLabel(
      labelId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLabel(
        labelId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["LabelsApi.deleteLabel"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get a list of all labels.
     * @summary Get all labels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLabels(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLabels200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLabels(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["LabelsApi.getLabels"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Add a new label or update an existing one.
     * @summary Add or update label
     * @param {Label} [label] Request containing a label.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putLabel(
      label?: Label,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Label>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putLabel(label, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["LabelsApi.putLabel"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * LabelsApi - factory interface
 * @export
 */
export const LabelsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LabelsApiFp(configuration);
  return {
    /**
     * Deletes the label.
     * @summary Delete label
     * @param {string} labelId ID of a label.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLabel(labelId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
      return localVarFp
        .deleteLabel(labelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a list of all labels.
     * @summary Get all labels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabels(options?: RawAxiosRequestConfig): AxiosPromise<GetLabels200Response> {
      return localVarFp.getLabels(options).then((request) => request(axios, basePath));
    },
    /**
     * Add a new label or update an existing one.
     * @summary Add or update label
     * @param {Label} [label] Request containing a label.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putLabel(label?: Label, options?: RawAxiosRequestConfig): AxiosPromise<Label> {
      return localVarFp
        .putLabel(label, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * LabelsApi - object-oriented interface
 * @export
 * @class LabelsApi
 * @extends {BaseAPI}
 */
export class LabelsApi extends BaseAPI {
  /**
   * Deletes the label.
   * @summary Delete label
   * @param {string} labelId ID of a label.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public deleteLabel(labelId: string, options?: RawAxiosRequestConfig) {
    return LabelsApiFp(this.configuration)
      .deleteLabel(labelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a list of all labels.
   * @summary Get all labels
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public getLabels(options?: RawAxiosRequestConfig) {
    return LabelsApiFp(this.configuration)
      .getLabels(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add a new label or update an existing one.
   * @summary Add or update label
   * @param {Label} [label] Request containing a label.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public putLabel(label?: Label, options?: RawAxiosRequestConfig) {
    return LabelsApiFp(this.configuration)
      .putLabel(label, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MeasuringPointsApi - axios parameter creator
 * @export
 */
export const MeasuringPointsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Creates a batch of new measuring points and adds them to the specified project.
     * @summary Add measuring points
     * @param {string} projectId ID of a tenant power project
     * @param {AddMeasuringPointsBatchRequest} [addMeasuringPointsBatchRequest] Request to create a list of measuring points.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addMeasuringPointsBatch: async (
      projectId: string,
      addMeasuringPointsBatchRequest?: AddMeasuringPointsBatchRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("addMeasuringPointsBatch", "projectId", projectId);
      const localVarPath =
        `/tenant-power/projects/{projectId}/measuring-points/batch`.replace(
          `{${"projectId"}}`,
          encodeURIComponent(String(projectId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addMeasuringPointsBatchRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Adds a meter reading to a meter and returns it.
     * @summary Add meter reading
     * @param {string} meterId ID of a meter
     * @param {MeterReading} [meterReading] Request to add a meter reading.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addMeterReading: async (
      meterId: string,
      meterReading?: MeterReading,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'meterId' is not null or undefined
      assertParamExists("addMeterReading", "meterId", meterId);
      const localVarPath = `/meters/{meterId}/readings`.replace(
        `{${"meterId"}}`,
        encodeURIComponent(String(meterId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        meterReading,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets a measuring point by its ID and returns it with a meter and a contract history.
     * @summary Get measuring point
     * @param {string} projectId ID of a tenant power project
     * @param {string} measuringPointId ID of a measuring point
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMeasuringPoint: async (
      projectId: string,
      measuringPointId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getMeasuringPoint", "projectId", projectId);
      // verify required parameter 'measuringPointId' is not null or undefined
      assertParamExists("getMeasuringPoint", "measuringPointId", measuringPointId);
      const localVarPath =
        `/tenant-power/projects/{projectId}/measuring-points/{measuringPointId}`
          .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
          .replace(
            `{${"measuringPointId"}}`,
            encodeURIComponent(String(measuringPointId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets a list of all measuring points with type `sub` for the tenant power project.
     * @summary Get measuring points
     * @param {string} projectId ID of a tenant power project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMeasuringPoints: async (
      projectId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getMeasuringPoints", "projectId", projectId);
      const localVarPath = `/tenant-power/projects/{projectId}/measuring-points`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates a meter and adds it to the specified measuring point. In case the measuring point is already having a meter, the existing meter will be replaced but will be kept in the meter history.
     * @summary Switch meter
     * @param {string} projectId ID of a tenant power project
     * @param {string} measuringPointId ID of a measuring point
     * @param {InitialMeterInstallation} [initialMeterInstallation] Request to add a meter to a measuring point.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchMeter: async (
      projectId: string,
      measuringPointId: string,
      initialMeterInstallation?: InitialMeterInstallation,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("switchMeter", "projectId", projectId);
      // verify required parameter 'measuringPointId' is not null or undefined
      assertParamExists("switchMeter", "measuringPointId", measuringPointId);
      const localVarPath =
        `/tenant-power/projects/{projectId}/measuring-points/{measuringPointId}/meters`
          .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
          .replace(
            `{${"measuringPointId"}}`,
            encodeURIComponent(String(measuringPointId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        initialMeterInstallation,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates or updates a preliminary contract of measuring point
     * @summary Create or update preliminary contract
     * @param {string} projectId ID of a tenant power project
     * @param {string} measuringPointId ID of a measuring point
     * @param {UpsertPreliminaryContractRequest} [upsertPreliminaryContractRequest] Request to create or update a preliminary contract of a measuring point.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertPreliminaryContract: async (
      projectId: string,
      measuringPointId: string,
      upsertPreliminaryContractRequest?: UpsertPreliminaryContractRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("upsertPreliminaryContract", "projectId", projectId);
      // verify required parameter 'measuringPointId' is not null or undefined
      assertParamExists(
        "upsertPreliminaryContract",
        "measuringPointId",
        measuringPointId,
      );
      const localVarPath =
        `/tenant-power/projects/{projectId}/measuring-points/{measuringPointId}`
          .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
          .replace(
            `{${"measuringPointId"}}`,
            encodeURIComponent(String(measuringPointId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        upsertPreliminaryContractRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MeasuringPointsApi - functional programming interface
 * @export
 */
export const MeasuringPointsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MeasuringPointsApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates a batch of new measuring points and adds them to the specified project.
     * @summary Add measuring points
     * @param {string} projectId ID of a tenant power project
     * @param {AddMeasuringPointsBatchRequest} [addMeasuringPointsBatchRequest] Request to create a list of measuring points.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addMeasuringPointsBatch(
      projectId: string,
      addMeasuringPointsBatchRequest?: AddMeasuringPointsBatchRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addMeasuringPointsBatch(
        projectId,
        addMeasuringPointsBatchRequest,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MeasuringPointsApi.addMeasuringPointsBatch"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Adds a meter reading to a meter and returns it.
     * @summary Add meter reading
     * @param {string} meterId ID of a meter
     * @param {MeterReading} [meterReading] Request to add a meter reading.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addMeterReading(
      meterId: string,
      meterReading?: MeterReading,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeterReading>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addMeterReading(
        meterId,
        meterReading,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MeasuringPointsApi.addMeterReading"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Gets a measuring point by its ID and returns it with a meter and a contract history.
     * @summary Get measuring point
     * @param {string} projectId ID of a tenant power project
     * @param {string} measuringPointId ID of a measuring point
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMeasuringPoint(
      projectId: string,
      measuringPointId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MeasuringPointWithMeterAsAdmin>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasuringPoint(
        projectId,
        measuringPointId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MeasuringPointsApi.getMeasuringPoint"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Gets a list of all measuring points with type `sub` for the tenant power project.
     * @summary Get measuring points
     * @param {string} projectId ID of a tenant power project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMeasuringPoints(
      projectId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetMeasuringPoints200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasuringPoints(
        projectId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MeasuringPointsApi.getMeasuringPoints"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Creates a meter and adds it to the specified measuring point. In case the measuring point is already having a meter, the existing meter will be replaced but will be kept in the meter history.
     * @summary Switch meter
     * @param {string} projectId ID of a tenant power project
     * @param {string} measuringPointId ID of a measuring point
     * @param {InitialMeterInstallation} [initialMeterInstallation] Request to add a meter to a measuring point.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async switchMeter(
      projectId: string,
      measuringPointId: string,
      initialMeterInstallation?: InitialMeterInstallation,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Meter>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.switchMeter(
        projectId,
        measuringPointId,
        initialMeterInstallation,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MeasuringPointsApi.switchMeter"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Creates or updates a preliminary contract of measuring point
     * @summary Create or update preliminary contract
     * @param {string} projectId ID of a tenant power project
     * @param {string} measuringPointId ID of a measuring point
     * @param {UpsertPreliminaryContractRequest} [upsertPreliminaryContractRequest] Request to create or update a preliminary contract of a measuring point.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsertPreliminaryContract(
      projectId: string,
      measuringPointId: string,
      upsertPreliminaryContractRequest?: UpsertPreliminaryContractRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upsertPreliminaryContract(
        projectId,
        measuringPointId,
        upsertPreliminaryContractRequest,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MeasuringPointsApi.upsertPreliminaryContract"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * MeasuringPointsApi - factory interface
 * @export
 */
export const MeasuringPointsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MeasuringPointsApiFp(configuration);
  return {
    /**
     * Creates a batch of new measuring points and adds them to the specified project.
     * @summary Add measuring points
     * @param {string} projectId ID of a tenant power project
     * @param {AddMeasuringPointsBatchRequest} [addMeasuringPointsBatchRequest] Request to create a list of measuring points.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addMeasuringPointsBatch(
      projectId: string,
      addMeasuringPointsBatchRequest?: AddMeasuringPointsBatchRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .addMeasuringPointsBatch(projectId, addMeasuringPointsBatchRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Adds a meter reading to a meter and returns it.
     * @summary Add meter reading
     * @param {string} meterId ID of a meter
     * @param {MeterReading} [meterReading] Request to add a meter reading.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addMeterReading(
      meterId: string,
      meterReading?: MeterReading,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MeterReading> {
      return localVarFp
        .addMeterReading(meterId, meterReading, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets a measuring point by its ID and returns it with a meter and a contract history.
     * @summary Get measuring point
     * @param {string} projectId ID of a tenant power project
     * @param {string} measuringPointId ID of a measuring point
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMeasuringPoint(
      projectId: string,
      measuringPointId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MeasuringPointWithMeterAsAdmin> {
      return localVarFp
        .getMeasuringPoint(projectId, measuringPointId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets a list of all measuring points with type `sub` for the tenant power project.
     * @summary Get measuring points
     * @param {string} projectId ID of a tenant power project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMeasuringPoints(
      projectId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetMeasuringPoints200Response> {
      return localVarFp
        .getMeasuringPoints(projectId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Creates a meter and adds it to the specified measuring point. In case the measuring point is already having a meter, the existing meter will be replaced but will be kept in the meter history.
     * @summary Switch meter
     * @param {string} projectId ID of a tenant power project
     * @param {string} measuringPointId ID of a measuring point
     * @param {InitialMeterInstallation} [initialMeterInstallation] Request to add a meter to a measuring point.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchMeter(
      projectId: string,
      measuringPointId: string,
      initialMeterInstallation?: InitialMeterInstallation,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Meter> {
      return localVarFp
        .switchMeter(projectId, measuringPointId, initialMeterInstallation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Creates or updates a preliminary contract of measuring point
     * @summary Create or update preliminary contract
     * @param {string} projectId ID of a tenant power project
     * @param {string} measuringPointId ID of a measuring point
     * @param {UpsertPreliminaryContractRequest} [upsertPreliminaryContractRequest] Request to create or update a preliminary contract of a measuring point.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertPreliminaryContract(
      projectId: string,
      measuringPointId: string,
      upsertPreliminaryContractRequest?: UpsertPreliminaryContractRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .upsertPreliminaryContract(
          projectId,
          measuringPointId,
          upsertPreliminaryContractRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MeasuringPointsApi - object-oriented interface
 * @export
 * @class MeasuringPointsApi
 * @extends {BaseAPI}
 */
export class MeasuringPointsApi extends BaseAPI {
  /**
   * Creates a batch of new measuring points and adds them to the specified project.
   * @summary Add measuring points
   * @param {string} projectId ID of a tenant power project
   * @param {AddMeasuringPointsBatchRequest} [addMeasuringPointsBatchRequest] Request to create a list of measuring points.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeasuringPointsApi
   */
  public addMeasuringPointsBatch(
    projectId: string,
    addMeasuringPointsBatchRequest?: AddMeasuringPointsBatchRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MeasuringPointsApiFp(this.configuration)
      .addMeasuringPointsBatch(projectId, addMeasuringPointsBatchRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Adds a meter reading to a meter and returns it.
   * @summary Add meter reading
   * @param {string} meterId ID of a meter
   * @param {MeterReading} [meterReading] Request to add a meter reading.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeasuringPointsApi
   */
  public addMeterReading(
    meterId: string,
    meterReading?: MeterReading,
    options?: RawAxiosRequestConfig,
  ) {
    return MeasuringPointsApiFp(this.configuration)
      .addMeterReading(meterId, meterReading, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets a measuring point by its ID and returns it with a meter and a contract history.
   * @summary Get measuring point
   * @param {string} projectId ID of a tenant power project
   * @param {string} measuringPointId ID of a measuring point
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeasuringPointsApi
   */
  public getMeasuringPoint(
    projectId: string,
    measuringPointId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return MeasuringPointsApiFp(this.configuration)
      .getMeasuringPoint(projectId, measuringPointId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets a list of all measuring points with type `sub` for the tenant power project.
   * @summary Get measuring points
   * @param {string} projectId ID of a tenant power project
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeasuringPointsApi
   */
  public getMeasuringPoints(projectId: string, options?: RawAxiosRequestConfig) {
    return MeasuringPointsApiFp(this.configuration)
      .getMeasuringPoints(projectId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creates a meter and adds it to the specified measuring point. In case the measuring point is already having a meter, the existing meter will be replaced but will be kept in the meter history.
   * @summary Switch meter
   * @param {string} projectId ID of a tenant power project
   * @param {string} measuringPointId ID of a measuring point
   * @param {InitialMeterInstallation} [initialMeterInstallation] Request to add a meter to a measuring point.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeasuringPointsApi
   */
  public switchMeter(
    projectId: string,
    measuringPointId: string,
    initialMeterInstallation?: InitialMeterInstallation,
    options?: RawAxiosRequestConfig,
  ) {
    return MeasuringPointsApiFp(this.configuration)
      .switchMeter(projectId, measuringPointId, initialMeterInstallation, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creates or updates a preliminary contract of measuring point
   * @summary Create or update preliminary contract
   * @param {string} projectId ID of a tenant power project
   * @param {string} measuringPointId ID of a measuring point
   * @param {UpsertPreliminaryContractRequest} [upsertPreliminaryContractRequest] Request to create or update a preliminary contract of a measuring point.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeasuringPointsApi
   */
  public upsertPreliminaryContract(
    projectId: string,
    measuringPointId: string,
    upsertPreliminaryContractRequest?: UpsertPreliminaryContractRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MeasuringPointsApiFp(this.configuration)
      .upsertPreliminaryContract(
        projectId,
        measuringPointId,
        upsertPreliminaryContractRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TariffsApi - axios parameter creator
 * @export
 */
export const TariffsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Gets a list of tariffs based on the provided query, ordered by its validity starting with the newest tariff.
     * @summary Get tariffs
     * @param {TariffType} type The type of tariffs to retrieve.
     * @param {string} zipCode The ZIP Code to retrieve tariffs for.
     * @param {number} annualConsumption The annual consumption to retrieve tariffs for.
     * @param {string} [partnerId] The partner id to retrieve tariffs for. If not given, tariffs with partnerId are filtered out.
     * @param {number} [annualConsumptionLowTariff] The annual consumption to retrieve low tariffs for.
     * @param {CustomerType} [applicableForCustomerType] The customer type to retrieve tariffs for. If not set, tariffs applicable for all customer types are returned.
     * @param {string} [requestedBeginAt] The requested supply date to retrieve tariffs for.
     * @param {ForecastBasis} [forecastBasis] The forecast basis to retrieve tariffs for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTariffs: async (
      type: TariffType,
      zipCode: string,
      annualConsumption: number,
      partnerId?: string,
      annualConsumptionLowTariff?: number,
      applicableForCustomerType?: CustomerType,
      requestedBeginAt?: string,
      forecastBasis?: ForecastBasis,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists("getTariffs", "type", type);
      // verify required parameter 'zipCode' is not null or undefined
      assertParamExists("getTariffs", "zipCode", zipCode);
      // verify required parameter 'annualConsumption' is not null or undefined
      assertParamExists("getTariffs", "annualConsumption", annualConsumption);
      const localVarPath = `/tariffs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (zipCode !== undefined) {
        localVarQueryParameter["zipCode"] = zipCode;
      }

      if (partnerId !== undefined) {
        localVarQueryParameter["partnerId"] = partnerId;
      }

      if (annualConsumption !== undefined) {
        localVarQueryParameter["annualConsumption"] = annualConsumption;
      }

      if (annualConsumptionLowTariff !== undefined) {
        localVarQueryParameter["annualConsumptionLowTariff"] = annualConsumptionLowTariff;
      }

      if (applicableForCustomerType !== undefined) {
        localVarQueryParameter["applicableForCustomerType"] = applicableForCustomerType;
      }

      if (requestedBeginAt !== undefined) {
        localVarQueryParameter["requestedBeginAt"] =
          (requestedBeginAt as any) instanceof Date
            ? (requestedBeginAt as any).toISOString().substring(0, 10)
            : requestedBeginAt;
      }

      if (forecastBasis !== undefined) {
        localVarQueryParameter["forecastBasis"] = forecastBasis;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TariffsApi - functional programming interface
 * @export
 */
export const TariffsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TariffsApiAxiosParamCreator(configuration);
  return {
    /**
     * Gets a list of tariffs based on the provided query, ordered by its validity starting with the newest tariff.
     * @summary Get tariffs
     * @param {TariffType} type The type of tariffs to retrieve.
     * @param {string} zipCode The ZIP Code to retrieve tariffs for.
     * @param {number} annualConsumption The annual consumption to retrieve tariffs for.
     * @param {string} [partnerId] The partner id to retrieve tariffs for. If not given, tariffs with partnerId are filtered out.
     * @param {number} [annualConsumptionLowTariff] The annual consumption to retrieve low tariffs for.
     * @param {CustomerType} [applicableForCustomerType] The customer type to retrieve tariffs for. If not set, tariffs applicable for all customer types are returned.
     * @param {string} [requestedBeginAt] The requested supply date to retrieve tariffs for.
     * @param {ForecastBasis} [forecastBasis] The forecast basis to retrieve tariffs for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTariffs(
      type: TariffType,
      zipCode: string,
      annualConsumption: number,
      partnerId?: string,
      annualConsumptionLowTariff?: number,
      applicableForCustomerType?: CustomerType,
      requestedBeginAt?: string,
      forecastBasis?: ForecastBasis,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTariffs200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTariffs(
        type,
        zipCode,
        annualConsumption,
        partnerId,
        annualConsumptionLowTariff,
        applicableForCustomerType,
        requestedBeginAt,
        forecastBasis,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TariffsApi.getTariffs"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TariffsApi - factory interface
 * @export
 */
export const TariffsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TariffsApiFp(configuration);
  return {
    /**
     * Gets a list of tariffs based on the provided query, ordered by its validity starting with the newest tariff.
     * @summary Get tariffs
     * @param {TariffType} type The type of tariffs to retrieve.
     * @param {string} zipCode The ZIP Code to retrieve tariffs for.
     * @param {number} annualConsumption The annual consumption to retrieve tariffs for.
     * @param {string} [partnerId] The partner id to retrieve tariffs for. If not given, tariffs with partnerId are filtered out.
     * @param {number} [annualConsumptionLowTariff] The annual consumption to retrieve low tariffs for.
     * @param {CustomerType} [applicableForCustomerType] The customer type to retrieve tariffs for. If not set, tariffs applicable for all customer types are returned.
     * @param {string} [requestedBeginAt] The requested supply date to retrieve tariffs for.
     * @param {ForecastBasis} [forecastBasis] The forecast basis to retrieve tariffs for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTariffs(
      type: TariffType,
      zipCode: string,
      annualConsumption: number,
      partnerId?: string,
      annualConsumptionLowTariff?: number,
      applicableForCustomerType?: CustomerType,
      requestedBeginAt?: string,
      forecastBasis?: ForecastBasis,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetTariffs200Response> {
      return localVarFp
        .getTariffs(
          type,
          zipCode,
          annualConsumption,
          partnerId,
          annualConsumptionLowTariff,
          applicableForCustomerType,
          requestedBeginAt,
          forecastBasis,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TariffsApi - object-oriented interface
 * @export
 * @class TariffsApi
 * @extends {BaseAPI}
 */
export class TariffsApi extends BaseAPI {
  /**
   * Gets a list of tariffs based on the provided query, ordered by its validity starting with the newest tariff.
   * @summary Get tariffs
   * @param {TariffType} type The type of tariffs to retrieve.
   * @param {string} zipCode The ZIP Code to retrieve tariffs for.
   * @param {number} annualConsumption The annual consumption to retrieve tariffs for.
   * @param {string} [partnerId] The partner id to retrieve tariffs for. If not given, tariffs with partnerId are filtered out.
   * @param {number} [annualConsumptionLowTariff] The annual consumption to retrieve low tariffs for.
   * @param {CustomerType} [applicableForCustomerType] The customer type to retrieve tariffs for. If not set, tariffs applicable for all customer types are returned.
   * @param {string} [requestedBeginAt] The requested supply date to retrieve tariffs for.
   * @param {ForecastBasis} [forecastBasis] The forecast basis to retrieve tariffs for.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TariffsApi
   */
  public getTariffs(
    type: TariffType,
    zipCode: string,
    annualConsumption: number,
    partnerId?: string,
    annualConsumptionLowTariff?: number,
    applicableForCustomerType?: CustomerType,
    requestedBeginAt?: string,
    forecastBasis?: ForecastBasis,
    options?: RawAxiosRequestConfig,
  ) {
    return TariffsApiFp(this.configuration)
      .getTariffs(
        type,
        zipCode,
        annualConsumption,
        partnerId,
        annualConsumptionLowTariff,
        applicableForCustomerType,
        requestedBeginAt,
        forecastBasis,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TenantPowerApi - axios parameter creator
 * @export
 */
export const TenantPowerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Creates a tenant power project and returns it.
     * @summary Create project
     * @param {TenantPowerProject} [tenantPowerProject] Request to create a tenant power project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProject: async (
      tenantPowerProject?: TenantPowerProject,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/tenant-power/projects`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantPowerProject,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets a tenant power project.
     * @summary Get project
     * @param {string} projectId ID of a tenant power project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProject: async (
      projectId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getProject", "projectId", projectId);
      const localVarPath = `/tenant-power/projects/{projectId}`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Search for tenant power projects matching the query.
     * @summary Search projects
     * @param {string} query The search query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchProjects: async (
      query: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists("searchProjects", "query", query);
      const localVarPath = `/tenant-power/projects/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      if (query !== undefined) {
        localVarQueryParameter["query"] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates tenant power project.
     * @summary Update project
     * @param {string} projectId ID of a tenant power project
     * @param {UpdateProjectRequest} [updateProjectRequest] Request to update a tenant power project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProject: async (
      projectId: string,
      updateProjectRequest?: UpdateProjectRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("updateProject", "projectId", projectId);
      const localVarPath = `/tenant-power/projects/{projectId}`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateProjectRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TenantPowerApi - functional programming interface
 * @export
 */
export const TenantPowerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TenantPowerApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates a tenant power project and returns it.
     * @summary Create project
     * @param {TenantPowerProject} [tenantPowerProject] Request to create a tenant power project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createProject(
      tenantPowerProject?: TenantPowerProject,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantPowerProject>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(
        tenantPowerProject,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantPowerApi.createProject"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Gets a tenant power project.
     * @summary Get project
     * @param {string} projectId ID of a tenant power project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProject(
      projectId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TenantPowerProjectAsAdmin>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(
        projectId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantPowerApi.getProject"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Search for tenant power projects matching the query.
     * @summary Search projects
     * @param {string} query The search query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchProjects(
      query: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SearchProjects200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchProjects(
        query,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantPowerApi.searchProjects"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Updates tenant power project.
     * @summary Update project
     * @param {string} projectId ID of a tenant power project
     * @param {UpdateProjectRequest} [updateProjectRequest] Request to update a tenant power project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateProject(
      projectId: string,
      updateProjectRequest?: UpdateProjectRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantPowerProject>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(
        projectId,
        updateProjectRequest,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantPowerApi.updateProject"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TenantPowerApi - factory interface
 * @export
 */
export const TenantPowerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TenantPowerApiFp(configuration);
  return {
    /**
     * Creates a tenant power project and returns it.
     * @summary Create project
     * @param {TenantPowerProject} [tenantPowerProject] Request to create a tenant power project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProject(
      tenantPowerProject?: TenantPowerProject,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TenantPowerProject> {
      return localVarFp
        .createProject(tenantPowerProject, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets a tenant power project.
     * @summary Get project
     * @param {string} projectId ID of a tenant power project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProject(
      projectId: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TenantPowerProjectAsAdmin> {
      return localVarFp
        .getProject(projectId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Search for tenant power projects matching the query.
     * @summary Search projects
     * @param {string} query The search query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchProjects(
      query: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SearchProjects200Response> {
      return localVarFp
        .searchProjects(query, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates tenant power project.
     * @summary Update project
     * @param {string} projectId ID of a tenant power project
     * @param {UpdateProjectRequest} [updateProjectRequest] Request to update a tenant power project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProject(
      projectId: string,
      updateProjectRequest?: UpdateProjectRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TenantPowerProject> {
      return localVarFp
        .updateProject(projectId, updateProjectRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TenantPowerApi - object-oriented interface
 * @export
 * @class TenantPowerApi
 * @extends {BaseAPI}
 */
export class TenantPowerApi extends BaseAPI {
  /**
   * Creates a tenant power project and returns it.
   * @summary Create project
   * @param {TenantPowerProject} [tenantPowerProject] Request to create a tenant power project.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantPowerApi
   */
  public createProject(
    tenantPowerProject?: TenantPowerProject,
    options?: RawAxiosRequestConfig,
  ) {
    return TenantPowerApiFp(this.configuration)
      .createProject(tenantPowerProject, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets a tenant power project.
   * @summary Get project
   * @param {string} projectId ID of a tenant power project
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantPowerApi
   */
  public getProject(projectId: string, options?: RawAxiosRequestConfig) {
    return TenantPowerApiFp(this.configuration)
      .getProject(projectId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Search for tenant power projects matching the query.
   * @summary Search projects
   * @param {string} query The search query
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantPowerApi
   */
  public searchProjects(query: string, options?: RawAxiosRequestConfig) {
    return TenantPowerApiFp(this.configuration)
      .searchProjects(query, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates tenant power project.
   * @summary Update project
   * @param {string} projectId ID of a tenant power project
   * @param {UpdateProjectRequest} [updateProjectRequest] Request to update a tenant power project.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantPowerApi
   */
  public updateProject(
    projectId: string,
    updateProjectRequest?: UpdateProjectRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TenantPowerApiFp(this.configuration)
      .updateProject(projectId, updateProjectRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TokenVerificationApi - axios parameter creator
 * @export
 */
export const TokenVerificationApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Trigger another email verification.
     * @summary Trigger email verification
     * @param {TriggerAnotherEmailVerificationRequest} [triggerAnotherEmailVerificationRequest] Request to trigger another email verification for a customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    triggerAnotherEmailVerification: async (
      triggerAnotherEmailVerificationRequest?: TriggerAnotherEmailVerificationRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/email-address/verification-trigger`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        triggerAnotherEmailVerificationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TokenVerificationApi - functional programming interface
 * @export
 */
export const TokenVerificationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TokenVerificationApiAxiosParamCreator(configuration);
  return {
    /**
     * Trigger another email verification.
     * @summary Trigger email verification
     * @param {TriggerAnotherEmailVerificationRequest} [triggerAnotherEmailVerificationRequest] Request to trigger another email verification for a customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async triggerAnotherEmailVerification(
      triggerAnotherEmailVerificationRequest?: TriggerAnotherEmailVerificationRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailVerificationToken>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.triggerAnotherEmailVerification(
          triggerAnotherEmailVerificationRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TokenVerificationApi.triggerAnotherEmailVerification"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TokenVerificationApi - factory interface
 * @export
 */
export const TokenVerificationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TokenVerificationApiFp(configuration);
  return {
    /**
     * Trigger another email verification.
     * @summary Trigger email verification
     * @param {TriggerAnotherEmailVerificationRequest} [triggerAnotherEmailVerificationRequest] Request to trigger another email verification for a customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    triggerAnotherEmailVerification(
      triggerAnotherEmailVerificationRequest?: TriggerAnotherEmailVerificationRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<EmailVerificationToken> {
      return localVarFp
        .triggerAnotherEmailVerification(triggerAnotherEmailVerificationRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TokenVerificationApi - object-oriented interface
 * @export
 * @class TokenVerificationApi
 * @extends {BaseAPI}
 */
export class TokenVerificationApi extends BaseAPI {
  /**
   * Trigger another email verification.
   * @summary Trigger email verification
   * @param {TriggerAnotherEmailVerificationRequest} [triggerAnotherEmailVerificationRequest] Request to trigger another email verification for a customer.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenVerificationApi
   */
  public triggerAnotherEmailVerification(
    triggerAnotherEmailVerificationRequest?: TriggerAnotherEmailVerificationRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TokenVerificationApiFp(this.configuration)
      .triggerAnotherEmailVerification(triggerAnotherEmailVerificationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
