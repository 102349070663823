import { useScript } from "@unhead/vue";
import config from "~/config";

/**
 * This composable is used to add or remove UPPR tracking scripts to the head of the page:
 * - Add scripts once marketing cookies are approved
 * - Remove scripts once marketing cookies are not approved anymore
 */
const useUpprScripts = () => {
  const route = useRoute();
  const cookieStore = useCookiesStore();
  const emid = useCookie("trs");

  const pagetype = route.fullPath.split("/").slice(-2, -1)[0];

  const { $script: upprTrackingScript } = useScript(
    {
      key: "uppr-tracking",
      src: `${config.uppr.trackingDomain}/etms/eatms.js?campaign_id=${config.uppr.campaignId}&pagetype=${pagetype}`,
      type: "text/javascript",
      referrerpolicy: "no-referrer-when-downgrade",
    },
    {
      trigger: "manual",
      tagPosition: "bodyClose",
      mode: "client",
    },
  );

  const { $script: upprBasketFreezeScript } = useScript(
    {
      key: "uppr-basket-freeze",
      src: `${config.uppr.trackingDomain}/etp/?campaign_id=${config.uppr.campaignId}&type=basketfreeze&emid=${emid.value}`,
      type: "text/javascript",
    },
    {
      trigger: "manual",
      tagPosition: "bodyClose",
      mode: "client",
    },
  );

  watchEffect(() => {
    if (cookieStore.marketingCookiesApproved) {
      upprTrackingScript.load();
      if (emid?.value) upprBasketFreezeScript.load();
    } else {
      // Remove uppr tracking scripts
      upprTrackingScript.remove();
      upprBasketFreezeScript.remove();
      // Remove other scripts that may have been added by UPPR
      if (process.client) {
        Array.from(document.querySelectorAll("script"))
          .filter((s) => s.src.includes(config.uppr.trackingDomain))
          .forEach((s) => s.remove());
      }
    }
  });
};

export default useUpprScripts;
