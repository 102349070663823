<template>
  <div
    class="ekomi-stars"
    :class="{ 'small-text': withSmallText }"
    itemscope
    itemtype="http://schema.org/Product"
  >
    <div class="hidden" itemprop="name">
      {{ config.ekomi.productName }}
    </div>
    <div
      class="aggregateRating"
      itemscope
      itemtype="http://schema.org/AggregateRating"
      itemprop="aggregateRating"
    >
      <TheLinkButton
        v-if="areStarsLinks"
        class="rating-link p-0 fw-normal"
        target="_blank"
        :to="config.ekomi.certificateLink"
      >
        <RatingStars
          show-text
          :rating-stars="Number(ekomiRoundedAverage)"
          :total-ratings="Number(ekomiNumberOfRatings)"
          :show-in-column="showInColumn"
          :justify-content-center="justifyContentCenter"
          :with-rating-value="withRatingValue"
          :with-short-first-text="withShortFirstText"
          :with-short-second-text="withShortSecondText"
          :with-large-stars="withLargeStars"
          :show-in-column-on-mobile="showInColumnOnMobile"
        />
      </TheLinkButton>
      <RatingStars
        v-else
        show-text
        :rating-stars="Number(ekomiRoundedAverage)"
        :total-ratings="Number(ekomiNumberOfRatings)"
        :show-in-column="showInColumn"
        :justify-content-center="justifyContentCenter"
        :with-rating-value="withRatingValue"
        :with-short-first-text="withShortFirstText"
        :with-short-second-text="withShortSecondText"
        :with-large-stars="withLargeStars"
        :show-in-column-on-mobile="showInColumnOnMobile"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import RatingStars from "~/components/02_molecules/RatingStars.vue";
import config from "~/config";
import TheLinkButton from "~/components/01_atoms/TheLinkButton.vue";
import type { EkomiAverageRating, EkomiData } from "~/types/shared/ekomi";

withDefaults(
  defineProps<{
    areStarsLinks?: boolean;
    showInColumn?: boolean;
    showInColumnOnMobile?: boolean;
    justifyContentCenter?: boolean;
    withRatingValue?: boolean;
    withShortFirstText?: boolean;
    withShortSecondText?: boolean;
    withLargeStars?: boolean;
    withSmallText?: boolean;
  }>(),
  { areStarsLinks: true, withRatingValue: true, withLargeStars: true },
);
const ekomiAverageRating = (useRuntimeConfig().public.ekomiData as EkomiData)
  .averageRating as EkomiAverageRating;

const ekomiRoundedAverage = ekomiAverageRating.roundedAverage;
const ekomiNumberOfRatings = ekomiAverageRating.numberOfRatings;

useSchemaOrg(
  defineReview({
    author: { name: "Team Wirklich" },
    reviewRating: {
      ratingCount: ekomiNumberOfRatings,
      ratingValue: ekomiRoundedAverage,
    },
  }),
);
</script>

<style lang="scss" scoped>
.ekomi-stars {
  margin-bottom: 20px;
  margin-top: 0;

  &.small-text {
    font-size: var(--font-size-s);
  }

  .hidden {
    display: none;
  }

  .rating-link:hover {
    color: var(--star-yellow);
  }
}
</style>
