import { defineStore } from "pinia";
import type {
  ContractAsCustomer,
  Customer,
  DeprecatedMeterReading,
  Recommendation,
} from "~/src/generated-sources/core";
import type {
  PublicTariffWithSummarizedPriceRanges as PublicTariff,
  PublicTariffSubTypeEnum,
} from "~/src/generated-sources/public";

export const useCustomerStore = defineStore("CustomerStore", () => {
  const contracts = ref<ContractAsCustomer[]>([]);
  const customer = ref<Customer>();
  const tariffs = ref<PublicTariff[]>([]);
  const recommendations = ref<Recommendation[]>([]);
  const isExclusivelyTenantPowerCustomer = ref(false);

  const setContracts = (newContracts: ContractAsCustomer[]) => {
    contracts.value = newContracts;
  };

  const setTariffs = (newTarrifs: PublicTariff[]) => {
    tariffs.value = newTarrifs;
    isExclusivelyTenantPowerCustomer.value = areTariffsAllOfSubTypeTenantPower(
      tariffs.value,
    );
  };

  const setRecommendations = (customerRecommendations: Recommendation[]) => {
    recommendations.value = customerRecommendations;
  };

  const setCustomer = (newCustomer: Customer) => {
    customer.value = newCustomer;
  };

  const areTariffsAllOfSubTypeTenantPower = (tariffs: PublicTariff[]) => {
    const tenantPowerSubTypes: PublicTariffSubTypeEnum[] = [
      "tenantPower",
      "tenantPowerShadow",
    ];

    return tariffs.every((tariff) => tenantPowerSubTypes.includes(tariff.subType));
  };

  const getContractById = (id: string): ContractAsCustomer | undefined =>
    contracts.value.find((contract) => contract.id === id);

  const getCurrentContract = () => {
    const id = useGetSlug("id");
    const currentContract = getContractById(id);

    if (!currentContract) {
      throw createError({ statusCode: 404, fatal: true, message: "not found" });
    }
    return currentContract;
  };

  const updateCustomer = (newContent: any) => {
    customer.value = { ...customer.value, ...newContent };
  };

  const updateContract = (newContent: any) => {
    const contract = getCurrentContract();
    const contractIndex = contracts.value.indexOf(contract);
    const newContract = { ...contract, ...newContent };
    contracts.value[contractIndex] = newContract;
  };

  const updateContractById = (contractId: string, newContent: any) => {
    const contract = contracts.value.find((c) => c.id === contractId)!;
    const contractIndex = contracts.value.indexOf(contract);
    const newContract = { ...contract, ...newContent };
    contracts.value[contractIndex] = newContract;
  };

  const pushMeterReadingToContract = (
    contractId: string,
    meterReading: DeprecatedMeterReading,
  ) => {
    if (contracts.value) {
      const contract = contracts.value.find((c) => c.id === contractId);
      if (contract) {
        if (contract.meterReadings) {
          contract.meterReadings.push(meterReading);
        } else {
          contract.meterReadings = [meterReading];
        }
      }
    }
  };

  const resetCustomer = async () => {
    const { $brain } = useNuxtApp();
    const customerRequest = await $brain.customer.customer.getCustomer();

    if (customerRequest.data) {
      setCustomer(customerRequest.data);
    }
  };

  return {
    contracts,
    tariffs,
    customer,
    getContractById,
    isExclusivelyTenantPowerCustomer,
    recommendations,
    setContracts,
    setTariffs,
    setRecommendations,
    setCustomer,
    resetCustomer,
    updateContract,
    updateContractById,
    pushMeterReadingToContract,
    updateCustomer,
    getCurrentContract,
  };
});
