import {
  InteractionRequiredAuthError,
  type IPublicClientApplication,
} from "@azure/msal-browser";
import * as Sentry from "@sentry/browser";

export default class AzureB2cClient {
  private readonly signInAuthority;
  private readonly passwordResetAuthority;

  public constructor(
    private readonly authType: "admin" | "user",
    private readonly client: IPublicClientApplication,
    private readonly clientId: string,
    private readonly scopes?: string[],
  ) {
    const authority = this.client.getConfiguration().auth.authority;
    this.signInAuthority = authority + (this.authType === "user" ? "/B2C_1_signin" : "");
    this.passwordResetAuthority =
      authority + "/B2C_1A_DisplayControl_sendgrid_PasswordReset";
  }

  /**
   * Returns the "fallback" scopes or better to say the scopes
   * needed for login of non-admin users at the b2c tenant.
   * @returns The scopes for requesting a token.
   */
  private getFallbackScopes = () => ["openid", this.clientId];

  public async handleRedirect() {
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/FAQ.md#how-do-i-handle-the-password-reset-user-flow
    const response = await this.client.handleRedirectPromise().catch((error) => {
      // check if the user initiated the password reset flow on the login page and redirect to the password reset page
      if (error?.errorMessage?.includes("AADB2C90118")) {
        // A quirk in the msal library causes the password reset flow to fail. The error message:
        // "AADB2C90088: The provided grant has not been issued for this endpoint.
        // Actual Value : B2C_1_signin and Expected Value : B2C_1_reset_email"
        // Since we cannot figure out how to have the user directly be logged in after the password reset flow
        // (as the error suggests, we would need to use the proper grant, but how?),
        // we just log the user out after the password reset was successful.
        this.passwordReset("/password-reset-success");
      }
      // redirect the to account page if the user aborts the password reset flow
      if (error?.errorMessage?.includes("AADB2C90091")) {
        window.location.href = "/kundenbereich/account";
      }
    });
    if (response) {
      const state = response.state ? JSON.parse(response.state) : {};

      if (state.authType === this.authType) {
        const accounts = this.client.getAllAccounts();
        if (accounts.length > 0) {
          const account = accounts[0];
          this.client.setActiveAccount(account);
        }

        // Quick and dirty workaround for the following issue:
        // The SPA redirect from navigateTo() results in very weird
        // behaviour after returning from the oAuth-flow. The page
        // is not redirected to /admin or /kundenbereich and some
        // errors are thrown in the console. So for now, just do a "hard
        // reload" to the redirect path. Later, we should extend our infrastructure
        // to forward directly to /admin or /kundenbereich from the oAuth flow,
        // so that the middleware can take care of the rest.
        // navigateTo(state.redirectTo ?? "/");
        window.location.href = state.redirectTo ?? "/";
      }
    }
  }

  public passwordReset(redirectTo?: string) {
    this.client.loginRedirect({
      authority: this.passwordResetAuthority,
      scopes: [],
      state: JSON.stringify({ redirectTo, authType: this.authType }),
    });
  }

  private async redirectToLoginPage(redirectTo?: string) {
    try {
      await this.client.loginRedirect({
        authority: this.signInAuthority,
        scopes: this.scopes || this.getFallbackScopes(),
        state: JSON.stringify({ redirectTo, authType: this.authType }),
      });
    } catch (error) {
      console.warn("MSAL error", error);
    }
  }

  public async signIn(redirectTo?: string) {
    const account = this.getUserAccount();

    if (account) {
      useAuthStore().setCustomer(account);
    } else {
      await this.redirectToLoginPage(redirectTo);
    }
  }

  public async signOut(redirectTo?: string) {
    try {
      await this.client.logoutRedirect({
        authority: this.signInAuthority,
        postLogoutRedirectUri: redirectTo || "/",
      });
    } catch (error) {
      console.warn("Logout failed: ", error);
    }
  }

  public async getToken() {
    try {
      const result = await this.client.acquireTokenSilent({
        authority: this.signInAuthority,
        scopes: this.scopes || this.getFallbackScopes(),
      });

      if (!result.accessToken) {
        const error = new Error("Failed to retrieve access token");
        Sentry.captureException(error, { data: result });
        throw error;
      }

      return result.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await this.client.acquireTokenRedirect({
          authority: this.signInAuthority,
          scopes: this.scopes || this.getFallbackScopes(),
        });
      } else {
        console.warn("Getting token failed", error);
        throw error;
      }
    }
  }

  public getUserAccount() {
    return this.client.getActiveAccount();
  }
}
