import type { Calculator } from "~/types/shared/calculator";

export const defaultCalculatorConfig: Calculator = {
  businessCustomerPreSelected: false,
  canSwitchBusinessCustomer: true,
  tabs: [
    {
      title: "Klassiker",
      icon: "classic",
      tariffs: [
        {
          tariffType: "power",
          tariffSubType: "classic",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
        {
          tariffType: "power",
          tariffSubType: "classicFlex",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
        {
          tariffType: "power",
          tariffSubType: "classic",
          runTimeType: "comfort",
          description: "",
          uniqueSellingPoints: [],
        },
        {
          tariffType: "gas",
          tariffSubType: "classic",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
        {
          tariffType: "gas",
          tariffSubType: "classicFlex",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
        {
          tariffType: "gas",
          tariffSubType: "geg",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
        {
          tariffType: "gas",
          tariffSubType: "vegan",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
      ],
    },
    {
      title: "Heizstrom",
      icon: "heating",
      tariffs: [
        {
          tariffType: "power",
          tariffSubType: "heatPump",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
        {
          tariffType: "power",
          tariffSubType: "nightStorageHeating",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
        {
          tariffType: "power",
          tariffSubType: "double",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
      ],
    },
    {
      title: "Autostrom",
      icon: "emobility",
      tariffs: [
        {
          tariffType: "power",
          tariffSubType: "carPower",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
        {
          tariffType: "power",
          tariffSubType: "carPowerPlus",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
      ],
    },
    // TODO: Turn into "Spezialtarife" tab if we get more options
    {
      title: "Mieterstrom",
      icon: "house-m",
      isAdminOnly: true,
      tariffs: [
        {
          tariffType: "power",
          tariffSubType: "tenantPower",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
        {
          tariffType: "power",
          tariffSubType: "tenantPowerShadow",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
      ],
    },
    {
      title: "Eigenstrom",
      icon: "ownpower",
      isAdminOnly: true,
      tariffs: [
        {
          tariffType: "power",
          tariffSubType: "own",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
      ],
    },
  ],
};
